import { FC } from 'react';

import HeaderMobile from '../../../../components/layout/HeaderMobile';
import { PageId } from '../../../../constants/page-ids';
import BusinessSecondaryNavigation from '../../../../features/business/components/BusinessLayout/BusinessSecondaryNavigation';
import FacilityHeaderActions from '../../../../features/business/components/FacilityHeaderActions';
import { useFacility } from '../../../../features/business/hooks/useFacility';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { useParamFacilityId } from '../../../../router/hooks/path-params';

const FacilitiesFullPageLayout: FC = () => {
  const paramFacilityId = useParamFacilityId();
  const { data: facility } = useFacility(paramFacilityId);
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={() =>
          facility ? <FacilityHeaderActions facility={facility} /> : null
        }
        title={getPageLabel(PageId.Facility)}
      />
    );
  }
  return (
    <>
      <BusinessSecondaryNavigation />
    </>
  );
};

export default FacilitiesFullPageLayout;
