import { TraineeFull, UserStatus } from '@strenco/api';
import { FC } from 'react';

import { ActionIconHeaderActions } from '../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../components/ui/menus/CustomMenu';
import { translate } from '../../../../i18n';
import { useClientActions } from '../../hooks/useClientActions';

interface Props {
  client: Pick<TraineeFull, 'businessId' | 'id' | 'status'>;
}

const ClientHeaderActions: FC<Props> = ({ client }) => {
  const {
    isDeleting,
    isUpdating,
    requestActivate,
    requestDeactivate,
    requestDelete,
    requestEdit,
  } = useClientActions(client);

  const items: MenuItem[] = [];

  if (client !== null) {
    items.push({
      Icon: iconsMap.edit.Icon,
      label: translate('client.edit'),
      onClick: requestEdit,
    });

    if (client.status === UserStatus.Active) {
      items.push({
        Icon: iconsMap.userDeactivate.Icon,
        label: translate('client.deactivate'),
        onClick: requestDeactivate,
      });
    }

    if (client.status === UserStatus.Inactive) {
      items.push({
        Icon: iconsMap.userActivate.Icon,
        label: translate('client.activate'),
        onClick: requestActivate,
      });
    }

    items.push({
      disabled: client.status === UserStatus.Active,
      Icon: iconsMap.delete.Icon,
      label: translate('client.delete'),
      onClick: requestDelete,
    });
  }

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      trigger={
        <ActionIconHeaderActions
          label={translate('client.actions')}
          loading={isDeleting || isUpdating}
        />
      }
    />
  );
};

export default ClientHeaderActions;
