import {
  ConversionRequest,
  ConversionType,
  TrainingProgramFull,
  TrainingProgramType,
  WorkoutFullEntity,
  WorkoutStatus,
} from '@strenco/api';
import {
  IconArrowBackUp,
  IconChevronsRight,
  IconRotateClockwise,
} from '@tabler/icons-react';

import { iconsMap } from '../../../components/common/ActionIcons/config';
import { MenuItem } from '../../../components/ui/menus/CustomMenu';
import { translate } from '../../../i18n';
import { isWorkoutCompleted } from '../@client/helpers/workout';
import { useTrainingProgramById, useWorkout } from './training-program';
import { useWorkoutActions } from './useWorkoutActions';

const getShouldShowResetWorkout = (
  workoutId: string | undefined,
  data: TrainingProgramFull<'template' | 'user'> | undefined,
) => {
  if (!workoutId || !data) return false;

  const workout = data?.workouts[workoutId];

  if (data.type === 'template' || workout.info?.status === WorkoutStatus.None) {
    return false;
  }

  return workout.exerciseSetGroupsOrder.some(group =>
    data.exerciseSetGroups[group].setsOrder.some(
      setId => data.exerciseSets[setId].result,
    ),
  );
};

const getConversionPayload = (
  data?: WorkoutFullEntity<TrainingProgramType>,
): ConversionRequest['payload'] | null => {
  if (!data) return null;

  if (data?.workout.trainingProgramId === null) {
    return null;
  }

  return {
    params: { workoutId: data.workout.id },
    type: ConversionType.ProgramWorkoutToStandaloneWorkoutTemplate,
  };
};

export const useWorkoutActionItems = () => {
  const dataTrainingProgram = useTrainingProgramById();
  const data = useWorkout();
  const conversion = getConversionPayload(data);

  const {
    convert,
    deleteOne,
    duplicate,
    editDetails,
    reset,
    skip,
    unSkip,
    ...rest
  } = useWorkoutActions();

  const status = data?.info?.status;
  const shouldShowReset = getShouldShowResetWorkout(
    data?.workout.id,
    dataTrainingProgram,
  );

  const items: MenuItem[] = [
    {
      Icon: iconsMap.edit.Icon,
      label: translate('workout.edit'),
      onClick: editDetails,
    },

    { isSectionLabel: true, label: translate('common.quick_actions') },
  ];

  items.push({
    Icon: iconsMap.duplicate.Icon,
    label: translate('workout.duplicate'),
    onClick: duplicate,
  });

  if (data?.info) {
    if (status === WorkoutStatus.Skipped) {
      items.push({
        Icon: IconArrowBackUp,
        label: translate('workout.unSkip'),
        onClick: unSkip,
      });
    } else if (isWorkoutCompleted(status) === false) {
      items.push({
        Icon: IconChevronsRight,
        label: translate('workout.skip'),
        onClick: skip,
      });
    }

    if (shouldShowReset) {
      items.push({
        Icon: IconRotateClockwise,
        label: translate('workout.reset'),
        onClick: reset,
      });
    }
  }

  if (conversion) {
    const labelConvert = translate(
      `common.conversion.${conversion.type}.title`,
    );
    items.push({
      Icon: iconsMap.export.Icon,
      label: labelConvert,
      onClick: () =>
        convert({
          context: { trainingProgramId: 'template', userId: 'template' },
          payload: conversion,
        }),
    });
  }

  items.push({
    Icon: iconsMap.delete.Icon,
    label: translate('workout.delete'),
    onClick: deleteOne,
  });

  return { isLoading: Object.values(rest).some(v => v === true), items };
};
