import { useTrainees, useTrainingProgramFull } from '@strenco/api';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { translate } from '../../i18n';
import {
  getBreadcrumbWorkoutLabel,
  getBreadcrumbWorkoutName,
} from '../../router/breadcrumbs/helpers';
import {
  useParamClientId,
  useParamRoutineId,
  useParamWorkoutId,
} from '../../router/hooks/path-params';
import { routes } from '../../router/routes';
import { getDataById } from '../../utils/array';
import { getFullName } from '../../utils/users';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const clientId = useParamClientId();
  const trainingProgramId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  const { data: trainees } = useTrainees();
  const { data } = useTrainingProgramFull(trainingProgramId, {
    enabled: !!trainingProgramId,
  });

  const trainee = getDataById(trainees, clientId);

  const items: BreadcrumbItem[] = [];

  if (clientId !== null) {
    items.push({
      content: trainee ? getFullName(trainee) : '',
      href: routes.clients.byId(clientId),
      isLoading: trainee === undefined,
      label: translate('client.label_one'),
    });

    if (trainingProgramId !== null) {
      items.push({
        content: data?.trainingProgram.program.name,
        href: routes.clients.routines(clientId, trainingProgramId),
        isLoading: data === undefined,
        label: translate('routine.routines_one'),
      });

      const workout = workoutId
        ? data?.workouts[workoutId]?.workout
        : undefined;

      if (workoutId !== null) {
        items.push({
          content: getBreadcrumbWorkoutName(workout),
          href: routes.clients.workouts(clientId, trainingProgramId, workoutId),
          isLoading: workout === undefined,
          label: getBreadcrumbWorkoutLabel(data, workoutId),
        });
      }
    }
  }

  return items;
};

export default useBreadcrumbs;
