/**
 * @file Search Params modal configuration
 */
import { SearchParam } from '../../router/types';
import { ModalId, ParamConfig } from './types';

export const modalParamsConfig: Record<ModalId, null | ParamConfig> = {
  [ModalId.BusinessAdminCreate]: {
    optional: [SearchParam.Facilities],
  },
  [ModalId.BusinessAdminEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.BusinessClientCreate]: {
    optional: [SearchParam.Facilities, SearchParam.CoachId],
  },
  [ModalId.BusinessClientEdit]: {
    required: [SearchParam.ClientId],
  },
  [ModalId.BusinessCoachCreate]: {
    optional: [SearchParam.Facilities],
  },
  [ModalId.BusinessCoachEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.BusinessCoachRankCreate]: null,
  [ModalId.BusinessCoachRankEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.BusinessFacilityCreate]: null,
  [ModalId.BusinessFacilityEdit]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.ChangePassword]: null,
  [ModalId.ClientCreate]: null,
  [ModalId.ClientEdit]: {
    required: [SearchParam.ClientId],
  },
  [ModalId.Exercise]: {
    optional: [SearchParam.ExerciseId],
  },
  [ModalId.GenerateClientRoutines]: {
    required: [SearchParam.EntityId],
  },
  [ModalId.StandaloneWorkoutTemplateDetails]: {
    optional: [SearchParam.EntityId],
  },
  [ModalId.SubscriptionChangePlan]: null,
  [ModalId.TrainingProgramCycle]: {
    optional: [SearchParam.CycleId],
    required: [SearchParam.EntityId],
  },
  [ModalId.TrainingProgramDetails]: {
    optional: [SearchParam.EntityId],
    required: [SearchParam.ClientId],
  },
  [ModalId.UserProfileEdit]: null,
  [ModalId.WorkoutDetails]: {
    optional: [SearchParam.EntityId, SearchParam.CycleId, SearchParam.ClientId],
    required: [SearchParam.TrainingProgramId],
  },
};
