import { ExerciseList, ExerciseVolumeUnit, useExercises } from '@strenco/api';
import { useEffect, useMemo, useRef, useState } from 'react';

let cached: null | null | Record<string, ExerciseList[]> = null;
let namesSet: null | Set<string> = null;

export const useGroupedExercises = (
  volumeUnitFilter?: Set<ExerciseVolumeUnit>,
) => {
  const refetchingRef = useRef(false);
  const { data, isLoading, isRefetching } = useExercises();
  const [groupedByName, setGroupedByName] = useState(cached ?? {});
  const [names, setNames] = useState(Array.from(namesSet ?? new Set<string>()));

  const isInvalidated = cached === null;

  useEffect(() => {
    if (refetchingRef.current && !isRefetching) {
      cached = null; // new data has been fetched
      namesSet = null;
    }
    refetchingRef.current = isRefetching;
  }, [isRefetching]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!data || !isInvalidated) return;
    cached = {};
    namesSet = new Set();

    for (const exerciseId of data.order) {
      const exercise = data.entities[exerciseId];
      const { name, variation, volumeUnits } = exercise;

      if (volumeUnitFilter && volumeUnitFilter.size) {
        const shouldInclude = volumeUnits.some(u => volumeUnitFilter.has(u));
        if (!shouldInclude) continue;
      }

      namesSet.add(name);

      if (!cached[name]) {
        cached[name] = [exercise];
        continue;
      }

      if (!variation) {
        cached[name].unshift(exercise);
      } else {
        cached[name].push(exercise);
      }
    }

    setNames(Array.from(namesSet));
    setGroupedByName(cached);
  }, [data, isInvalidated, volumeUnitFilter]);

  return useMemo(
    () => ({
      data,
      groupedByName,
      isLoading,
      names,
    }),
    [data, isLoading, names, groupedByName],
  );
};
