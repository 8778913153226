import { Exercise } from '@strenco/api';

import { iconsMap } from '../../../../../../components/common/ActionIcons/config';
import { translate } from '../../../../../../i18n';
import { getCurrentStorageUser, isSuperAdmin } from '../../../../../user/utils';

export const getDuplicateTooltipAndIcon = (
  exercise: Pick<Exercise, 'createdBy' | 'isPublic'>,
) => {
  const showDuplicate =
    (exercise.isPublic && isSuperAdmin()) ||
    exercise.createdBy === getCurrentStorageUser()?.id;

  if (showDuplicate) {
    return {
      icon: iconsMap.duplicate.Icon,
      tooltip: translate('exercise.duplicate'),
    };
  }

  return {
    icon: iconsMap.duplicate.Icon,
    tooltip: translate('exercise.personalize'),
  };
};
