import { FacilityWithMembers } from '@strenco/api';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionIconHeaderActions } from '../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../components/ui/menus/CustomMenu';
import { translate } from '../../../../i18n';
import { PATH__BUSINESS__FACILITIES } from '../../../../router/paths/paths';
import { isBusinessAdmin, isBusinessOwner } from '../../../user/utils';
import { useFacilityActions } from '../../hooks/useFacilityActions';

interface Props {
  facility: FacilityWithMembers;
}

const FacilityHeaderActions: FC<Props> = ({ facility }) => {
  const navigate = useNavigate();
  const { isDeleting, requestDelete, requestEdit } = useFacilityActions(
    facility.id,
  );

  const items: MenuItem[] = [];

  if (facility !== null) {
    items.push({
      Icon: iconsMap.edit.Icon,
      label: translate('business.facilities_edit'),
      onClick: requestEdit,
    });

    if (isBusinessOwner() || isBusinessAdmin()) {
      items.push({
        Icon: iconsMap.delete.Icon,
        label: translate('business.facilities_delete'),
        onClick: () => {
          requestDelete().then(() => {
            navigate(PATH__BUSINESS__FACILITIES, { replace: true });
          });
        },
      });
    }
  }

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      tooltip={translate('business.facilities_actions')}
      trigger={<ActionIconHeaderActions loading={isDeleting} />}
    />
  );
};

export default FacilityHeaderActions;
