import { useWorkoutDelete, useWorkoutDuplicate } from '@strenco/api';

import { iconsMap } from '../../../../components/common/ActionIcons/config';
import { MenuItem } from '../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../constants/mutation-keys';
import { translate } from '../../../../i18n';
import { SearchParam } from '../../../../router/types';
import { ModalId, useModal } from '../../../../utils/modal';
import { confirmDanger } from '../../../../utils/modal/confirm';
import { useWorkoutActionContext } from '../../hooks/useWorkoutActionsContext';

export const useStandaloneTemplateWorkoutActions = (workoutId: string) => {
  const context = useWorkoutActionContext();
  const { isPending: isDeleting, mutateAsync: deleteWorkoutTemplate } =
    useWorkoutDelete(workoutId);
  const { isPending: isDuplicating, mutateAsync: duplicateWorkoutTemplate } =
    useWorkoutDuplicate(workoutId);

  const { openModal } = useModal();

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.TemplateWorkout,
      onConfirm: () => deleteWorkoutTemplate(context),
    });
  };

  const requestDuplicate = () => {
    duplicateWorkoutTemplate(context);
  };

  const requestEdit = () => {
    openModal(ModalId.StandaloneWorkoutTemplateDetails, {
      [SearchParam.EntityId]: workoutId,
    });
  };

  return {
    isDeleting,
    isDuplicating,
    requestDelete,
    requestDuplicate,
    requestEdit,
  };
};

export const useStandaloneTemplateWorkoutActionItems = (workoutId: string) => {
  const { isDeleting, requestDelete, requestDuplicate, requestEdit } =
    useStandaloneTemplateWorkoutActions(workoutId);

  const items: MenuItem[] = [];

  items.push({
    Icon: iconsMap.edit.Icon,
    label: translate('workout.edit'),
    onClick: requestEdit,
  });

  items.push({
    Icon: iconsMap.duplicate.Icon,
    label: translate('workout.duplicate'),
    onClick: requestDuplicate,
  });

  items.push({
    Icon: iconsMap.delete.Icon,
    label: translate('workout.delete'),
    onClick: requestDelete,
  });

  return { isLoading: isDeleting, items };
};
