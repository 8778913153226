import { CurrentUser } from '@strenco/api';

export const getAvatarProps = (userData: CurrentUser) => {
  const fullName = `${userData.identity.firstName} ${userData.identity.lastName}`;

  return {
    avatarUrl: userData.identity.avatarUrl,
    name: fullName,
  };
};
