//Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';

import envs from '../config/envs';
import { User } from '../features/user/types';
import { getRelease, isLocal, isProduction } from './environment';

const DISABLED = isLocal() || isProduction() === false;

export const initMixpanel = () => {
  if (DISABLED) {
    return;
  }

  mixpanel.init(envs.mixpanel.token, {
    debug: true,
    persistence: 'localStorage',
    track_pageview: true,
  });

  mixpanel.register({
    Release: getRelease(),
  });
};

export const setMixpanelUser = (user: User) => {
  if (DISABLED) {
    return;
  }
  mixpanel.identify(user.id);

  mixpanel.people.set({
    'User Type': user.type,
  });
};

export const clearMixpanelUser = () => {
  if (DISABLED) {
    return;
  }
  mixpanel.reset();
};

type MixpanelEvent = 'Log Out' | 'Sign In';

export const trackEvent = (event: MixpanelEvent) => {
  if (DISABLED) {
    return;
  }
  mixpanel.track(event);
};
