import { TraineeList, UserStatus } from '@strenco/api';
import { ComponentProps } from 'react';
import { Location } from 'react-router-dom';

import DrawerNavItemUser from '../../../../components/common/DrawerNavItemUser';
import { translate } from '../../../../i18n';
import { getRelativeUrlClientFull } from '../../../../router/urls/clients';

type ClientItem = ComponentProps<typeof DrawerNavItemUser>;
interface Filters {
  paramClientId: null | string;
  paramCoachId: null | string;
  search: string | undefined;
}

const buildClient = (
  client: TraineeList,
  paramClientId: Filters['paramClientId'],
  location: Location,
): ClientItem => {
  const { id, identity, trainingProgramInProgressId, workoutsInProgress } =
    client;

  const output: ClientItem = {
    ...identity,
    isActive: id === paramClientId,
    status: client.status,
    to: getRelativeUrlClientFull(id, location),
  };

  if (!trainingProgramInProgressId) {
    output.indicator = {
      color: 'yellow.8',
      label: '',
      tooltip: translate('common.clients_action_no_routines_in_progress'),
    };
  }

  if (workoutsInProgress.length) {
    const tooltip = translate('workout.in_progress', {
      workoutName: workoutsInProgress[0].name,
    });

    output.indicator = {
      color: 'branding.7',
      label: '',
      processing: true,
      tooltip,
      withBorder: true,
    };
  }

  return output;
};

type List = ReturnType<typeof buildClient>[];

type Return = Record<UserStatus, List>;

export const getFilteredClients = (
  clients: TraineeList[],
  filters: Filters,
  location: Location,
) => {
  const { paramClientId, paramCoachId, search } = filters;

  return clients.reduce(
    (current, clientItem) => {
      // We are on the business coaches page, and we filter only clients that belong to that coach
      if (paramCoachId !== null && clientItem.coachId !== paramCoachId) {
        return current;
      }

      if (!search) {
        current[clientItem.status].push(
          buildClient(clientItem, paramClientId, location),
        );
        return current;
      }

      const isSearch =
        `${clientItem.identity.firstName} ${clientItem.identity.lastName} ${clientItem.email}`
          .toLowerCase()
          .search(search) > -1;

      if (isSearch) {
        current[clientItem.status].push(
          buildClient(clientItem, paramClientId, location),
        );
      }

      return current;
    },
    {
      [UserStatus.Active]: [],
      [UserStatus.Inactive]: [],
      [UserStatus.SignUpInstructionsSent]: [],
    } as Return,
  );
};
