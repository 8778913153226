import { UserType } from '@strenco/api';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { getUser } from '../../features/user/api/cache/getters';
import { routes } from '../../router/routes';
import { ErrorParam } from '../../types';

type ProtectedRouteProps = {
  allowedUserTypes?: Set<UserType>;
  redirectTo?: string;
};

const ProtectedRoute: React.FC<PropsWithChildren<ProtectedRouteProps>> = ({
  allowedUserTypes = new Set<UserType>(),
  children,
  redirectTo = routes.error.byErrorType(ErrorParam.Forbidden),
}) => {
  const userType = getUser()?.type ?? null;

  const isAllowed = allowedUserTypes.has(userType as UserType);

  if (isAllowed) {
    return children;
  }

  return <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
