import { Avatar, MantineColor } from '@mantine/core';
import { UserStatus } from '@strenco/api';
import { ComponentProps } from 'react';

import { translate } from '../i18n';

interface Parameters {
  avatarUrl: null | string;
  firstName: string;
  lastName: string;
}

export const getAvatarProps = (
  client: Parameters | { identity: Parameters },
): ComponentProps<typeof Avatar> => {
  if ('identity' in client) {
    const {
      identity: { avatarUrl, firstName, lastName },
    } = client;

    return {
      color: 'branding' as const,
      firstName,
      lastName,
      src: avatarUrl,
    };
  }

  const { avatarUrl, firstName, lastName } = client;

  return {
    color: 'branding' as const,
    firstName,
    lastName,
    src: avatarUrl,
  };
};

export const getDeleteIconTooltip = (status: UserStatus) =>
  status === UserStatus.Active
    ? translate('common.users_deactivate_first')
    : translate('common.users_delete');

export const badgeStatusColor: Record<UserStatus, MantineColor> = {
  [UserStatus.Active]: 'green.5',
  [UserStatus.Inactive]: 'red.6',
  [UserStatus.SignUpInstructionsSent]: 'violet.5',
};

export const getStatusActionIconProps = (
  type: 'client' | 'coach',
  status: UserStatus,
  activate: () => void,
  deactivate: () => void,
) => {
  if (status === UserStatus.Active) {
    return {
      icon: 'userDeactivate' as const,
      onClick: deactivate,
      tooltip: translate(
        type === 'client' ? 'client.deactivate' : 'business.coaches_deactivate',
      ),
    };
  }

  return {
    icon: 'userActivate' as const,
    onClick: activate,
    tooltip: translate(
      type === 'client' ? 'client.activate' : 'business.coaches_activate',
    ),
  };
};

interface Name {
  firstName: string;
  lastName: string;
}

type UserOrIdentity = Name | { identity: Name };

export const getFullName = (userOrIdentity: UserOrIdentity) => {
  if ('identity' in userOrIdentity) {
    const {
      identity: { firstName, lastName },
    } = userOrIdentity;
    return `${firstName} ${lastName}`;
  }
  const { firstName, lastName } = userOrIdentity;

  return `${firstName} ${lastName}`;
};
