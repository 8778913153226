import { useTrainingProgramFull } from '@strenco/api';
import { FC } from 'react';

import { useParamRoutineId } from '../../../../router/hooks/path-params';

const Prefetch: FC = () => {
  const trainingProgramId = useParamRoutineId();
  useTrainingProgramFull(trainingProgramId);

  return null;
};

export default Prefetch;
