import {
  browserTracingIntegration,
  init,
  replayIntegration,
  setUser,
} from '@sentry/react';

import envs from '../config/envs';
import { User } from '../features/user/types';
import { getRelease, isLocal, isProduction } from './environment';

// eslint-disable-next-line complexity
export const initSentry = () => {
  if (isLocal()) {
    return;
  }

  init({
    _experiments: {
      profilesSampleRate: isProduction() ? 0.1 : 0.5,
    },
    // We recommend adjusting this value in production.
    debug: isProduction() === false,
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
    dsn: envs.sentry.dsn,
    environment: isProduction() ? 'production' : 'development',
    integrations: [
      browserTracingIntegration(),
      replayIntegration({ blockAllMedia: true, maskAllText: true }),
    ],
    release: getRelease(),
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/my.strenco\.app/],
    // Tracing
    tracesSampleRate: isProduction() ? 0.05 : 0.1,
  });
};

export const setSentryUser = (user: Pick<User, 'id' | 'type'>) => {
  if (isLocal()) {
    return;
  }

  setUser({
    id: user.id,
    type: user.type,
  });
};

export const clearSentryUser = () => {
  setUser(null);
};
