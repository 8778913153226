import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  invalidateCognitoUser,
  invalidateCurrentUser,
} from '../../features/user/api/queries/useCognitoUser';
import { refreshSession } from '../../features/user/utils/cognito';
import { useParamProxyType } from '../../router/hooks/path-params';
import { routes } from '../../router/routes';
import { ProxyParam } from '../../types';

const Proxy: FC = () => {
  const navigate = useNavigate();
  const proxyType = useParamProxyType();

  useEffect(() => {
    if (proxyType === ProxyParam.CheckoutSuccess) {
      refreshSession().then(() => {
        Promise.all([invalidateCognitoUser(), invalidateCurrentUser()]).then(
          () =>
            navigate(routes.settings.subscriptions(), {
              replace: true,
            }),
        );
      });
      return;
    }

    navigate(routes.home, { replace: true });
  }, [navigate, proxyType]);

  return null;
};

export default Proxy;
