import { useExercises, useTrainingProgramFull } from '@strenco/api';
import { FC } from 'react';

import { useParamRoutineId } from '../../../router/hooks/path-params';

const WorkoutFullPrefetch: FC = () => {
  const trainingProgramId = useParamRoutineId();
  useExercises();
  useTrainingProgramFull(trainingProgramId, { refetchOnMount: true });
  return null;
};

export default WorkoutFullPrefetch;
