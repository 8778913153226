import { useBusiness } from '@strenco/api';

import { getDataById } from '../../../utils/array';
import { getBusinessQueryOptions } from '../helpers/business';

export const useFacility = (facilityId: null | number | undefined) => {
  const query = useBusiness(getBusinessQueryOptions());

  return {
    ...query,
    data: getDataById(query.data?.facilities, facilityId),
  };
};
