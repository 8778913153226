import { TrainingProgramFull } from '@strenco/api';

import { translate } from '../../i18n';

export const getBreadcrumbWorkoutName = <T extends { name: string }>(
  workout: T | undefined,
) => workout?.name ?? '';

export const getBreadcrumbWorkoutLabel = (
  data: TrainingProgramFull<'template' | 'user'> | undefined,
  workoutId: string,
) => {
  const allIds = data?.trainingProgram.cyclesOrder.flatMap(
    cycleId => data.cycles[cycleId].workoutsOrder,
  );

  const workoutIdx = allIds?.findIndex(id => id === workoutId) ?? -1;

  const total = allIds?.length ?? 0;

  const suffix =
    workoutIdx > -1
      ? ` (${translate('common.of_double', {
          value1: workoutIdx + 1,
          value2: total,
        })})`
      : '';

  return `${translate('workout.workouts_one')}${suffix}`;
};
