/**
 * @file Contains helpers for modals
 */
import { Location } from 'react-router-dom';

import {
  getSearchParam,
  parseUrlString,
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../router/helpers/searchParams';
import { SearchParam, SearchParamValues } from '../../router/types';
import { getSearchParamModalId } from '../../router/urls/searchParams/getters';
import { modalParamsConfig } from './config';
import { ModalAccessibleOptions, ModalId, ModalParams } from './types';

/**
 * Modal specific params that are required for each modal
 */

/**
 * Get the required params for modal, that are required for it to be visible
 * @param modalId The ModalId
 * @returns       Required searchParam keys for the requested modal
 */
export const getRequiredParamsByModalId = (modalId: ModalId) =>
  modalParamsConfig[modalId]?.required ?? [];

/**
 * Get the optional params for modal
 * @param modalId The ModalId
 * @returns       Optional SearchParam keys for the requested modal
 */
export const getOptionalParamsByModalId = (modalId: ModalId) =>
  modalParamsConfig[modalId]?.optional ?? [];

/**
 * Checks whether the URL has all the required search params, so the modal
 * can be visible.
 * @param location History Location
 * @param modalId  ModalId
 * @returns        Whether the URL has all required params for requested modal
 */
export const getHasRequiredModalParams = (
  location: Location,
  modalId: ModalId,
) => {
  const modalIdParam = getSearchParamModalId(location);
  if (modalIdParam !== modalId) return false;

  const params = getRequiredParamsByModalId(modalId);

  return params.every(
    param =>
      getSearchParam(param as keyof SearchParamValues, location) !== null,
  );
};

/**
 * Get whether we should and can show a modal.
 * "Should see" means that the URL has all params that tell us to open a modal.
 * "Can see" means that the user can access the modal. Some reasons why the user
 * can not see the modal are: not enough permissions, the entity was deleted...
 * @param location           History's location object
 * @param modalId            Id of the modal
 * @param options            Additional things we want to check before displaying
 * @param options.fetching   Whether any data we need to validate is fetching
 * @param options.validators Validators that show whether the user CAN access the modal
 * @returns                  Whether the modal should and can be shown [should, can].
 */
export const getShouldShowModal = (
  location: Location,
  modalId: ModalId,
  options?: ModalAccessibleOptions,
) => {
  const { fetching, validators = [] } = options ?? {};

  if (fetching === true) {
    return [false, false];
  }

  const searchParamModalId = getSearchParamModalId(location);

  if (searchParamModalId !== modalId) {
    return [false, false];
  }

  const hasRequiredModalParams = getHasRequiredModalParams(location, modalId);

  return [hasRequiredModalParams, validators.every(bool => bool === true)];
};

/**
 * Creates an URL with params that will open a modal
 * @param location    History location object
 * @param modalId     The modal id of the modal we want to open
 * @param paramConfig Modal specific params that need to be added
 * @returns           A string in URL format
 */
export const getOpenModalUrl = <T extends ModalId>(
  location: Location,
  modalId: T,
  paramConfig?: ModalParams[T],
) => {
  const allowedParams = new Set([
    ...getOptionalParamsByModalId(modalId),
    ...getRequiredParamsByModalId(modalId),
  ]);

  const invalidParams = new Set();

  const params: Record<string, number | string> = {
    [SearchParam.ModalId]: modalId,
  };

  if (paramConfig !== undefined) {
    Object.entries(paramConfig).forEach(([key, value]) => {
      if (allowedParams.has(key as SearchParam) === false) {
        invalidParams.add(key);
      } else if (value !== null) {
        params[key as SearchParam] = value;
      }
    });
  }

  const url = replaceOrAddParamsToUrl(params, location);

  if (invalidParams.size > 0) {
    const [, search] = parseUrlString(url);
    console.error(
      `Invalid modal param in url for modal "${modalId}": ${[
        ...invalidParams,
      ].join(', ')} (${search})`,
    );
  }

  return url;
};

/**
 * Creates an URL without modal related params that will close any modal if open.
 * @param location History location object
 * @returns        A string in URL format
 */
export const getCloseModalUrl = (location: Location) => {
  const modalId = getSearchParamModalId(location);

  // There is no opened modal
  if (modalId === null) {
    const [pathname, search] = parseUrlString(
      `${location.pathname}?${location.search}`,
    );
    return `${pathname}${search}`;
  }

  return removeParamsKeysFromUrl(
    [
      SearchParam.ModalId,
      ...getRequiredParamsByModalId(modalId),
      ...getOptionalParamsByModalId(modalId),
    ],
    location,
  );
};

/**
 * Helper to reduce code repetition and standardize what accessibility hooks
 * return.
 * @param isAccessible Whether the modal is accessible
 * @param data         The data that the modal needs
 * @param fetching     Whether the data is fetching
 * @returns            Data or null and whether it's fetching
 */
export const getModalAccessibleData = <T>(
  isAccessible: boolean,
  data: null | T,
  fetching: boolean,
): [null | T, boolean] => {
  if (isAccessible === false || fetching === true) {
    return [null, fetching];
  }

  return [data, false];
};
