import {
  TraineeFull,
  UserStatus,
  UserType,
  useUserDelete,
  useUserUpdate,
} from '@strenco/api';

import { MutationDelete } from '../../../constants/mutation-keys';
import useBackNavigation from '../../../hooks/useGetBackUrl';
import { useParamClientId } from '../../../router/hooks/path-params';
import { SearchParam } from '../../../router/types';
import { ModalId, useModal } from '../../../utils/modal';
import { confirmDanger } from '../../../utils/modal/confirm';

export const useClientActions = (
  client: Pick<TraineeFull, 'businessId' | 'id' | 'status'>,
) => {
  const paramClientId = useParamClientId();
  const { businessId, id, status } = client;
  const { openModal } = useModal();
  const { goBack } = useBackNavigation();

  const { isPending: isUpdating, mutateAsync: update } = useUserUpdate(
    UserType.Trainee,
    id,
  );
  const { isPending: isDeleting, mutateAsync: deleteClient } = useUserDelete(
    UserType.Trainee,
  );

  const isDeleteDisabled = status === UserStatus.Active;

  const requestActivate = () => update({ status: UserStatus.Active });
  const requestDeactivate = () => update({ status: UserStatus.Inactive });

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.Client,
      onConfirm: () =>
        deleteClient(id).then(() => {
          if (paramClientId) {
            goBack();
          }
        }),
    });
  };

  const requestEdit = () => {
    openModal(businessId ? ModalId.BusinessClientEdit : ModalId.ClientEdit, {
      [SearchParam.ClientId]: id,
    });
  };

  return {
    isDeleteDisabled,
    isDeleting,
    isUpdating,
    requestActivate,
    requestDeactivate,
    requestDelete,
    requestEdit,
  };
};
