import {
  ExerciseCategory,
  ExerciseComplexity,
  ExerciseExecutionType,
  ExerciseType,
  ExerciseVolumeUnit,
  MuscleGroup,
} from '@strenco/api';

interface ExercisesViewProps {
  long: string;
  short: string;
}
interface WithLabel {
  label: string;
}

const exercisesView: Record<ExerciseCategory, ExercisesViewProps> = {
  business: {
    long: 'Company Exercises',
    short: 'Company',
  },
  my: {
    long: 'My Exercises',
    short: 'My',
  },
  strenco: {
    long: 'Strenco Exercises',
    short: 'Strenco',
  },
};

interface Labels {
  label: string;
}

const complexity: Record<ExerciseComplexity, Labels> & WithLabel = {
  [ExerciseComplexity.High]: { label: 'High' },
  [ExerciseComplexity.Low]: { label: 'Low' },
  [ExerciseComplexity.Medium]: { label: 'Medium' },
  [ExerciseComplexity.VeryHigh]: { label: 'Very High' },
  [ExerciseComplexity.VeryLow]: { label: 'Very Low' },
  label: 'Complexity',
};

const executionType: Record<ExerciseExecutionType, Labels> = {
  [ExerciseExecutionType.Dynamic]: { label: 'Dynamic' },
  [ExerciseExecutionType.Static]: { label: 'Static' },
};

const muscleGroup: Record<MuscleGroup, Labels> & {
  primary: string;
  secondary: string;
} = {
  [MuscleGroup.Abductors]: { label: 'Abductors' },
  [MuscleGroup.Abs]: { label: 'Abs' },
  [MuscleGroup.Adductors]: { label: 'Adductors' },
  [MuscleGroup.Biceps]: { label: 'Biceps' },
  [MuscleGroup.Calves]: { label: 'Calves' },
  [MuscleGroup.Chest]: { label: 'Chest' },
  [MuscleGroup.Forearm]: { label: 'Forearms' },
  [MuscleGroup.Glutes]: { label: 'Glutes' },
  [MuscleGroup.Hamstring]: { label: 'Hamstring' },
  [MuscleGroup.HipFlexors]: { label: 'Hip Flexors' },
  [MuscleGroup.Lats]: { label: 'Lats' },
  [MuscleGroup.LowerBack]: { label: 'Lower Back' },
  [MuscleGroup.MidBack]: { label: 'Mid Back' },
  [MuscleGroup.Neck]: { label: 'Neck' },
  [MuscleGroup.Quads]: { label: 'Quads' },
  [MuscleGroup.RotatorCuff]: { label: 'Rotator Cuff' },
  [MuscleGroup.Shins]: { label: 'Shins' },
  [MuscleGroup.Shoulder]: { label: 'Shoulders' },
  [MuscleGroup.Traps]: { label: 'Traps' },
  [MuscleGroup.Triceps]: { label: 'Triceps' },
  primary: 'Primary Muscles',
  secondary: 'Secondary Muscles',
};

const volumeUnit: Record<ExerciseVolumeUnit, Labels & { inputInfo: string }> = {
  [ExerciseVolumeUnit.Distance]: {
    inputInfo: '',
    label: 'Distance',
  },
  [ExerciseVolumeUnit.Reps]: {
    inputInfo:
      'Valid values include a single positive number or a range of positive numbers (e.g., "10" or "10-12").\n Numbers less than 1 are not allowed. Do not include units.',
    label: 'Reps',
  },
  [ExerciseVolumeUnit.Time]: {
    inputInfo:
      'Valid values include a single positive number followed by an optional time unit (e.g., "5 min" or "30 sec"), or a range of such values separated by hyphens (e.g., "1 min - 3 min"). Numbers less than 1 are not allowed. Accepted time units are "min", "s", and "sec".',
    label: 'Time',
  },
};

const table = {
  columns: [
    { label: 'Exercise' },
    { label: 'Primary Muscles' },
    { label: 'Complexity' },
    { label: 'Actions' },
  ],
};

const search = {
  noResult: {
    subtitle: 'You can search another one, or add a new exercise.',
    title: 'The exercise you are looking for does not exist.',
  },
};

const type: Record<ExerciseType, Labels> = {
  [ExerciseType.Compound]: { label: 'Compound' },
  [ExerciseType.Isolation]: { label: 'Isolation' },
};

const translationsExercise = {
  actions: 'Exercise Actions',
  by_strenco: 'This exercise was created by strenco.',
  complexity,
  create: 'Create Exercise',
  delete: 'Delete Exercise',
  duplicate: 'Duplicate Exercise',
  edit: 'Edit Exercise',
  empty_data: {
    cta: 'Add New Exercise',
    description: 'You can create new exercises to get started.',
    title: 'No Exercises Available',
  },
  empty_search: {
    cta: 'Create This Exercise',
    description:
      'No exercises match your search. You may want to try a different search or ',
    title: 'No Exercises Found',
  },
  executionType,
  exercisesView,
  info: 'More about this exercise',
  label: 'Exercise',
  muscleGroup,
  personalize: 'Create my customizable version',
  regular: 'Regular',
  search,
  table,
  thumbnail_not_available: 'No thumbnail added yet.',
  type,
  unilateral: 'Unilateral',
  variations: 'Variations',
  video_not_available: 'No video added yet.',
  volumeUnit,
};

export default translationsExercise;
