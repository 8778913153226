import { useEntityUserContext, useTrainingProgramContext } from '@strenco/api';
import { useMemo } from 'react';

export const useWorkoutActionContext = () => {
  const userId = useEntityUserContext().id;
  const trainingProgramId = useTrainingProgramContext().id;
  return useMemo(
    () => ({ trainingProgramId, userId }),
    [userId, trainingProgramId],
  );
};
