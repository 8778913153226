import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import ClientRoutineFullHeaderActions from '../../../features/routine/@client/components/RoutineFullHeaderActions';
import RoutineFullSecondaryNavigation from '../../../features/routine/components/routine/RoutineFullSecondaryNavigation';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';

const RoutineFullLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={ClientRoutineFullHeaderActions}
        title={getPageLabel(PageId.ClientsRoutineDetails)}
      />
    );
  }

  return (
    <RoutineFullSecondaryNavigation pageId={PageId.ClientsRoutineDetails} />
  );
};

export default RoutineFullLayout;
