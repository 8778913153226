import {
  ConversionType,
  useConversion,
  UserTrainingProgramStatus,
  useTrainingProgramContext,
  useTrainingProgramDelete,
  useTrainingProgramFull,
  useTrainingProgramUpdate,
} from '@strenco/api';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../../constants/mutation-keys';
import { translate } from '../../../../../i18n';
import { useParamRoutineId } from '../../../../../router/hooks/path-params';
import useCurrentPath from '../../../../../router/hooks/useCurrentPath';
import { getBackUrl } from '../../../../../router/paths/back-paths';
import { PATH_FRAGMENT__CLIENT_ID } from '../../../../../router/paths/path-fragments';
import { PATH__ME } from '../../../../../router/paths/paths';
import { SearchParam } from '../../../../../router/types';
import { ModalId, useModal } from '../../../../../utils/modal';
import { confirmDanger } from '../../../../../utils/modal/confirm';
import { useWorkoutActionContext } from '../../../hooks/useWorkoutActionsContext';
import {
  getProgramTemplateStatusUpdateOptions,
  getUserProgramStatusUpdateOptions,
} from '../../helpers/routine';

// eslint-disable-next-line complexity
const ClientRoutineFullHeaderActions: FC = () => {
  const type = useTrainingProgramContext().type;
  const context = useWorkoutActionContext();
  const currentPath = useCurrentPath();
  const location = useLocation();
  const navigate = useNavigate();
  const trainingProgramId = useParamRoutineId()!;
  const { data } = useTrainingProgramFull(trainingProgramId);

  const { openModal } = useModal();
  const { isPending: isDeleting, mutateAsync: deleteRoutineMutation } =
    useTrainingProgramDelete(trainingProgramId!);
  const { isPending: isStatusUpdating, mutate: updateUserTrainingProgram } =
    useTrainingProgramUpdate(trainingProgramId!);
  const { isPending: isConverting, mutateAsync: convert } = useConversion();

  const conversionType =
    type === 'template'
      ? ConversionType.ProgramTemplateToUserProgram
      : ConversionType.UserProgramToProgramTemplate;

  const backUrl = currentPath?.startsWith(PATH__ME)
    ? PATH__ME
    : getBackUrl(currentPath, location, {
        toPathFragment: PATH_FRAGMENT__CLIENT_ID,
      });

  const labelConvert = translate(`common.conversion.${conversionType}.title`);

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.ClientRoutine,
      onConfirm: () => {
        if (data === undefined) {
          return;
        }

        deleteRoutineMutation(context);
        const status = data.trainingProgram.info.status;

        const toStatus =
          status === UserTrainingProgramStatus.Ready
            ? UserTrainingProgramStatus.InProgress
            : status;

        navigate(
          {
            pathname: backUrl,
            search: `${SearchParam.RoutineStatus}=${toStatus}`,
          },
          { replace: true },
        );
      },
    });
  };

  const requestEdit = () => {
    openModal(ModalId.TrainingProgramDetails, {
      [SearchParam.ClientId]: context.userId,
      [SearchParam.EntityId]: trainingProgramId,
    });
  };

  const requestConvert = async () => {
    if (trainingProgramId === null) {
      return;
    }
    if (type === 'user') {
      await convert({
        context: { trainingProgramId: 'template', userId: 'template' },
        payload: {
          params: { trainingProgramId: trainingProgramId },
          type: ConversionType.UserProgramToProgramTemplate,
        },
      });
    } else {
      openModal(ModalId.GenerateClientRoutines, {
        [SearchParam.EntityId]: trainingProgramId,
      });
    }
  };

  const items: MenuItem[] = [
    {
      Icon: iconsMap.edit.Icon,
      label: translate('routine.edit'),
      onClick: requestEdit,
    },
    {
      Icon: iconsMap.export.Icon,
      label: labelConvert,
      onClick: requestConvert,
    },
    {
      Icon: iconsMap.delete.Icon,
      label: translate('routine.delete'),
      onClick: requestDelete,
    },
  ];

  items.push({
    isSectionLabel: true,
    label: translate('common.status_change_to'),
  });

  if (data?.type === 'user') {
    const { status, userId } = data.trainingProgram.info;

    const statusUpdateOptions = getUserProgramStatusUpdateOptions(
      status,
      updatedStatus => {
        updateUserTrainingProgram({
          context: { trainingProgramId, userId },
          userTrainingProgram: { status: updatedStatus },
        });
      },
    );

    items.push(...statusUpdateOptions);
  }

  if (data?.type === 'template') {
    const { status } = data.trainingProgram.info;

    const statusUpdateOptions = getProgramTemplateStatusUpdateOptions(
      status,
      updatedStatus => {
        updateUserTrainingProgram({
          context: { trainingProgramId, userId: 'template' },
          trainingProgramTemplate: { status: updatedStatus },
        });
      },
    );

    items.push(...statusUpdateOptions);
  }

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      trigger={
        <ActionIconHeaderActions
          label={translate('routine.actions')}
          loading={isDeleting || isConverting || isStatusUpdating}
        />
      }
    />
  );
};

export default ClientRoutineFullHeaderActions;
