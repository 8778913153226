import {
  EntityUserProvider,
  TrainingProgramProvider,
  WorkoutProvider,
} from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../constants/page-ids';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../i18n/helpers/page';
import {
  useParamClientId,
  useParamRoutineId,
  useParamWorkoutId,
} from '../../../router/hooks/path-params';
import Layout from './layout';
import WorkoutFullPrefetch from './prefetch';

const Content = lazy(() => import('./content'));

interface Props {
  clientId?: string;
}
const ClientWorkoutFullPage: FC<Props> = ({ clientId }) => {
  useDocumentTitle(getPageLabel(PageId.ClientsWorkoutDetails));
  const paramClientId = useParamClientId() ?? clientId;
  const trainingProgramId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  if (!paramClientId || !trainingProgramId || !workoutId) return null;

  return (
    <EntityUserProvider id={paramClientId}>
      <TrainingProgramProvider id={trainingProgramId} type="user">
        <WorkoutProvider id={workoutId} type="user">
          <WorkoutFullPrefetch />
          <Layout />
          <Suspense fallback={null}>
            <Content />
          </Suspense>
        </WorkoutProvider>
      </TrainingProgramProvider>
    </EntityUserProvider>
  );
};

export default ClientWorkoutFullPage;
