import { EntityUserProvider } from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../../constants/page-ids';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../../i18n/helpers/page';
import Layout from './layout';
import Prefetch from './prefetch';

const Content = lazy(() => import('./content'));

const TemplateRoutinesRootPage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.TemplateRoutines));

  return (
    <EntityUserProvider id="template">
      <Prefetch />
      <Layout />
      <Suspense fallback={null}>
        <Content />
      </Suspense>
    </EntityUserProvider>
  );
};

export default TemplateRoutinesRootPage;
