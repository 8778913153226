import { MantineColor } from '@mantine/core';
import {
  CheckInType,
  ExerciseCategory,
  ExerciseVolumeUnit,
  PaddleProductType,
  PaddleProductVariant,
  TimeUnit,
  UserStatus,
} from '@strenco/api';

import { vars } from '../theme';
import {
  PaddleBillingInterval,
  PaddleSubscriptionStatus,
} from '../types/product.types';

export const BODYWEIGHT_CHART_HEIGHT = 180;
export const BODYWEIGHT_CHART_HEIGHT_EMPTY = 100;
export const BODYWEIGHT__MIN_WEIGHT = 20;

export const CHECK_IN__DEFAULT_VALUE__ENERGY = 8;
export const CHECK_IN__DEFAULT_VALUE__MOOD = 8;
export const CHECK_IN__DEFAULT_VALUE__SLEEP = 8;
export const CHECK_IN__DEFAULT_VALUE__SORENESS = 5;

export const TIME_UNIT_ORDER: TimeUnit[] = [
  TimeUnit.Seconds,
  TimeUnit.Minutes,
  TimeUnit.Hours,
];

export const CHECK_INS_COLORS = {
  energy: '#4BCDFA',
  mood: '#9647FB',
  sleep: '#93c572',
  soreness: '#FF4D00',
};

export const VOLUME_UNIT__ORDER = [
  ExerciseVolumeUnit.Reps,
  ExerciseVolumeUnit.Time,
  ExerciseVolumeUnit.Distance,
];

export const CHECK_INS_CHART_HEIGHT = 220;
export const CHECK_INS_CHART_HEIGHT_EMPTY = 100;

export const CHECK_INS_FIELDS_ORDER: CheckInType[] = [
  'soreness',
  'sleep',
  'energy',
  'mood',
];

export const CHECK_INS_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const FILE__AVATAR_SIZE_KB_LIMIT = 512;

export const USER_STATUS_ORDER: UserStatus[] = [
  UserStatus.SignUpInstructionsSent,
  UserStatus.Active,
  UserStatus.Inactive,
];

export const USER_STATUS_COLOR: Record<UserStatus, MantineColor> = {
  [UserStatus.Active]: 'green.5',
  [UserStatus.Inactive]: 'red.6',
  [UserStatus.SignUpInstructionsSent]: 'violet.5',
};

export const EXERCISE_CATEGORY_ORDER: ExerciseCategory[] = [
  'my',
  'business',
  'strenco',
];

export const RAIL_WIDTH = 72;
export const RAIL_DRAWER_WIDTH = 260;
export const BOTTOM_NAVIGATION__HEIGHT = 72;
export const HEADER_HEIGHT = 64;
export const HEADER_AND_RAIL_COLOR = vars.colors.dark[6];
export const SIDEBAR_COLOR = vars.colors.dark[5];
export const CONTENT_COLOR = vars.colors.gray[0];
export const SUBHEADER_TITLE_ORDER = 3;
/**
 * Small icons like dots
 */
export const DRAWER_NAV_ITEM_ICON__SIZE_DEFAULT = 12;
/**
 * How much larger the icon should be when active
 */
export const DRAWER_NAV_ITEM_ICON__ACTIVE_SIZE_FACTOR = 1.2;
export const DRAWER_NAV_ITEM_ICON__COLOR_DEFAULT = vars.colors.dark[2];
/**
 * Fancy icons like gear, user etc
 */
export const DRAWER_NAV_ITEM_RICH_ICON__SIZE = 18;

export const DATE__DEFAULT_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export const TABLE__DESKTOP_MIN_WIDTH = 800;

export const PASSWORD__MIN_LENGTH = 8;
export const IDLE__TIMEOUT_MS = 10000;
export const CLIENTS__REFETCH_INTERVAL = 1000 * 60 * 1; // 1 minute

export const PRODUCT__TYPE_ORDER: PaddleProductType[] = [
  PaddleProductType.Personal,
  PaddleProductType.Coach,
  PaddleProductType.Business,
];

export const PRODUCT__VARIANT_ORDER: PaddleProductVariant[] = [
  PaddleProductVariant.Starter,
  PaddleProductVariant.Standard,
  PaddleProductVariant.Pro,
];

export const SUBSCRIPTION_STATUS__COLOR: Record<
  PaddleSubscriptionStatus,
  MantineColor
> = {
  active: 'green',
  canceled: 'red',
  past_due: 'orange',
  paused: 'yellow',
  trialing: 'purple',
};

export const PADDLE__DEFAULT_BILLING_INTERVAL = PaddleBillingInterval.Month;
export const PADDLE__DEFAULT_PRODUCT_TYPE: PaddleProductType =
  PaddleProductType.Coach;
