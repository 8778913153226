import { createContext, useContext } from 'react';

interface SidebarContextProps {
  close: () => void;
  isOpen: boolean;
  open: () => void;
  toggle: () => void;
}

export const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined,
);

export const useSidebarContext = () => {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error(
      'useSidebarContext can not be used outside of SidebarProvider',
    );
  }

  return context;
};

