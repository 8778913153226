import {
  Anchor,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  ExerciseDeleteReferenceRestrictErrorResponse,
  patchExerciseSetGroup,
  QueryKey,
  useCurrentUser,
  useExerciseListOne,
} from '@strenco/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { Link } from 'react-router-dom';

import http from '../../../../../config/http';
import { translate } from '../../../../../i18n';
import { getHashWorkoutId } from '../../../../../router/helpers/hash';
import {
  PATH_FRAGMENT__ROUTINES,
  PATH_FRAGMENT__WORKOUTS,
} from '../../../../../router/paths/path-fragments';
import {
  PATH__CLIENTS,
  PATH__LIBRARY__TEMPLATE_ROUTINES,
  PATH__LIBRARY__TEMPLATE_WORKOUTS,
} from '../../../../../router/paths/paths';
import { routes } from '../../../../../router/routes';
import { Unpacked } from '../../../../../types';
import toast, { ToastErrorId } from '../../../../../utils/toast';
import { ExercisePickerAutocomplete } from '../ExercisePickerAutocomplete';

type GetWorkoutUrl = Unpacked<
  ExerciseDeleteReferenceRestrictErrorResponse['affectedWorkouts']
> & { currentUserId: string | undefined };

type WorkoutType = 'standalone' | 'template' | 'user';

const getWorkoutUrl = ({
  currentUserId,
  traineeId,
  trainingProgramId,
  workoutId,
}: GetWorkoutUrl): { type: WorkoutType; url: string } => {
  if (!trainingProgramId) {
    // Standalone workout template
    return {
      type: 'standalone',
      url: `${PATH__LIBRARY__TEMPLATE_WORKOUTS}/${workoutId}`,
    };
  }

  if (!traineeId) {
    // Training program template
    return {
      type: 'template',
      url: `${PATH__LIBRARY__TEMPLATE_ROUTINES}/${trainingProgramId}/${getHashWorkoutId(workoutId)}`,
    };
  }

  if (traineeId === currentUserId) {
    return {
      type: 'user',
      url: routes.me.workoutsDetails(trainingProgramId, workoutId),
    };
  }

  return {
    type: 'user',
    url: PATH__CLIENTS.concat(
      '/',
      traineeId,
      PATH_FRAGMENT__ROUTINES,
      '/',
      trainingProgramId,
      PATH_FRAGMENT__WORKOUTS,
      '/',
      workoutId,
    ),
  };
};

/** @todo - when templates are added, handle them as well */

export default function ExerciseInUseModalContent({
  affectedWorkouts,
  exerciseId,
  onChange,
}: ExerciseDeleteReferenceRestrictErrorResponse & {
  exerciseId: string;
  onChange: () => void;
}) {
  const queryClient = useQueryClient();
  const { data: currentUser } = useCurrentUser();
  const { exercise } = useExerciseListOne(exerciseId);
  const { isPending, mutate } = useMutation({
    mutationFn: async (newExerciseId: null | string) => {
      if (newExerciseId === null) {
        return;
      }
      const exerciseSetGroups = affectedWorkouts.flatMap(
        workout => workout.exerciseSetGroups,
      );

      const trainingProgramIds = [
        ...new Set(
          affectedWorkouts
            .filter(workout => workout.trainingProgramId)
            .map(workout => workout.trainingProgramId),
        ),
      ];

      const traineeIds = [
        ...new Set(
          affectedWorkouts
            .filter(workout => workout.traineeId)
            .map(workout => workout.traineeId),
        ),
      ];

      await Promise.all(
        exerciseSetGroups.map(id =>
          patchExerciseSetGroup(
            http,
            id,
          )({
            context: { trainingProgramId: '', userId: undefined }, // context is used for cache invalidation, but we do that manually below
            exerciseSetGroup: { exerciseId: newExerciseId },
          }),
        ),
      );

      await Promise.all([
        ...trainingProgramIds.map(tpId =>
          queryClient.invalidateQueries({
            queryKey: QueryKey.TrainingProgramOne(tpId),
          }),
        ),
        ...traineeIds.map(id =>
          queryClient.invalidateQueries({
            queryKey: QueryKey.TrainingPrograms(id),
          }),
        ),
      ]);
      modals.closeAll();
      onChange();
    },
    onError: error => {
      if (isAxiosError(error)) {
        toast.apiError(error);
      } else {
        toast.error(ToastErrorId.General);
      }
    },
  });

  return (
    <Stack h="60vh" mah={800} mih={400}>
      <Text c="red.6">{translate('exercise.not_deleted__info')}</Text>
      <Text component="label" lh={2} size="sm">
        {translate('exerciseInstruction.selectExercise')}
        <ExercisePickerAutocomplete
          autoFocus={true}
          defaultSelected={exerciseId}
          isLoading={isPending}
          onChange={mutate}
          volumeUnitFilter={new Set(exercise?.volumeUnits)}
        />
      </Text>
      <LoadingOverlay visible={isPending} />
      <Space h={10} />
      {affectedWorkouts.length > 0 && (
        <>
          <Text fw="bold" size="sm">
            {translate('workout.workouts')}
          </Text>
          <Grid pb="xl">
            {affectedWorkouts.map(workout => {
              const { url } = getWorkoutUrl({
                ...workout,
                currentUserId: currentUser?.id,
              });
              return (
                <Grid.Col key={workout.workoutId} span={{ base: 12, sm: 6 }}>
                  <Anchor component={Link} to={url} underline="hover">
                    <Paper
                      component={Group}
                      justify="space-between"
                      p="xs"
                      withBorder={true}
                    >
                      <Text
                        size="sm"
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {workout.workoutName}
                      </Text>
                    </Paper>
                  </Anchor>
                </Grid.Col>
              );
            })}
          </Grid>
        </>
      )}
    </Stack>
  );
}
