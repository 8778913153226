import {
  EntityUserProvider,
  TrainingProgramCycleProvider,
  TrainingProgramProvider,
} from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../../constants/page-ids';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../../i18n/helpers/page';
import Layout from './layout';
import Prefetch from './prefetch';

const Content = lazy(() => import('./content'));

const TemplateWorkoutsRootPage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.TemplateWorkouts));

  return (
    <EntityUserProvider id="template">
      <TrainingProgramProvider id="template" type="template">
        <TrainingProgramCycleProvider id={-1}>
          <Prefetch />
          <Layout />
          <Suspense fallback={null}>
            <Content />
          </Suspense>
        </TrainingProgramCycleProvider>
      </TrainingProgramProvider>
    </EntityUserProvider>
  );
};

export default TemplateWorkoutsRootPage;
