import {
  BusinessUser,
  useBusinessCache,
  UserStatus,
  UserType,
  useUserDelete,
  useUserUpdate,
} from '@strenco/api';

import { MutationDelete } from '../../../constants/mutation-keys';
import useBackNavigation from '../../../hooks/useGetBackUrl';
import useCurrentPath from '../../../router/hooks/useCurrentPath';
import {
  PATH_FRAGMENT__COACHES,
  PATH_FRAGMENT__FACILITY_ID,
} from '../../../router/paths/path-fragments';
import { PATH__BUSINESS__FACILITIES } from '../../../router/paths/paths';
import { SearchParam } from '../../../router/types';
import { ModalId, useModal } from '../../../utils/modal';
import { confirmDanger } from '../../../utils/modal/confirm';

const useCoachActions = (coach: Pick<BusinessUser, 'id' | 'status'>) => {
  const cache = useBusinessCache();
  const { goBack } = useBackNavigation();
  const currentPath = useCurrentPath();
  const { id, status } = coach;
  const { openModal } = useModal();

  const { isPending, mutateAsync: updateUser } = useUserUpdate(
    UserType.BusinessCoach,
    id,
  );
  const { isPending: isDeleting, mutateAsync } = useUserDelete(
    UserType.BusinessCoach,
  );

  const isDeleteDisabled = isPending || status === UserStatus.Active;

  const requestActivate = () =>
    updateUser({ status: UserStatus.Active }).finally(cache.invalidate);

  const requestDeactivate = () =>
    updateUser({ status: UserStatus.Inactive }).finally(cache.invalidate);

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.BusinessCoach,
      onConfirm: () =>
        mutateAsync(id)
          .then(() => {
            if (currentPath?.startsWith(PATH__BUSINESS__FACILITIES)) {
              goBack({ toPathFragment: PATH_FRAGMENT__FACILITY_ID });
            } else {
              goBack({ toPathFragment: PATH_FRAGMENT__COACHES });
            }
          })
          .finally(cache.invalidate),
    });
  };

  const requestEdit = () => {
    openModal(ModalId.BusinessCoachEdit, {
      [SearchParam.EntityId]: id,
    });
  };

  return {
    isDeleteDisabled,
    isDeleting,
    isUpdating: isPending,
    requestActivate,
    requestDeactivate,
    requestDelete,
    requestEdit,
  };
};

export default useCoachActions;
