export enum AlertLevel {
  Critical = 'critical',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum ErrorParam {
  Forbidden = 'forbidden',
  General = 'general',
  NotFound = 'not-found',
  PageNotFound = 'page-not-found',
}

export enum MediaType {
  Avatar = 'Avatar',
  BusinessAvatar = 'BusinessAvatar',
  BusinessLogo = 'BusinessLogo',
  BusinessLogoBlack = 'BusinessLogoBlack',
  BusinessLogoWhite = 'BusinessLogoWhite',
  ExerciseThumbnail = 'ExerciseThumbnail',
  ExerciseVideo = 'ExerciseVideo',
}

export enum ProxyParam {
  CheckoutSuccess = 'checkout-success',
}

export interface Timestamps {
  createdAt?: string;
  deletedAt?: null | string;
  updatedAt?: string;
}

export type Unpacked<T> = T extends (infer U)[] ? U : T;
