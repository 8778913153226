import { AxiosError, isAxiosError } from 'axios';

import { ApiErrorType } from '../constants/errors/api-errors';
import { CognitoError } from '../constants/errors/cognito';

/**
 * Get error message key
 * @param error Should accept all errors
 * @returns     An valid key for translations @todo - needs implementation
 */
export const getApiErrorCode = (error: never | unknown): string => {
  if (error instanceof AxiosError && error?.response?.data?.error?.code) {
    return error?.response?.data?.error?.code;
  }

  console.error('UNRECOGNIZED_ERROR', error);

  return 'Default';
};

/**
 * Get error message
 * @param error Should accept all errors
 * @returns     Error message from backend
 */
export const getApiErrorMessage = (error: never | unknown): string => {
  if (error instanceof AxiosError && error?.response?.data?.error?.message) {
    return error?.response?.data?.error?.message;
  }

  console.error('UNRECOGNIZED_ERROR', error);

  return 'Error';
};

/**
 * Get error details
 * @param error Should accept all errors
 * @returns     Error details object
 */
export const getApiErrorDetails = <T = any>(
  error: never | unknown,
): T | undefined => {
  if (error instanceof AxiosError && error?.response?.data?.error?.details) {
    return error?.response?.data?.error?.details;
  }

  console.error('UNRECOGNIZED_ERROR', error);

  return undefined;
};

/**
 * Get error message key
 * @param error        Should accept all errors
 * @param defaultError The error we want to return if it does not match any handled errors
 * @returns            An valid key for translations @todo - needs implementation
 */
export const getApiErrorType = (
  error: never | unknown,
  defaultError = ApiErrorType.General,
): ApiErrorType => {
  if (error instanceof AxiosError && error?.response?.data?.error?.type) {
    const msg = error?.response?.data?.error?.type;

    return msg in ApiErrorType ? (msg as ApiErrorType) : defaultError;
  }

  console.error('UNRECOGNIZED_ERROR');

  return defaultError;
};

type CognitoErrorType = {
  code: CognitoError;
  error?: { message: string; name?: CognitoError };
  name?: CognitoError;
};

/**
 * Extracts the error code from the cognito error object
 * @param err          Any error object that comes from cognito
 * @param defaultError The default error message to display if none error found
 * @returns            The cognito error key we can handle in the app
 */
export const getCognitoErrorMessage = (
  err: never | unknown,
  defaultError = CognitoError.CognitoDefaultError,
): CognitoError => {
  const theError = err as AxiosError | CognitoErrorType;

  if (isAxiosError(theError)) {
    const theData = theError.response?.data as CognitoErrorType;

    return theData?.error?.name ?? defaultError;
  }
  return (
    theError?.error?.name ??
    theError?.code ??
    (theError as CognitoErrorType)?.name ??
    defaultError
  );
};

/**
 * Get whether an error object is NewPasswordRequired error from cognito
 * @param err Any error object
 * @returns   Whether the error is a cognito error which requires a new password to be set
 */
export const isRequireNewPassword = (err: never | unknown): boolean => {
  return (
    (err as { message: string }).message.search(
      CognitoError.PasswordResetRequiredException,
    ) > -1
  );
};
