import { FormId } from '../../constants/forms/form';

interface FormProp {
  description: string;
  info: string;
  title: string;
}

const translationForm: Record<FormId, FormProp> = {
  [FormId.BodyweightCreate]: {
    description: '',
    info: '',
    title: 'Add new bodyweight',
  },
  [FormId.BusinessAdminCreate]: {
    description: 'Add a new admin to your business',
    info: "Please fill in the admin's details to add them.",
    title: 'Create a new admin user',
  },
  [FormId.BusinessAdminEdit]: {
    description: '',
    info: '',
    title: 'Edit Admin',
  },
  [FormId.BusinessCoachCreate]: {
    description: 'Add a new coach to your business',
    info: "Please fill in the coach's details to add them.",
    title: 'Create a new coach user',
  },
  [FormId.BusinessCoachEdit]: {
    description: '',
    info: '',
    title: 'Edit Coach',
  },
  [FormId.BusinessCoachRankCreate]: {
    description: '',
    info: '',
    title: 'Create Coach Level',
  },
  [FormId.BusinessCoachRankEdit]: {
    description: '',
    info: '',
    title: 'Edit Coach Level',
  },
  [FormId.BusinessFacilityCreate]: {
    description: '',
    info: '',
    title: 'Create Facility',
  },
  [FormId.BusinessFacilityEdit]: {
    description: '',
    info: '',
    title: 'Edit Facility',
  },
  [FormId.CheckInCreate]: {
    description: '',
    info: 'Enter values between zero and 10',
    title: 'Create a new check in',
  },
  [FormId.ClientCreate]: {
    description: 'Add a new client to your account',
    info: "Please fill in the client's details to add them.",
    title: 'Create a new client',
  },
  [FormId.ClientEdit]: {
    description: '',
    info: '',
    title: 'Edit Client',
  },
  [FormId.ClientRoutineDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Routine',
  },
  [FormId.ClientRoutineDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Training Program',
  },
  [FormId.ClientWorkoutDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Workout',
  },
  [FormId.ClientWorkoutDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Workout',
  },
  [FormId.CommentsCreator]: {
    description: 'Comments',
    info: 'Add or remove comments the clients will see.',
    title: 'Comments',
  },
  [FormId.CycleCreate]: {
    description: '',
    info: '',
    title: 'Create Cycle',
  },
  [FormId.CycleEdit]: {
    description: '',
    info: '',
    title: 'Edit Cycle',
  },
  [FormId.ExerciseCreate]: {
    description: '',
    info: '',
    title: 'Create New Exercise',
  },
  [FormId.ExerciseEdit]: {
    description: '',
    info: '',
    title: 'Edit Exercise',
  },
  [FormId.PasswordChange]: {
    description: 'Change your current password',
    info: 'To change your password, please provide your current and new password.',
    title: 'Change Password',
  },
  [FormId.PasswordForgot]: {
    description: 'Forgot your password?',
    info: 'Please enter your email to reset your password.',
    title: 'Forgot Password',
  },
  [FormId.PasswordNew]: {
    description: 'Enter your new password',
    info: 'Please create a password that is at least 8 characters long and includes one uppercase letter, one lowercase letter, one number, and one special character.',
    title: 'New Password',
  },
  [FormId.PasswordReset]: {
    description: 'Reset your password',
    info: 'Please enter your new password to reset it.',
    title: 'Reset Password',
  },
  [FormId.RoutineCreate]: {
    description: '',
    info: '',
    title: 'Create Routine',
  },
  [FormId.RoutineGenerate]: {
    description: 'This template will be used to create routines for clients.',
    info: 'Choose clients you want the routine to be created for.',
    title: 'Generate routine for client',
  },
  [FormId.SignIn]: {
    description: 'Sign in to your account',
    info: 'Please enter your credentials to sign in.',
    title: 'Sign In',
  },
  [FormId.SignUp]: {
    description: 'Create a new account',
    info: 'To create an account, please fill in the required information.',
    title: 'Welcome to Strenco',
  },
  [FormId.SubscriptionChange]: {
    description:
      'Select a new plan to upgrade or downgrade your subscription. Changes will take effect on your next billing cycle if you downgrade, or immediately if you upgrade.',
    info: 'Update your subscription plan',
    title: 'Change Subscription Plan',
  },
  [FormId.TemplateRoutineDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Program Template',
  },
  [FormId.TemplateRoutineDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Training Program Template',
  },
  [FormId.TemplateWorkoutDetailsCreate]: {
    description: '',
    info: '',
    title: 'Create Workout Template',
  },
  [FormId.TemplateWorkoutDetailsEdit]: {
    description: '',
    info: '',
    title: 'Edit Workout Template',
  },
  [FormId.UserProfileEdit]: {
    description: '',
    info: '',
    title: 'Edit Profile',
  },
};

export default translationForm;
