import { UserType } from '@strenco/api';
import {
  IconBox,
  IconBuilding,
  IconDotsVertical,
  IconFolders,
  IconHome,
  IconSettings,
  IconUsers,
} from '@tabler/icons-react';

import { INavItem } from '../../components/layout/NavigationDesktop/types';
import { PageId } from '../../constants/page-ids';
import { getUser } from '../../features/user/api/cache/getters';
import {
  isBusinessAdmin,
  isBusinessOwner,
  isSuperAdmin,
} from '../../features/user/utils';
import { getPageLabel } from '../../i18n/helpers/page';
import { PATH__LIBRARY, PATH__MORE, PATH__SETTINGS } from '../paths/paths';
import { routes } from '../routes';
import { RailNavigationItemName } from '../types';

/**
 * Get whether the current NavItem should be active
 * @param pathname Pathname
 * @param to       Main NavItem link
 * @param links    Child link items
 * @returns        True if it should be active, otherwise false
 */
export const getIsNavItemActive = (
  pathname: string,
  to: string | undefined,
  links: Array<{ to: string }>,
) => {
  return to
    ? pathname.startsWith(to.split('?')[0])
    : links?.some(p => pathname === p.to);
};

export const parseUrl = (url: string, keepSearchParams: boolean = true) => {
  const parsedUrl = new URL(url);
  const newSearchParams = new URLSearchParams();

  if (keepSearchParams) {
    // Keep the existing search params
    parsedUrl.searchParams.forEach((value, key) => {
      newSearchParams.append(key, value);
    });
  }

  // Update the URL with the new search params
  parsedUrl.search = newSearchParams.toString();

  return parsedUrl.toString();
};

type TGetNavLink = INavItem | null;

/**
 * Get NavLink - Clients
 * @returns Sidebar navigation link
 */
export const getNavLinkClients = (): TGetNavLink => {
  return {
    icon: IconUsers,
    label: getPageLabel(PageId.Clients),
    name: RailNavigationItemName.Clients,
    to: routes.clients.root(),
  };
};

/**
 * Get NavLink - Company
 * @returns Sidebar navigation link
 */
export const getNavLinkBusiness = (): TGetNavLink => {
  if (isBusinessOwner() || isBusinessAdmin() || isSuperAdmin()) {
    return {
      icon: IconBuilding,
      label: getPageLabel(PageId.Business),
      name: RailNavigationItemName.Business,
      to: routes.business.root(),
    };
  } else {
    return null;
  }
};

/**
 * Get NavLink - Home
 * @returns Sidebar navigation link
 */
export const getNavLinkHome = (): TGetNavLink => {
  return {
    icon: IconHome,
    label: getPageLabel(PageId.Home),
    name: RailNavigationItemName.Home,
    to: routes.home,
  };
};

/**
 * Get NavLink - My Stuff
 * @returns Sidebar navigation link
 */
export const getNavLinkMyStuff = (): TGetNavLink => {
  return {
    icon: IconBox,
    label: getPageLabel(PageId.MyStuff),
    name: RailNavigationItemName.MyStuff,
    to: routes.me.root(),
  };
};

/**
 * Get NavLink - Settings
 * @returns Sidebar navigation link
 */
export const getNavLinkSettings = (): TGetNavLink => {
  return {
    icon: IconSettings,
    label: getPageLabel(PageId.Settings),
    name: RailNavigationItemName.Settings,
    to: routes.settings.root(),
  };
};

/**
 * Get NavLink - Library
 * @returns Sidebar navigation link
 */
export const getNavLinkLibrary = (): TGetNavLink => {
  return {
    icon: IconFolders,
    label: getPageLabel(PageId.Library),
    name: RailNavigationItemName.Library,
    to: routes.library.root(),
  };
};

/**
 * Get NavLink - Library
 * @returns Sidebar navigation link
 */
export const getNavLinkMore = (): TGetNavLink => {
  return {
    icon: IconDotsVertical,
    label: getPageLabel(PageId.More),
    name: RailNavigationItemName.More,
    to: routes.more.root(),
  };
};

/**
 * Get All Navigation links
 * @returns Sidebar navigation link
 */
export const getNavItems = (): TGetNavLink[] => {
  const links = [getNavLinkHome()];

  const user = getUser();

  if (user === null) {
    return links;
  }

  if (
    user.type === UserType.BusinessAdmin ||
    user.type === UserType.BusinessOwner
  ) {
    links.push(getNavLinkBusiness());
  }

  if (user.type === UserType.Coach || user.type === UserType.BusinessCoach) {
    links.push(getNavLinkClients());
  }

  links.push(getNavLinkMyStuff(), getNavLinkLibrary());

  return links;
};

/**
 * Get All Navigation links
 * @returns Sidebar navigation link
 */
export const getNavItemsMobile = (): TGetNavLink[] => {
  const links = [getNavLinkHome()];

  const user = getUser();

  if (user === null) {
    return links;
  }

  if (
    user.type === UserType.BusinessAdmin ||
    user.type === UserType.BusinessOwner
  ) {
    links.push(getNavLinkBusiness());
  }

  if (user.type === UserType.Coach || user.type === UserType.BusinessCoach) {
    links.push(getNavLinkClients());
  }

  links.push(getNavLinkMyStuff(), getNavLinkMore());

  return links;
};

const moreChildPaths = [PATH__MORE, PATH__SETTINGS, PATH__LIBRARY];

export const getIsMoreActive = (currentPath: null | string) => {
  if (currentPath === null) {
    return false;
  }

  return moreChildPaths.some(path => currentPath.startsWith(path));
};
