import { useTrainingProgramContext, useWorkoutContext } from '@strenco/api';
import { FC } from 'react';

import { ActionIconHeaderActions } from '../../../../../components/common/ActionIcons';
import CustomMenu from '../../../../../components/ui/menus/CustomMenu';
import { translate } from '../../../../../i18n';
import { useWorkoutActionItems } from '../../../hooks/useWorkoutActionItems';
import { useStandaloneTemplateWorkoutActionItems } from '../../hooks/useStandaloneWorkoutTemplateActions';

const StandaloneWorkoutTemplateActions: FC = () => {
  const workoutId = useWorkoutContext().id;
  const { isLoading, items } =
    useStandaloneTemplateWorkoutActionItems(workoutId);

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      trigger={
        <ActionIconHeaderActions
          label={translate('workout.actions')}
          loading={isLoading}
        />
      }
    />
  );
};

const WorkoutHeaderActionsRegular: FC = () => {
  const { isLoading, items } = useWorkoutActionItems();

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      trigger={
        <ActionIconHeaderActions
          label={translate('workout.actions')}
          loading={isLoading}
        />
      }
    />
  );
};

const WorkoutFullHeaderActions: FC = () => {
  const trainingProgramId = useTrainingProgramContext().id;

  if (trainingProgramId === 'template')
    return <StandaloneWorkoutTemplateActions />;

  return <WorkoutHeaderActionsRegular />;
};

export default WorkoutFullHeaderActions;
