import {
  useConversion,
  useEntityUserContext,
  useTrainingProgramContext,
  useWorkoutContext,
  useWorkoutDelete,
  useWorkoutDuplicate,
  useWorkoutReset,
  useWorkoutUpdate,
  WorkoutStatus,
} from '@strenco/api';
import { useNavigate } from 'react-router-dom';

import {
  MutationDelete,
  MutationUpdate,
} from '../../../constants/mutation-keys';
import { useParamWorkoutId } from '../../../router/hooks/path-params';
import { SearchParam } from '../../../router/types';
import { getRoutineDetailsUrl } from '../../../router/urls/clients';
import { ModalId, useModal } from '../../../utils/modal';
import { confirmDanger } from '../../../utils/modal/confirm';

export const useWorkoutActions = () => {
  const navigate = useNavigate();
  const paramWorkoutId = useParamWorkoutId();

  const userId = useEntityUserContext().id;
  const trainingProgramId = useTrainingProgramContext().id;
  const workoutId = useWorkoutContext().id;
  const { openModal } = useModal();

  const { isPending: isDuplicating, mutateAsync: duplicateMutation } =
    useWorkoutDuplicate(workoutId);
  const { isPending: isDeleting, mutateAsync: deleteMutation } =
    useWorkoutDelete(workoutId);
  const { isPending: isResetting, mutateAsync: resetWorkout } =
    useWorkoutReset(workoutId);
  const { isPending: isSkipping, mutateAsync: updateWorkout } =
    useWorkoutUpdate(workoutId);

  const { isPending: isConverting, mutateAsync: convert } = useConversion();

  const context = { trainingProgramId, userId };

  const skip = () =>
    confirmDanger({
      key: MutationUpdate.ClientWorkoutSkip,
      onConfirm: () =>
        updateWorkout({
          context,
          userWorkout: { status: WorkoutStatus.Skipped },
        }),
    });

  const unSkip = () =>
    updateWorkout({ context, userWorkout: { status: WorkoutStatus.None } });

  const reset = () =>
    confirmDanger({
      key: MutationUpdate.ClientWorkoutReset,
      onConfirm: () => resetWorkout(context),
    });

  const editDetails = () => {
    openModal(ModalId.WorkoutDetails, {
      [SearchParam.ClientId]: userId,
      [SearchParam.CycleId]: null,
      [SearchParam.EntityId]: workoutId,
      [SearchParam.TrainingProgramId]: trainingProgramId,
    });
  };

  const deleteOne = () =>
    confirmDanger({
      key: MutationDelete.ClientWorkout,
      onConfirm: () =>
        deleteMutation(context).then(() => {
          // We are on the workout page, navigate to trainingProgram page
          if (paramWorkoutId) {
            navigate(getRoutineDetailsUrl(trainingProgramId, workoutId), {
              replace: true,
            });
          }
        }),
    });

  const duplicate = () => duplicateMutation(context);

  return {
    convert,
    deleteOne: deleteOne,
    duplicate,
    editDetails,
    isConverting,
    isDeleting,
    isDuplicating,
    isResetting,
    isSkipping,
    reset,
    skip,
    unSkip,
  };
};
