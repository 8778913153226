import {
  EntityUserProvider,
  TrainingProgramProvider,
  WorkoutProvider,
} from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../../constants/page-ids';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { useParamWorkoutId } from '../../../../router/hooks/path-params';
import Layout from './layout';
import Prefetch from './prefetch';

const Content = lazy(() => import('./content'));

const TemplateWorkoutFullPage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.TemplateWorkoutFull));
  const workoutId = useParamWorkoutId();

  if (!workoutId) return null;

  return (
    <EntityUserProvider id="template">
      <TrainingProgramProvider id="template" type="template">
        <WorkoutProvider id={workoutId} type="template">
          <Prefetch />
          <Layout />
          <Suspense fallback={null}>
            <Content />
          </Suspense>
        </WorkoutProvider>
      </TrainingProgramProvider>
    </EntityUserProvider>
  );
};

export default TemplateWorkoutFullPage;
