/**
 * @file Contains types used for modals
 */
import { SearchParam } from '../../router/types';

export enum ModalId {
  BusinessAdminCreate = 'Modal_BusinessAdminCreate',
  BusinessAdminEdit = 'Modal_BusinessAdminEdit',
  BusinessClientCreate = 'Modal_BusinessClientCreate',
  BusinessClientEdit = 'Modal_BusinessClientEdit',
  BusinessCoachCreate = 'Modal_BusinessCoachCreate',
  BusinessCoachEdit = 'Modal_BusinessCoachEdit',
  BusinessCoachRankCreate = 'Modal_BusinessCoachRankCreate',
  BusinessCoachRankEdit = 'Modal_BusinessCoachRankEdit',
  BusinessFacilityCreate = 'Modal_BusinessFacilityCreate',
  BusinessFacilityEdit = 'Modal_BusinessFacilityEdit',
  ChangePassword = 'Modal_ChangePassword',
  ClientCreate = 'Modal_ClientCreate',
  ClientEdit = 'Modal_ClientEdit',
  Exercise = 'Modal_Exercise',
  GenerateClientRoutines = 'Modal_GenerateClientRoutines',
  StandaloneWorkoutTemplateDetails = 'Modal_StandaloneWorkoutTemplateDetails',
  SubscriptionChangePlan = 'Modal_SubscriptionChangePlan',
  TrainingProgramCycle = 'Modal_TrainingProgramCycle',
  TrainingProgramDetails = 'Modal_TrainingProgramDetails',
  UserProfileEdit = 'Modal_UserProfileEdit',
  WorkoutDetails = 'Modal_WorkoutDetails',
}

export interface ModalAccessibleOptions {
  /**
   * Indicates whether any data required for validating the modal's accessibility
   * is currently being fetched. This check is performed before more complex
   * validation operations, allowing for early handling of fetching state.
   */
  fetching?: boolean;
  /**
   * A collection of conditions that must be true in order to display the modal.
   * These conditions can include permissions checks, existence of entities, etc.
   * Using validators helps manage code complexity when many checks are required.
   */
  validators?: boolean[];
}

export type ModalOpenHandler = <T extends ModalId>(
  modalId: T,
  paramConfig?: ModalParams[T],
) => void;

export interface ModalParams {
  [ModalId.BusinessAdminCreate]: {
    [SearchParam.Facilities]: null | string;
  };
  [ModalId.BusinessAdminEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.BusinessClientCreate]: {
    [SearchParam.CoachId]: null | string;
    [SearchParam.Facilities]: null | string;
  };

  [ModalId.BusinessClientEdit]: {
    [SearchParam.ClientId]: null | string;
  };
  [ModalId.BusinessCoachCreate]: {
    [SearchParam.Facilities]: null | string;
  };
  [ModalId.BusinessCoachEdit]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.BusinessCoachRankCreate]: undefined;
  [ModalId.BusinessCoachRankEdit]: {
    [SearchParam.EntityId]: null | number;
  };
  [ModalId.BusinessFacilityCreate]: undefined;
  [ModalId.BusinessFacilityEdit]: {
    [SearchParam.EntityId]: null | number;
  };
  [ModalId.ChangePassword]: undefined;
  [ModalId.ClientCreate]: undefined;
  [ModalId.ClientEdit]: {
    [SearchParam.ClientId]: null | string;
  };
  [ModalId.Exercise]?: { [SearchParam.ExerciseId]: string };
  [ModalId.GenerateClientRoutines]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.StandaloneWorkoutTemplateDetails]: {
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.SubscriptionChangePlan]: undefined;
  [ModalId.TrainingProgramCycle]: {
    [SearchParam.CycleId]?: null | number;
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.TrainingProgramDetails]: {
    [SearchParam.ClientId]: null | string;
    [SearchParam.EntityId]: null | string;
  };
  [ModalId.UserProfileEdit]: undefined;
  [ModalId.WorkoutDetails]:
    | {
        [SearchParam.ClientId]: null | string;
        [SearchParam.CycleId]: null | number;
        [SearchParam.EntityId]: null | string;
        [SearchParam.TrainingProgramId]: string;
      }
    | {
        [SearchParam.ClientId]: null | string;
        [SearchParam.EntityId]: null | string;
        [SearchParam.TrainingProgramId]: string;
      };
}

export interface ParamConfig {
  /**
   * Optional params that can be added for modals
   */
  optional?: SearchParam[];
  /**
   * The params that are required to show the modal
   */
  required?: SearchParam[];
}
