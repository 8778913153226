import { EntityUserProvider, TrainingProgramProvider } from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../../constants/page-ids';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { useParamRoutineId } from '../../../../router/hooks/path-params';
import Layout from './layout';
import Prefetch from './prefetch';

const Content = lazy(() => import('./content'));

const TemplateRoutineFullPage: FC = () => {
  const trainingProgramId = useParamRoutineId();
  useDocumentTitle(getPageLabel(PageId.TemplateRoutineFull));

  if (!trainingProgramId) return null;

  return (
    <EntityUserProvider id="template">
      <TrainingProgramProvider id={trainingProgramId} type="template">
        <Prefetch />
        <Layout />
        <Suspense fallback={null}>
          <Content />
        </Suspense>
      </TrainingProgramProvider>
    </EntityUserProvider>
  );
};

export default TemplateRoutineFullPage;
