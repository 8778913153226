/** @file Contains helpers for ClientRoutinesDnd component */
import {
  TrainingProgramListEntity,
  TrainingProgramsListGroup,
  TrainingProgramTemplateStatus,
  UserTrainingProgramStatus,
  WorkoutStatus,
} from '@strenco/api';

import {
  getTrainingProgramStatusLabel,
  getTrainingProgramTemplateStatusLabel,
} from '../../../../i18n/helpers/training-program';
import { ROUTINE__STATUS_ORDERED } from '../constants';
import { isWorkoutCompleted } from './workout';

type TrainingProgramInfo = Partial<{
  lastWorkoutCompletedId: string;
  nextWorkoutId: string;
  trainingProgramStartedAt: string;
}> & {
  durationHours: number;
  workoutCounts: Record<'total' | WorkoutStatus, number>;
};

// eslint-disable-next-line complexity
export const getTrainingProgramInfo = (
  data: Pick<TrainingProgramsListGroup<'user'>, 'cycles' | 'workouts'> & {
    program: TrainingProgramListEntity<'user'>;
  },
): TrainingProgramInfo => {
  const { cycles, program, workouts } = data;

  const result: TrainingProgramInfo = {
    durationHours: 0,
    workoutCounts: {
      total: 0,
      [WorkoutStatus.Completed]: 0,
      [WorkoutStatus.CompletedWithMissingData]: 0,
      [WorkoutStatus.InProgress]: 0,
      [WorkoutStatus.None]: 0,
      [WorkoutStatus.Skipped]: 0,
    },
  };

  if (!program) {
    return result;
  }

  const workoutCompletedAt: [
    workoutId: string | undefined,
    completedAt: number,
  ] = [undefined, 0];

  for (const cycleId of program.cyclesOrder) {
    for (const workoutId of cycles[cycleId]?.workoutsOrder ?? []) {
      const workout = workouts[workoutId];
      if (!workout) continue;

      result.workoutCounts.total++;
      result.workoutCounts[workout.info.status]++;
      result.durationHours += workout.workout.restHours;

      const shouldAddNextWorkout =
        !result.nextWorkoutId &&
        [WorkoutStatus.InProgress, WorkoutStatus.None].includes(
          workout.info.status,
        );

      if (shouldAddNextWorkout) {
        result.nextWorkoutId = workout.workout.id;
      }

      if (!result.trainingProgramStartedAt && workout.info.completedAt) {
        result.trainingProgramStartedAt = workout.info.completedAt;
      }

      if (!isWorkoutCompleted(workout.info.status)) continue;
      const completedAt = new Date(workout.info.completedAt ?? 0).getTime();

      if (completedAt >= workoutCompletedAt[1]) {
        workoutCompletedAt[0] = workout.workout.id;
        workoutCompletedAt[1] = completedAt;
      }
    }
  }

  result.lastWorkoutCompletedId = workoutCompletedAt[0];

  return result;
};

export const getUserProgramStatusUpdateOptions = (
  trainingProgramStatus: UserTrainingProgramStatus,
  onClick?: (updatedStatus: UserTrainingProgramStatus) => void,
) => {
  return ROUTINE__STATUS_ORDERED.filter(s => s !== trainingProgramStatus).map(
    status => ({
      label: getTrainingProgramStatusLabel(status),
      onClick: () => onClick?.(status),
      value: status,
    }),
  );
};

export const getProgramTemplateStatusUpdateOptions = (
  trainingProgramStatus: TrainingProgramTemplateStatus,
  onClick?: (updatedStatus: TrainingProgramTemplateStatus) => void,
) => {
  return [
    TrainingProgramTemplateStatus.Draft,
    TrainingProgramTemplateStatus.Published,
  ]
    .filter(s => s !== trainingProgramStatus)
    .map(status => ({
      label: getTrainingProgramTemplateStatusLabel(status),
      onClick: () => onClick?.(status),
      value: status,
    }));
};
