import { useTrainingProgramFull } from '@strenco/api';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { PageId } from '../../constants/page-ids';
import { translate } from '../../i18n';
import { getPageLabel } from '../../i18n/helpers/page';
import {
  getBreadcrumbWorkoutLabel,
  getBreadcrumbWorkoutName,
} from '../../router/breadcrumbs/helpers';
import {
  useParamRoutineId,
  useParamWorkoutId,
} from '../../router/hooks/path-params';
import { routes } from '../../router/routes';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const routineId = useParamRoutineId();
  const workoutId = useParamWorkoutId();
  const { data } = useTrainingProgramFull(routineId);

  const items: BreadcrumbItem[] = [
    { content: getPageLabel(PageId.MyStuff), href: routes.me.root() },
  ];

  if (routineId !== null) {
    const program = data?.trainingProgram.program;

    items.push({
      content: program?.name,
      href: routes.me.routine(routineId),
      isLoading: !program,
      label: translate('routine.routines_one'),
    });

    if (workoutId !== null) {
      const workout = data?.workouts[workoutId].workout;

      items.push({
        content: getBreadcrumbWorkoutName(workout),
        href: routes.me.workouts(routineId, workoutId),
        isLoading: !workout,
        label: getBreadcrumbWorkoutLabel(data, workoutId),
      });
    }
  }

  return items;
};

export default useBreadcrumbs;
