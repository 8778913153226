import {
  useBusiness,
  useTraineeFull,
  useTrainingProgramFull,
} from '@strenco/api';
import { useLocation } from 'react-router-dom';

import { BreadcrumbItem } from '../../components/common/Breadcrumbs';
import { getBusinessQueryOptions } from '../../features/business/helpers/business';
import {
  getBreadcrumbBusiness,
  getBreadcrumbBusinessAdmin,
  getBreadcrumbBusinessCoach,
  getBreadcrumbBusinessFacility,
} from '../../router/breadcrumbs/business';
import { getBreadCrumbClientFull } from '../../router/breadcrumbs/clients';
import { getBreadcrumbRoutineFull } from '../../router/breadcrumbs/routines';
import { getBreadcrumbWorkoutFull } from '../../router/breadcrumbs/workouts';
import {
  useParamAdminId,
  useParamClientId,
  useParamCoachId,
  useParamFacilityId,
  useParamRoutineId,
  useParamWorkoutId,
} from '../../router/hooks/path-params';
import useCurrentPath from '../../router/hooks/useCurrentPath';
import { getDataById } from '../../utils/array';

// eslint-disable-next-line complexity
const useBreadcrumbs = (): BreadcrumbItem[] => {
  const currentPath = useCurrentPath();
  const location = useLocation();

  const paramAdminId = useParamAdminId();
  const paramClientId = useParamClientId();
  const paramCoachId = useParamCoachId();
  const paramFacilityId = useParamFacilityId();
  const paramRoutineId = useParamRoutineId();
  const paramWorkoutId = useParamWorkoutId();

  const { data: business } = useBusiness(getBusinessQueryOptions());
  const { data: traineeFull } = useTraineeFull(paramClientId);
  const { data: routineFull } = useTrainingProgramFull(paramRoutineId);

  const rootBreadcrumb = getBreadcrumbBusiness(currentPath);

  const items: BreadcrumbItem[] =
    rootBreadcrumb === null ? [] : [rootBreadcrumb];

  if (paramFacilityId !== null) {
    const facility = getDataById(business?.facilities, paramFacilityId);
    items.push(getBreadcrumbBusinessFacility(facility));
  }

  if (paramAdminId !== null) {
    const admin = business?.admins?.find(admin => admin.id === paramAdminId);

    items.push(getBreadcrumbBusinessAdmin(admin, currentPath, location));
  }

  if (paramCoachId !== null) {
    const coach = business?.coaches?.find(coach => coach.id === paramCoachId);

    items.push(getBreadcrumbBusinessCoach(coach, currentPath, location));
  }

  if (paramClientId !== null) {
    items.push(getBreadCrumbClientFull(traineeFull, currentPath, location));
  }

  if (paramRoutineId !== null && paramClientId !== null) {
    items.push(
      getBreadcrumbRoutineFull(
        routineFull?.trainingProgram?.program,
        currentPath,
        location,
      ),
    );

    if (paramWorkoutId !== null) {
      const workout = routineFull?.workouts[paramWorkoutId].workout;
      items.push(getBreadcrumbWorkoutFull(workout, currentPath, location));
    }
  }

  return items;
};

export default useBreadcrumbs;
