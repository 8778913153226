import { FC } from 'react';

import HeaderMobile from '../../../components/layout/HeaderMobile';
import { PageId } from '../../../constants/page-ids';
import WorkoutFullHeaderActions from '../../../features/routine/@client/components/WorkoutFullHeaderActions';
import RoutineFullSecondaryNavigation from '../../../features/routine/components/routine/RoutineFullSecondaryNavigation';
import useIsMobile from '../../../hooks/useIsMobile';
import { getPageLabel } from '../../../i18n/helpers/page';

const WorkoutBuilderLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={WorkoutFullHeaderActions}
        // backUrl={getBackUrl({
        //   toPathFragment: PATH_FRAGMENT__ROUTINE_ID,
        // })}
        title={getPageLabel(PageId.WorkoutBuilder)}
      />
    );
  }

  return <RoutineFullSecondaryNavigation pageId={PageId.WorkoutBuilder} />;
};

export default WorkoutBuilderLayout;
