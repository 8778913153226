import { CurrencyCode } from '@paddle/paddle-js';

export enum PaddleBillingInterval {
  Month = 'month',
  Year = 'year',
}

export enum PaddleProductType {
  Business = 'business',
  Coach = 'coach',
  Personal = 'personal',
}

export enum PaddleProductVariant {
  Pro = 'pro',
  Standard = 'standard',
  Starter = 'starter',
}

export enum PaddleSubscriptionStatus {
  /**
   * Subscription is active.
   * Paddle is billing for this subscription and
   * related transactions are not past due.
   */
  Active = 'active',
  /**
   * Subscription is canceled.
   * Automatically set by Paddle when a subscription is canceled.
   * When a subscription is set to cancel on the next billing period,
   * a scheduled change for the cancellation is created.
   *
   * The subscription status moves to canceled when the scheduled change takes effect.
   */
  Canceled = 'canceled',
  /**
   * Subscription has an overdue payment.
   * Automatically set by Paddle when payment fails for an automatically-collected transaction,
   * or when payment terms have elapsed for a manually-collected transaction (an invoice).
   */
  PastDue = 'past_due',
  /**
   * Subscription is paused.
   * Automatically set by Paddle when a subscription is paused.
   * When a subscription is set to pause on the next billing period,
   * a scheduled change for the pause is created.
   *
   * The subscription status moves to paused when the scheduled change takes effect.
   */
  Paused = 'paused',
  /**
   * Subscription is in trial.
   */
  Trialing = 'trialing',
}

export interface PaddlePrice {
  amount: number; // The numeric value of the unit price
  billingCycleInterval: PaddleBillingInterval; // The interval for billing (e.g., "month", "year")
  currencyCode: CurrencyCode; // Currency code (e.g., "USD", "EUR")
  formattedTotals: {
    discount: string;
    subtotal: string;
    tax: string;
    total: string;
  };
  id: string; // The price ID
  productId: string; // The associated product ID
  taxRate: string;
  totals: {
    discount: string;
    subtotal: string;
    tax: string;
    total: string;
  };
  trialDurationDays: number; // Duration of the trial period in days
}

export interface PaddleProduct {
  description: string; // Product description
  id: string; // Product ID
  items: string[]; // List of textual items that will be listed as features
  maxUsers: number;
  name: string; // Product name
  tier: number;
  type: PaddleProductType; // Product type (e.g., "business", "coach", "personal")
  variant: PaddleProductVariant; // Product variant (e.g., "pro", "standard", "starter")
}

export interface PaddleProductCustomData {
  maxUsers: number;
  tier: number;
  type: PaddleProductType;
  variant: PaddleProductVariant;
}

export type PaddleProductWithPrices = PaddleProduct & { prices: PaddlePrice[] };

/**
 * SUBSCRIPTIONS
 */

// export interface PaddleSubscriptionItem {
//   created_at: string;
//   next_billed_at: string;
//   previously_billed_at: string;
//   price: PaddlePrice;
//   product: PaddleProduct;
//   quantity: number;
//   recurring: boolean;
//   status: PaddleSubscriptionStatus;
//   trial_dates: any | null;
//   updated_at: string;
// }

export interface PaddleSubscription {
  billingInterval: PaddleBillingInterval;
  currencyCode: CurrencyCode;
  firstBilledAt: string;
  id: string;
  items: PaddleSubscriptionItemPretty[];
  managementUrls: {
    cancel: string;
    updatePaymentMethod: string;
  };
  nextBilledAt: string;
  pausedAt: null | string;
  scheduledChange: null | PaddleSubscriptionScheduledChange;
  startedAt: string;
  status: PaddleSubscriptionStatus;
}

export interface PaddleSubscriptionItemPretty extends PaddleProduct {
  priceId: string;
}

export interface PaddleSubscriptionScheduledChange {
  action: 'cancel' | 'pause';
  effective_at: string;
  resume_at: null;
}

export type ProductsGroup = {
  items: PaddleProductWithPrices[];
  type: PaddleProductType;
}[];

export interface ScheduledSubscriptionChangeAttributes {
  paddleSubscriptionId: string;
  priceId: string;
  scheduledAt: string;
  status: 'cancelled' | 'completed' | 'pending';
}
