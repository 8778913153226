/**
 * @file Contains translations for exercise instructions
 */

const translationsExerciseInstructions = {
  actions: 'Actions for {{group}}',
  comments_set: 'Set comments',
  move_to_group: 'Move to another group',
  newSet: 'New Set',
  reorder: 'Reorder {{value}}',
  selectAtLeastOne: 'Select at least one exercise instruction (A1, A2..)',
  selectExercise: 'Select Exercise',
  series: 'Series {{value}}',
  series_delete: 'Delete series {{value}}',
  series_duplicate: 'Duplicate series {{value}}',
  series_reorder: 'Reorder series {{value}}',
  set_note: 'Set Note',
  set_note_one: 'Client left a note',
  set_reorder: 'Reorder set (move up or down)',
  setBuilder: {
    label: 'Set Builder',
  },
  sets: 'Sets',
  sets_missingData_one: 'Missing data',
  sets_one: 'Set',
  sets_other: '{{count}} sets',
  sets_skipped: 'Sets skipped',
  sets_skipped_one: 'One Set Skipped',
  sets_skipped_other: '{{count}} sets skipped',
  sets_zero: 'No sets available',
  weight_prepared: 'Prepared weight',
};

export default translationsExerciseInstructions;
