import { FC, PropsWithChildren, useRef } from 'react';

import ScrollContainerContext from './context';

const ScrollContainerProvider: FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ScrollContainerContext.Provider value={ref}>
      {children}
    </ScrollContainerContext.Provider>
  );
};

export default ScrollContainerProvider;
