import { useTrainingProgramFull } from '@strenco/api';

import { BreadcrumbItem } from '../../../components/common/Breadcrumbs';
import { PageId } from '../../../constants/page-ids';
import { translate } from '../../../i18n';
import { getPageLabel } from '../../../i18n/helpers/page';
import { getBreadcrumbWorkoutName } from '../../../router/breadcrumbs/helpers';
import { useParamWorkoutId } from '../../../router/hooks/path-params';
import { routes } from '../../../router/routes';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const workoutId = useParamWorkoutId();

  const { data } = useTrainingProgramFull('template');

  const items: BreadcrumbItem[] = [
    {
      content: getPageLabel(PageId.TemplateWorkouts),
      href: routes.library.workouts.root(),
    },
  ];

  if (workoutId !== null) {
    const workout = data?.workouts[workoutId]?.workout;
    items.push({
      content: getBreadcrumbWorkoutName(workout),
      href: routes.library.workouts.byId(workoutId),
      isLoading: !workout,
      label: translate('workout.template_one'),
    });
  }

  return items;
};

export default useBreadcrumbs;
