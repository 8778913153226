import { useBusiness } from '@strenco/api';
import { IconBuilding, IconUsers } from '@tabler/icons-react';
import { FC } from 'react';

import { DrawerSidebarScrollArea } from '../../../../components/common/DrawerComponents';
import DrawerNavGroup from '../../../../components/common/DrawerNavGroup';
import DrawerSidebar from '../../../../components/common/DrawerSidebar';
import { PageId } from '../../../../constants/page-ids';
import { translate } from '../../../../i18n';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { ModalId, useModal } from '../../../../utils/modal';
import { getBusinessQueryOptions } from '../../helpers/business';
import {
  getFacilitiesLinks,
  getLinksPeople,
  getUserCreateMenuItems,
} from './helpers';

const BusinessSecondaryNavigation: FC = () => {
  const { openModal } = useModal();
  const { data: business, isLoading } = useBusiness(getBusinessQueryOptions());

  const linksFacilities = getFacilitiesLinks(business);
  const linksPeople = getLinksPeople();
  const itemsUserCreate = getUserCreateMenuItems(openModal);

  return (
    <DrawerSidebar title={getPageLabel(PageId.Business)}>
      <DrawerSidebarScrollArea>
        <DrawerNavGroup
          actionIcon={{
            items: itemsUserCreate,
            tooltip: translate('business.people_create'),
          }}
          Icon={IconUsers}
          items={linksPeople}
          label={translate('business.people')}
        />
        <DrawerNavGroup
          actionIcon={{
            onClick: () => openModal(ModalId.BusinessFacilityCreate),
            tooltip: translate('business.facilities_create'),
          }}
          Icon={IconBuilding}
          isLoading={isLoading}
          items={linksFacilities}
          label={translate('business.facilities')}
        />
      </DrawerSidebarScrollArea>
    </DrawerSidebar>
  );
};

export default BusinessSecondaryNavigation;
