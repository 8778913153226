import { UserType } from '@strenco/api';
import { ComponentProps } from 'react';
import { Location } from 'react-router-dom';

import DrawerNavItem from '../../components/common/DrawerNavItem';
import { MenuItem } from '../../components/ui/menus/CustomMenu';
import { PageId } from '../../constants/page-ids';
import { getCategoriesToShow } from '../../features/routine/@exercise/helpers';
import { getUser } from '../../features/user/api/cache/getters';
import { translate } from '../../i18n';
import { getPageLabel } from '../../i18n/helpers/page';
import { PATH__LIBRARY__EXERCISES } from '../../router/paths/paths';
import { routes } from '../../router/routes';
import { SearchParam } from '../../router/types';
import { getSearchParamExerciseCategory } from '../../router/urls/searchParams/getters';
import { ModalId, ModalOpenHandler } from '../../utils/modal';

export const getLinksTemplates = (): ComponentProps<typeof DrawerNavItem>[] => [
  {
    label: getPageLabel(PageId.TemplateRoutines),
    to: routes.library.routines.root(),
  },
  {
    label: getPageLabel(PageId.TemplateWorkouts),
    to: routes.library.workouts.root(),
  },
];

export const getLinksExercises = (
  location: Location,
): ComponentProps<typeof DrawerNavItem>[] => {
  const categories = getCategoriesToShow();

  return categories.map(category => ({
    isActive:
      location.pathname.includes(PATH__LIBRARY__EXERCISES) &&
      (getSearchParamExerciseCategory(location) ?? 'my') === category,
    label: translate(`exercise.exercisesView.${category}.long`),
    to: routes.library.exercises.root(category),
  }));
};

export const getTemplateItems = (openModal: ModalOpenHandler) => {
  const items: MenuItem[] = [];

  if (getUser()?.type !== UserType.Personal) {
    items.push({
      label: translate('routine.template.create'),
      onClick: () =>
        openModal(ModalId.TrainingProgramDetails, {
          [SearchParam.ClientId]: 'template',
          [SearchParam.EntityId]: null,
        }),
    });
  }

  items.push({
    label: translate('workout.template.create'),
    onClick: () => openModal(ModalId.StandaloneWorkoutTemplateDetails),
  });

  return items;
};
