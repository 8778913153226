import { IconBuilding, IconCreditCard, IconUser } from '@tabler/icons-react';
import { ComponentProps, FC } from 'react';
import { useLocation } from 'react-router-dom';

import DrawerNavItem from '../../../../components/common/DrawerNavItem';
import DrawerNavItemsList from '../../../../components/common/DrawerNavItemsList';
import DrawerSidebar from '../../../../components/common/DrawerSidebar';
import { PageId } from '../../../../constants/page-ids';
import { DRAWER_NAV_ITEM_RICH_ICON__SIZE } from '../../../../constants/preferences';
import { getPageLabel } from '../../../../i18n/helpers/page';
import {
  PATH__SETTINGS__BUSINESS,
  PATH__SETTINGS__PROFILE,
  PATH__SETTINGS__SUBSCRIPTIONS,
} from '../../../../router/paths/paths';
import { isBusinessOwner, isSubscriptionUser } from '../../utils';

const iconSize = DRAWER_NAV_ITEM_RICH_ICON__SIZE;

const SettingsSecondaryNavigation: FC = () => {
  const location = useLocation();

  const links: ComponentProps<typeof DrawerNavItem>[] = [
    {
      Icon: IconUser,
      iconSize,
      isActive: location.pathname === PATH__SETTINGS__PROFILE,
      label: getPageLabel(PageId.SettingsProfile),
      to: PATH__SETTINGS__PROFILE,
    },
  ];

  if (isSubscriptionUser()) {
    links.push({
      Icon: IconCreditCard,
      iconSize,
      isActive: location.pathname === PATH__SETTINGS__SUBSCRIPTIONS,
      label: getPageLabel(PageId.SettingsSubscriptions),
      to: PATH__SETTINGS__SUBSCRIPTIONS,
    });
  }

  if (isBusinessOwner()) {
    links.push({
      Icon: IconBuilding,
      iconSize,
      isActive: location.pathname === PATH__SETTINGS__BUSINESS,
      label: getPageLabel(PageId.SettingsBusiness),
      to: PATH__SETTINGS__BUSINESS,
    });
  }

  return (
    <DrawerSidebar title={getPageLabel(PageId.Settings)}>
      <DrawerNavItemsList items={links} />
    </DrawerSidebar>
  );
};

export default SettingsSecondaryNavigation;
