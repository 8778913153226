import { useExerciseFull } from '@strenco/api';
import { FC } from 'react';

import HeaderMobile from '../../../../components/layout/HeaderMobile';
import { PageId } from '../../../../constants/page-ids';
import ExerciseHeaderActions from '../../../../features/routine/@exercise/components/ExerciseHeaderActions';
import useBackNavigation from '../../../../hooks/useGetBackUrl';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { useParamExerciseId } from '../../../../router/hooks/path-params';

const ExerciseFullPageLayout: FC = () => {
  const exerciseId = useParamExerciseId();
  const { data: exercise } = useExerciseFull(exerciseId);
  const isMobile = useIsMobile();
  const { getBackUrl } = useBackNavigation();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={
          exercise ? () => <ExerciseHeaderActions exercise={exercise} /> : null
        }
        backUrl={getBackUrl({ keepSearch: true })}
        title={getPageLabel(PageId.ExerciseFull)}
      />
    );
  }

  return null;
};

export default ExerciseFullPageLayout;
