import { UserStatus, UserType } from '@strenco/api';
import {
  AuthSession,
  confirmSignUp as cognitoConfirmSignUp,
  signIn as cognitoSignIn,
  signUp as cognitoSignUp,
  fetchAuthSession,
  resendSignUpCode,
  signOut,
} from 'aws-amplify/auth';

import { queryClient } from '../../../../config/react-query';
import { SignUpFormData } from '../../../../pages/auth/SignUp/useSignUpForm';
import { PaddleSupportedCountries } from '../../../../types/locale';
import {
  PaddleProductType,
  PaddleProductVariant,
  PaddleSubscriptionStatus,
} from '../../../../types/product.types';
import {
  clearMixpanelUser,
  setMixpanelUser,
} from '../../../../utils/analytics';
import { clearSentryUser, setSentryUser } from '../../../../utils/sentry';
import { StorageKeys, StorageService } from '../../../../utils/storage';
import { AccountType, User } from '../../types';
import { IConfirmSignUp, IResendVerificationCode, ISignIn } from './types';

const logoutUser = async () => {
  if (window.navigator.onLine === false) {
    return;
  }
  await signOut();
  StorageService.remove(StorageKeys.User);
  queryClient.cancelQueries();
  queryClient.removeQueries();
  queryClient.clear();
  clearSentryUser();
  clearMixpanelUser();
};

export const setUser = async (user: null | User, reload: boolean = false) => {
  if (user === null) {
    await logoutUser();
  } else {
    StorageService.save(StorageKeys.User, user);
    setSentryUser(user);
    setMixpanelUser(user);
  }

  if (reload === true) {
    window.location.href = '/';
  }
};

// eslint-disable-next-line complexity
const buildUser = (session: AuthSession): null | User => {
  if (!session.tokens?.idToken?.payload) {
    return null;
  }

  const idToken = session.tokens.idToken.payload;

  const customerId = idToken.customerId?.toString() ?? null;
  const businessId = idToken.businessId?.toString() ?? null;
  const status = (idToken.status as UserStatus) ?? UserStatus.Inactive;

  const subscriptionMaxUsers = idToken.subscriptionMaxUsers
    ? Number(idToken.subscriptionMaxUsers)
    : -1;

  const subscriptionPriceId = (idToken.subscriptionPriceId ?? null) as
    | null
    | string;

  const subscriptionStatus = idToken.subscriptionStatus
    ? (idToken.subscriptionStatus as PaddleSubscriptionStatus)
    : null;

  const subscriptionVariant = idToken.subscriptionVariant
    ? (idToken.subscriptionVariant as PaddleProductVariant)
    : null;

  const subscriptionType = idToken.subscriptionType
    ? (idToken.subscriptionType as PaddleProductType)
    : null;

  const subscriptionTier = idToken.subscriptionTier
    ? +idToken.subscriptionTier
    : -1;

  const userType = idToken?.userType as UserType;

  const user: User = {
    avatarUrl: null,
    businessId,
    customerId: (customerId ?? null) as null | string,
    email: idToken?.email?.toString() ?? '',
    firstName: idToken?.given_name?.toString() ?? '',
    id: idToken?.userId?.toString() ?? '',
    lastName: idToken?.family_name?.toString() ?? '',
    status,
    subscriptionMaxUsers,
    subscriptionPriceId,
    subscriptionStatus,
    subscriptionTier,
    subscriptionType,
    subscriptionVariant,
    type: userType || UserType.New,
  };

  return user;
};

/**
 * Confirm a user's sign-up.
 * @param confirmData                The confirmation data.
 * @param confirmData.code           The confirmation code.
 * @param confirmData.email          The user's email.
 * @param confirmData.clientMetadata Optional client metadata (e.g., coachId).
 * @returns                          A promise that resolves when the confirmation is successful or rejects with an error.
 */
export function confirmSignUp({ clientMetadata, code, email }: IConfirmSignUp) {
  return cognitoConfirmSignUp({
    confirmationCode: code,
    options: { clientMetadata },
    username: email,
  });
}

// /**
//  * Get the Cognito user session for the current user.
//  * @returns A promise that resolves to the Cognito user session or rejects with an error.
//  */
// export function getSession() {
//   return fetchAuthSession();
// }

/**
 * Get the current user from the Cognito session.
 * @returns A promise that resolves to the session user or rejects with an error.
 */
// eslint-disable-next-line complexity
export async function getCurrentCognitoUser() {
  let didRetry = false;

  try {
    const session = await fetchAuthSession();
    const user = buildUser(session);
    return user;
  } catch (error) {
    // Attempt to refresh the session
    if (didRetry) {
      throw error;
    }

    try {
      didRetry = true;
      await refreshSession();
      // Retry getting the current user
      return await getCurrentCognitoUser();
    } catch (refreshError) {
      // Handle session refresh failure
      console.error('Failed to refresh session:', refreshError);
      // Redirect to login or display an error message
      throw refreshError;
    }
  }
}

export async function refreshSession() {
  const authSession = await fetchAuthSession({ forceRefresh: true });

  const user = buildUser(authSession);

  return user;
}

/**
 * Resend a verification code to a user.
 * @param resendData         - The resend data.
 * @param resendData.coachId - The ID of the coach (optional).
 * @param resendData.email   - The user's email.
 * @returns                  A promise that resolves to `true` when the code is successfully resent or rejects with an error.
 */
export function resendVerificationCode({
  coachId,
  email,
}: IResendVerificationCode) {
  const clientMetadata = coachId ? { coachId, email } : undefined;
  return resendSignUpCode({ options: { clientMetadata }, username: email });
}

/**
 * Sign in a user.
 * @param signInData                - The sign-in data.
 * @param signInData.email          - The user's email.
 * @param signInData.password       - The user's password.
 * @param signInData.clientMetadata - Optional client metadata.
 * @returns                         A promise that resolves to the user session or rejects with an error.
 */
export async function signIn({ clientMetadata, email, password }: ISignIn) {
  await cognitoSignIn({
    options: { clientMetadata },
    password,
    username: email,
  });
}

/**
 * Sign up a user.
 * @param data Sign up form data
 * @returns    A promise that resolves to the sign-up result or rejects with an error.
 */
export function signUp(data: SignUpFormData) {
  const {
    accountType,
    business,
    countryCode,
    email,
    firstName,
    lastName,
    password,
    postalCode,
  } = data;

  const metadataCommon: Partial<{
    accountType: AccountType;
    businessName: string;
    businessTaxIdentifier: string;
    countryCode: PaddleSupportedCountries;
    postalCode: string;
  }> = {
    accountType,
  };

  if (business?.name) {
    metadataCommon.businessName = business.name;
  }

  if (business?.taxIdentifier) {
    metadataCommon.businessTaxIdentifier = business.taxIdentifier;
  }

  if (countryCode) {
    metadataCommon.countryCode = countryCode;
  }

  if (postalCode) {
    metadataCommon.postalCode = postalCode;
  }

  return cognitoSignUp({
    options: {
      clientMetadata: metadataCommon,
      userAttributes: {
        family_name: lastName,
        given_name: firstName,
      },
    },
    password: password,
    username: email,
  });
}
