import { useBusiness } from '@strenco/api';
import { FC } from 'react';

import HeaderMobile from '../../../../components/layout/HeaderMobile';
import { PageId } from '../../../../constants/page-ids';
import BusinessSecondaryNavigation from '../../../../features/business/components/BusinessLayout/BusinessSecondaryNavigation';
import BusinessCoachHeaderActions from '../../../../features/business/components/CoachHeaderActions';
import { getBusinessQueryOptions } from '../../../../features/business/helpers/business';
import useIsMobile from '../../../../hooks/useIsMobile';
import { getPageLabel } from '../../../../i18n/helpers/page';
import { useParamCoachId } from '../../../../router/hooks/path-params';

const HeaderActionsMobile: FC = () => {
  const paramCoachId = useParamCoachId();
  const { data: business } = useBusiness(getBusinessQueryOptions());

  const coach = business?.coaches?.find(
    coachItem => coachItem.id === paramCoachId,
  );

  if (coach === undefined) {
    return null;
  }

  return <BusinessCoachHeaderActions coach={coach} />;
};

const BusinessCoachLayout: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <HeaderMobile
        Action={HeaderActionsMobile}
        title={getPageLabel(PageId.BusinessCoachDetails)}
      />
    );
  }

  return <BusinessSecondaryNavigation />;
};

export default BusinessCoachLayout;
