import { useMeasurements, useTrainingPrograms } from '@strenco/api';
import { FC } from 'react';

// 10 min
const staleTime = 60 * 10 * 1000;

export const PrefetchTraineeFull: FC<{ traineeId?: string }> = ({
  traineeId,
}) => {
  useMeasurements('bodyWeight', traineeId);
  useMeasurements('checkIn', traineeId);
  useTrainingPrograms(traineeId!, {
    refetchOnMount: true,
    staleTime,
  });
  return null;
};
