import { Button, Stack, Text, Title } from '@mantine/core';
import { FC } from 'react';

import { ButtonId } from '../../../../../constants/button-ids';
import { translate } from '../../../../../i18n';
import { getButtonLabel } from '../../../../../i18n/helpers/buttons';
import { ModalId, useModal } from '../../../../../utils/modal';

const NothingFoundMessage: FC = () => {
  const { openModal } = useModal();
  return (
    <Stack align="center" justify="center" p="md" pt="xl">
      <Title c="dark.6" size="sm">
        {translate('exercise.search.noResult.title')}
      </Title>
      <Text c="dimmed" size="sm">
        {translate('exercise.search.noResult.subtitle')}
      </Text>
      <Button
        mt="md"
        onClick={() => {
          openModal(ModalId.Exercise);
        }}
      >
        {getButtonLabel(ButtonId.ExerciseCreate)}
      </Button>
    </Stack>
  );
};

export default NothingFoundMessage;
