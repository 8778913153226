import {
  IconBarbell,
  IconChartDots3,
  IconCheck,
  IconCopyPlus,
  IconDots,
  IconDotsVertical,
  IconEdit,
  IconFileExport,
  IconFileImport,
  IconGitCompare,
  IconInfoSquare,
  IconList,
  IconPlus,
  IconReplace,
  IconSettings,
  IconSettingsPlus,
  IconSquarePlus,
  IconStatusChange,
  IconTrash,
  IconUserCancel,
  IconUserCheck,
  IconUserShare,
  IconX,
} from '@tabler/icons-react';
import { IconMessageCirclePlus } from '@tabler/icons-react';

import { ButtonId } from '../../../constants/button-ids';
import { translate } from '../../../i18n';
import { getButtonTitle } from '../../../i18n/helpers/buttons';

export const iconButtonProps = {
  size: 'lg',
  variant: 'subtle',
};

export const iconStyle = {
  height: '75%',
  minHeight: '75%',
  minWidth: '75%',
  width: '75%',
};

export const iconProps = { stroke: 1.75, style: iconStyle };

export const iconsMap = {
  add: {
    Icon: IconSquarePlus,
    tooltip: translate('common.add'),
  },
  addComment: {
    Icon: IconMessageCirclePlus,
    tooltip: getButtonTitle(ButtonId.CommentsAdd),
  },
  chart: {
    Icon: IconChartDots3,
    tooltip: translate('aria.show_chart_view'),
  },
  check: {
    Icon: IconCheck,
    tooltip: 'Ok',
  },
  clientShare: {
    Icon: IconUserShare,
    tooltip: '',
  },
  compare: {
    Icon: IconGitCompare,
    tooltip: translate('common.compare'),
  },
  delete: {
    Icon: IconTrash,
    tooltip: translate('common.delete'),
  },
  dotsHorizontal: {
    Icon: IconDots,
    tooltip: getButtonTitle(ButtonId.Actions),
  },
  dotsVertical: {
    Icon: IconDotsVertical,
    tooltip: getButtonTitle(ButtonId.Actions),
  },
  dumbbell: {
    Icon: IconBarbell,
    tooltip: getButtonTitle(ButtonId.WorkoutBuilder),
  },
  duplicate: {
    Icon: IconCopyPlus,
    tooltip: translate('common.duplicate'),
  },
  edit: {
    Icon: IconEdit,
    tooltip: translate('common.edit'),
  },
  export: {
    Icon: IconFileExport,
    tooltip: translate('common.export'),
  },
  import: {
    Icon: IconFileImport,
    tooltip: translate('common.import'),
  },
  info: {
    Icon: IconInfoSquare,
    tooltip: translate('common.info'),
  },
  list: {
    Icon: IconList,
    tooltip: translate('aria.show_list_view'),
  },
  move: {
    Icon: IconStatusChange,
    tooltip: translate('common.move'),
  },
  plus: {
    Icon: IconPlus,
    tooltip: translate('common.prepare'),
  },
  replace: {
    Icon: IconReplace,
    tooltip: translate('common.replace'),
  },
  settings: {
    Icon: IconSettings,
    tooltip: translate('common.settings'),
  },
  settingsPlus: {
    Icon: IconSettingsPlus,
    tooltip: translate('common.add'),
  },
  userActivate: {
    Icon: IconUserCheck,
    tooltip: translate('common.users_activate'),
  },
  userDeactivate: {
    Icon: IconUserCancel,
    tooltip: translate('common.users_deactivate'),
  },
  x: {
    Icon: IconX,
    tooltip: translate('common.close'),
  },
};
