import { UserType } from '@strenco/api';
import { FC } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import AuthenticatedLayout from '../../../components/layout/AuthenticatedLayout';
import ProtectedRoute from '../../../components/utils/ProtectedRoute';
import { getUser } from '../../../features/user/api/cache/getters';
import BusinessLayout from '../../../pages/business';
import BusinessAdminsRootPage from '../../../pages/business/admins/root';
import BusinessClientsRootPage from '../../../pages/business/clients/root';
import BusinessCoachFullPage from '../../../pages/business/coaches/coach';
import BusinessCoachesRootPage from '../../../pages/business/coaches/root';
import BusinessFacilityFullPage from '../../../pages/business/facilities/full';
import BusinessFacilitiesRootPage from '../../../pages/business/facilities/root';
import BusinessRootPage from '../../../pages/business/root';
import ClientsLayout from '../../../pages/clients';
import ClientFullPage from '../../../pages/clients/full';
import ClientsRootPage from '../../../pages/clients/root';
import ClientRoutineFullPage from '../../../pages/clients/routine';
import ClientWorkoutFullPage from '../../../pages/clients/workout';
import ErrorPage from '../../../pages/error';
import HomePage from '../../../pages/home';
import LibraryLayout from '../../../pages/library';
import ExercisesLayout from '../../../pages/library/exercises';
import LibraryExerciseFullPage from '../../../pages/library/exercises/full';
import LibraryExercisesRootPage from '../../../pages/library/exercises/root';
import LibraryRootPage from '../../../pages/library/root';
import TemplateRoutinesLayout from '../../../pages/library/routines';
import TemplateRoutineFullPage from '../../../pages/library/routines/full';
import TemplateRoutinesRootPage from '../../../pages/library/routines/root';
import TemplateRoutineWorkoutPage from '../../../pages/library/routines/workout';
import TemplateWorkoutsLayout from '../../../pages/library/workouts';
import TemplateWorkoutFullPage from '../../../pages/library/workouts/full';
import TemplateWorkoutsRootPage from '../../../pages/library/workouts/root';
import MeLayout from '../../../pages/me';
import MorePage from '../../../pages/more';
import ProxyPage from '../../../pages/proxy/Proxy';
import SettingsLayout from '../../../pages/settings';
import SettingsBusinessPage from '../../../pages/settings/business';
import SettingsProfilePage from '../../../pages/settings/profile';
import SettingsRootPage from '../../../pages/settings/root';
import SettingsSubscriptionPage from '../../../pages/settings/subscriptions';
import * as P from '../../../router/paths/paths';
import { routes } from '../../../router/routes';
import { ErrorParam } from '../../../types';
import protectedRoutes from './protected';

const NotFoundHandler: FC = () => {
  const { pathname } = useLocation();

  if (pathname.startsWith(routes.auth.signIn())) {
    const user = getUser();
    const path =
      user?.type === UserType.New ? P.PATH__ONBOARDING : P.PATH__HOME;

    return <Navigate to={path} />;
  }

  console.error('Route Not Found: ', window.location.href);
  return <Navigate to={routes.error.byErrorType(ErrorParam.PageNotFound)} />;
};

const AppUserSubscribed: FC = () => {
  return (
    <Routes>
      {/* === PROXY === */}
      <Route path={P.PATH__PROXY}>
        <Route element={<ProxyPage />} index={true} path={P.PATH__PROXY} />
        <Route element={<ProxyPage />} path={P.PATH__PROXY_TYPE} />
      </Route>

      <Route element={<AuthenticatedLayout />} path={P.PATH__ROOT}>
        <Route
          element={<Navigate replace={true} to={P.PATH__HOME} />}
          index={true}
          path={P.PATH__ROOT}
        />
        {/* /home */}
        <Route element={<HomePage />} path={P.PATH__HOME} />

        {/* === BUSINESS === */}

        <Route
          element={
            <ProtectedRoute allowedUserTypes={protectedRoutes.business}>
              <BusinessLayout />
            </ProtectedRoute>
          }
          path={P.PATH__BUSINESS}
        >
          <Route
            element={<BusinessRootPage />}
            index={true}
            path={P.PATH__BUSINESS}
          />
          <Route
            element={<BusinessAdminsRootPage />}
            path={P.PATH__BUSINESS__ADMINS}
          />

          {/* === BUSINESS CLIENTS === */}
          <Route
            element={
              <ProtectedRoute
                allowedUserTypes={protectedRoutes.businessClients}
              >
                <Outlet />
              </ProtectedRoute>
            }
            path={P.PATH__BUSINESS__CLIENTS}
          >
            <Route
              element={<BusinessClientsRootPage />}
              index={true}
              path={P.PATH__BUSINESS__CLIENTS}
            />
            <Route
              element={<ClientFullPage />}
              path={P.PATH__BUSINESS__CLIENTS__BY_ID}
            />
            <Route
              element={<ClientRoutineFullPage />}
              path={P.PATH__BUSINESS__CLIENTS__BY_ID__ROUTINE__BY_ID}
            />
            <Route
              element={<ClientWorkoutFullPage />}
              path={P.PATH__BUSINESS__CLIENTS__WORKOUT}
            />
          </Route>

          {/* === BUSINESS COACHES === */}
          <Route
            element={
              <ProtectedRoute
                allowedUserTypes={protectedRoutes.businessCoaches}
              >
                <Outlet />
              </ProtectedRoute>
            }
            path={P.PATH__BUSINESS__COACHES}
          >
            <Route
              element={<BusinessCoachesRootPage />}
              index={true}
              path={P.PATH__BUSINESS__COACHES}
            />
            <Route
              element={<BusinessCoachFullPage />}
              path={P.PATH__BUSINESS__COACHES__BY_ID}
            />

            <Route
              element={<ClientFullPage />}
              path={P.PATH__BUSINESS__COACH_CLIENT__BY_ID}
            />
            <Route
              element={<ClientRoutineFullPage />}
              path={P.PATH__BUSINESS__COACH_CLIENT_ROUTINE__BY_ID}
            />
            <Route
              element={<ClientWorkoutFullPage />}
              path={P.PATH__BUSINESS__COACH_CLIENT_WORKOUT__BY_ID}
            />
          </Route>

          {/* === BUSINESS FACILITIES === */}
          <Route
            element={
              <ProtectedRoute
                allowedUserTypes={protectedRoutes.businessFacilities}
              >
                <Outlet />
              </ProtectedRoute>
            }
            path={P.PATH__BUSINESS__FACILITIES}
          >
            <Route
              element={<BusinessFacilitiesRootPage />}
              index={true}
              path={P.PATH__BUSINESS__FACILITIES}
            />

            <Route path={P.PATH__BUSINESS__FACILITIES__BY_ID}>
              <Route
                element={<BusinessFacilityFullPage />}
                index={true}
                path={P.PATH__BUSINESS__FACILITIES__BY_ID}
              />
              {/* === BUSINESS FACILITIES COACHES === */}
              <Route path={P.PATH__BUSINESS__FACILITY_COACH__BY_ID}>
                <Route
                  element={<BusinessCoachFullPage />}
                  index={true}
                  path={P.PATH__BUSINESS__FACILITY_COACH__BY_ID}
                />

                <Route
                  element={<ClientFullPage />}
                  path={P.PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID}
                />
                <Route
                  element={<ClientRoutineFullPage />}
                  path={P.PATH__BUSINESS__FACILITY_COACH_CLIENT_ROUTINE__BY_ID}
                />
                <Route
                  element={<ClientWorkoutFullPage />}
                  path={P.PATH__BUSINESS__FACILITY_COACH_CLIENT_WORKOUT__BY_ID}
                />
              </Route>
              {/* === BUSINESS FACILITIES CLIENTS === */}
              <Route path={P.PATH__BUSINESS__FACILITY_CLIENT__BY_ID}>
                <Route
                  element={<ClientFullPage />}
                  index={true}
                  path={P.PATH__BUSINESS__FACILITY_CLIENT__BY_ID}
                />
                <Route
                  element={<ClientRoutineFullPage />}
                  path={P.PATH__BUSINESS__FACILITY_CLIENT_ROUTINE__BY_ID}
                />
                <Route
                  element={<ClientWorkoutFullPage />}
                  path={P.PATH__BUSINESS__FACILITY_CLIENT_WORKOUT__BY_ID}
                />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* === LIBRARY === */}
        <Route element={<LibraryLayout />} path={P.PATH__LIBRARY}>
          <Route
            element={<LibraryRootPage />}
            index={true}
            path={P.PATH__LIBRARY}
          />

          {/* LIBRARY - TEMPLATE EXERCISES */}
          <Route
            element={<ExercisesLayout />}
            path={P.PATH__LIBRARY__EXERCISES}
          >
            <Route
              element={<LibraryExercisesRootPage />}
              index={true}
              path={P.PATH__LIBRARY__EXERCISES}
            />
            <Route
              element={<LibraryExerciseFullPage />}
              path={P.PATH__LIBRARY__EXERCISE__BY_ID}
            />
          </Route>

          {/* LIBRARY - TEMPLATE ROUTINES */}
          <Route
            element={
              <ProtectedRoute
                allowedUserTypes={protectedRoutes.templateRoutines}
              >
                <TemplateRoutinesLayout />
              </ProtectedRoute>
            }
            path={P.PATH__LIBRARY__TEMPLATE_ROUTINES}
          >
            <Route
              element={<TemplateRoutinesRootPage />}
              index={true}
              path={P.PATH__LIBRARY__TEMPLATE_ROUTINES}
            />
            <Route
              element={<TemplateRoutineFullPage />}
              path={P.PATH__LIBRARY__TEMPLATE_ROUTINE_FULL}
            />
            <Route
              element={<TemplateRoutineWorkoutPage />}
              path={P.PATH__LIBRARY__TEMPLATE_ROUTINE_WORKOUT}
            />
          </Route>

          {/* LIBRARY - TEMPLATE WORKOUTS */}
          <Route
            element={<TemplateWorkoutsLayout />}
            path={P.PATH__LIBRARY__TEMPLATE_WORKOUTS}
          >
            <Route
              element={<TemplateWorkoutsRootPage />}
              index={true}
              path={P.PATH__LIBRARY__TEMPLATE_WORKOUTS}
            />
            <Route
              element={<TemplateWorkoutFullPage />}
              path={P.PATH__LIBRARY__TEMPLATE_WORKOUT_FULL}
            />
          </Route>
        </Route>

        {/* === CLIENTS === */}
        <Route
          element={
            <ProtectedRoute allowedUserTypes={protectedRoutes.clients}>
              <ClientsLayout />
            </ProtectedRoute>
          }
          path={P.PATH__CLIENTS}
        >
          <Route
            element={<ClientsRootPage />}
            index={true}
            path={P.PATH__CLIENTS}
          />

          <Route element={<ClientFullPage />} path={P.PATH__CLIENTS__BY_ID} />

          <Route
            element={<ClientRoutineFullPage />}
            path={P.PATH__CLIENTS__BY_ID__ROUTINE__BY_ID}
          />
          <Route
            element={<ClientWorkoutFullPage />}
            path={P.PATH__CLIENTS__WORKOUT__BY_ID}
          />
        </Route>

        {/* === ME === */}
        <Route element={<MeLayout />} path={P.PATH__ME}>
          <Route
            element={<ClientFullPage clientId={getUser()?.id} />}
            index={true}
            path={P.PATH__ME}
          />
          <Route
            element={<ClientRoutineFullPage clientId={getUser()?.id} />}
            path={P.PATH__ME__ROUTINE__BY_ID}
          />

          <Route
            element={<ClientWorkoutFullPage clientId={getUser()?.id} />}
            path={P.PATH__ME__WORKOUT__BY_ID}
          />
        </Route>

        {/* === SETTINGS === */}
        <Route element={<SettingsLayout />} path={P.PATH__SETTINGS}>
          <Route
            element={<SettingsRootPage />}
            index={true}
            path={P.PATH__SETTINGS}
          />

          <Route
            element={<SettingsProfilePage />}
            path={P.PATH__SETTINGS__PROFILE}
          />
          <Route
            element={
              <ProtectedRoute
                allowedUserTypes={protectedRoutes.settingsSubscriptions}
              >
                <SettingsSubscriptionPage />
              </ProtectedRoute>
            }
            path={P.PATH__SETTINGS__SUBSCRIPTIONS}
          />
          <Route
            element={
              <ProtectedRoute
                allowedUserTypes={protectedRoutes.settingsBusiness}
              >
                <SettingsBusinessPage />
              </ProtectedRoute>
            }
            path={P.PATH__SETTINGS__BUSINESS}
          />
        </Route>

        <Route path={P.PATH__ERROR}>
          <Route element={<ErrorPage />} path={P.PATH__ERROR} />
          <Route element={<ErrorPage />} path={P.PATH__ERROR__TYPE} />
        </Route>

        <Route element={<MorePage />} path={P.PATH__MORE} />

        <Route element={<NotFoundHandler />} path="*" />
      </Route>
    </Routes>
  );
};

export default AppUserSubscribed;
