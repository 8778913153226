import { Location } from 'react-router-dom';

import { PathFragment } from './path-fragments';
import {
  PATH__BUSINESS,
  PATH__BUSINESS__ADMINS,
  PATH__BUSINESS__CLIENTS,
  PATH__BUSINESS__CLIENTS__BY_ID,
  PATH__BUSINESS__CLIENTS__BY_ID__ROUTINE__BY_ID,
  PATH__BUSINESS__CLIENTS__WORKOUT,
  PATH__BUSINESS__COACH_CLIENT__BY_ID,
  PATH__BUSINESS__COACH_CLIENT_ROUTINE__BY_ID,
  PATH__BUSINESS__COACH_CLIENT_WORKOUT__BY_ID,
  PATH__BUSINESS__COACHES,
  PATH__BUSINESS__COACHES__BY_ID,
  PATH__BUSINESS__FACILITIES,
  PATH__BUSINESS__FACILITIES__BY_ID,
  PATH__BUSINESS__FACILITY_CLIENT__BY_ID,
  PATH__BUSINESS__FACILITY_COACH__BY_ID,
  PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID,
  PATH__BUSINESS__FACILITY_COACH_CLIENT_ROUTINE__BY_ID,
  PATH__BUSINESS__FACILITY_COACH_CLIENT_WORKOUT__BY_ID,
  PATH__CLIENTS,
  PATH__CLIENTS__BY_ID,
  PATH__CLIENTS__BY_ID__ROUTINE__BY_ID,
  PATH__CLIENTS__WORKOUT__BY_ID,
  PATH__ERROR,
  PATH__ERROR__TYPE,
  PATH__HOME,
  PATH__LIBRARY,
  PATH__LIBRARY__EXERCISE__BY_ID,
  PATH__LIBRARY__EXERCISES,
  PATH__LIBRARY__TEMPLATE_ROUTINE_FULL,
  PATH__LIBRARY__TEMPLATE_ROUTINE_WORKOUT,
  PATH__LIBRARY__TEMPLATE_ROUTINES,
  PATH__LIBRARY__TEMPLATE_WORKOUT_FULL,
  PATH__LIBRARY__TEMPLATE_WORKOUTS,
  PATH__ME,
  PATH__ME__ROUTINE__BY_ID,
  PATH__ME__WORKOUT__BY_ID,
  PATH__MORE,
  PATH__ONBOARDING,
  PATH__ONBOARDING__PRODUCT,
  PATH__PROXY,
  PATH__PROXY_TYPE,
  PATH__ROOT,
  PATH__SETTINGS,
  PATH__SETTINGS__BUSINESS,
  PATH__SETTINGS__PROFILE,
  PATH__SETTINGS__SUBSCRIPTIONS,
} from './paths';

const BACK_PATH_MAP = {
  [PATH__BUSINESS]: PATH__BUSINESS,
  [PATH__BUSINESS__ADMINS]: PATH__BUSINESS,
  [PATH__BUSINESS__CLIENTS]: PATH__BUSINESS,
  [PATH__BUSINESS__CLIENTS__BY_ID]: PATH__BUSINESS__CLIENTS,
  [PATH__BUSINESS__CLIENTS__BY_ID__ROUTINE__BY_ID]:
    PATH__BUSINESS__CLIENTS__BY_ID,
  [PATH__BUSINESS__CLIENTS__WORKOUT]:
    PATH__BUSINESS__CLIENTS__BY_ID__ROUTINE__BY_ID,
  [PATH__BUSINESS__COACH_CLIENT__BY_ID]: PATH__BUSINESS__COACHES__BY_ID,

  [PATH__BUSINESS__COACH_CLIENT_ROUTINE__BY_ID]:
    PATH__BUSINESS__COACH_CLIENT__BY_ID,
  [PATH__BUSINESS__COACH_CLIENT_WORKOUT__BY_ID]:
    PATH__BUSINESS__COACH_CLIENT_ROUTINE__BY_ID,
  [PATH__BUSINESS__COACHES]: PATH__BUSINESS,
  [PATH__BUSINESS__COACHES__BY_ID]: PATH__BUSINESS__COACHES,
  [PATH__BUSINESS__FACILITIES]: PATH__BUSINESS,

  [PATH__BUSINESS__FACILITIES__BY_ID]: PATH__BUSINESS__FACILITIES,
  [PATH__BUSINESS__FACILITY_CLIENT__BY_ID]: PATH__BUSINESS__FACILITIES__BY_ID,
  [PATH__BUSINESS__FACILITY_COACH__BY_ID]: PATH__BUSINESS__FACILITIES__BY_ID,
  [PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID]:
    PATH__BUSINESS__FACILITY_COACH__BY_ID,
  [PATH__BUSINESS__FACILITY_COACH_CLIENT_ROUTINE__BY_ID]:
    PATH__BUSINESS__FACILITY_COACH_CLIENT__BY_ID,
  [PATH__BUSINESS__FACILITY_COACH_CLIENT_WORKOUT__BY_ID]:
    PATH__BUSINESS__FACILITY_COACH_CLIENT_ROUTINE__BY_ID,
  [PATH__CLIENTS]: PATH__CLIENTS,

  [PATH__CLIENTS__BY_ID]: PATH__CLIENTS,
  [PATH__CLIENTS__BY_ID__ROUTINE__BY_ID]: PATH__CLIENTS__BY_ID,
  [PATH__CLIENTS__WORKOUT__BY_ID]: PATH__CLIENTS__BY_ID__ROUTINE__BY_ID,
  [PATH__ERROR]: PATH__ROOT,

  [PATH__ERROR__TYPE]: PATH__ERROR,
  [PATH__HOME]: PATH__ROOT,
  [PATH__LIBRARY]: PATH__MORE,
  [PATH__LIBRARY__EXERCISE__BY_ID]: PATH__LIBRARY__EXERCISES,
  [PATH__LIBRARY__EXERCISES]: PATH__LIBRARY,
  [PATH__LIBRARY__TEMPLATE_ROUTINE_FULL]: PATH__LIBRARY__TEMPLATE_ROUTINES,
  [PATH__LIBRARY__TEMPLATE_ROUTINE_WORKOUT]:
    PATH__LIBRARY__TEMPLATE_ROUTINE_FULL,
  [PATH__LIBRARY__TEMPLATE_ROUTINES]: PATH__LIBRARY,
  [PATH__LIBRARY__TEMPLATE_WORKOUT_FULL]: PATH__LIBRARY__TEMPLATE_WORKOUTS,
  [PATH__LIBRARY__TEMPLATE_WORKOUTS]: PATH__LIBRARY,
  [PATH__ME]: PATH__ME,
  [PATH__ME__ROUTINE__BY_ID]: PATH__ME,
  [PATH__ME__WORKOUT__BY_ID]: PATH__ME__ROUTINE__BY_ID,

  [PATH__MORE]: PATH__MORE,
  [PATH__ONBOARDING]: PATH__ONBOARDING,

  [PATH__ONBOARDING__PRODUCT]: PATH__ONBOARDING,
  [PATH__PROXY]: PATH__ROOT,
  [PATH__PROXY_TYPE]: PATH__PROXY,
  [PATH__ROOT]: null, // Root has no "back" path
  [PATH__SETTINGS]: PATH__MORE,
  [PATH__SETTINGS__BUSINESS]: PATH__SETTINGS,
  [PATH__SETTINGS__PROFILE]: PATH__SETTINGS,
  [PATH__SETTINGS__SUBSCRIPTIONS]: PATH__SETTINGS,
};

export const getBackPath = (currentPath: string, toPathFragment?: string) => {
  const path = BACK_PATH_MAP[currentPath] || PATH__ROOT;

  if (toPathFragment === undefined) {
    return path;
  }

  /**
   * If we want to go
   * from `/clients/client-uuid/routines/routine-uuid/workouts/workout-uuid`
   * to `/clients/client-uuid`
   * we pass `/:clientId` as toPathFragment
   */
  const fragments = currentPath.split(toPathFragment);

  const pathNew = fragments[0] + toPathFragment;

  return pathNew;
};

export interface GetBackUrlOptions extends OptionsCommon {
  toPathFragment?: PathFragment;
}

interface OptionsCommon {
  keepHash?: boolean;
  keepSearch?: boolean;
}

/**
 * Returns the back URL based on the current path by comparing the segments.
 * @param currentPath                - The current path definition from rrd.
 * @param location                   - The location object to get `search` and `hash`.
 * @param [options]                  - Options to configure the behavior.
 * @param [options.keepSearch=false] - Whether to retain the current search parameters.
 * @param [options.keepHash=false]   - Whether to retain the current hash fragment.
 * @param [options.toPathFragment]   - Get the url to a specific fragment
 * @returns                          - The calculated back URL.
 */
// eslint-disable-next-line complexity
export const getBackUrl = (
  currentPath: null | string,
  location: Location | null,
  options: GetBackUrlOptions = {},
) => {
  const { keepHash = false, keepSearch = false, toPathFragment } = options;

  const { hash, pathname, search } = location ?? window.location;

  if (currentPath === null) {
    console.error('getBackUrl: No current path');
    return '';
  }

  // Find the back path from the map, defaulting to "/" if not found.
  const backPath = getBackPath(currentPath, toPathFragment);

  // Split current and back paths into segments
  const backSegments = backPath.split('/').filter(Boolean);
  const currentSegments = pathname.split('/').filter(Boolean);

  // Remove excess segments from current path to match the back path structure
  const resultSegments = currentSegments.slice(0, backSegments.length);

  // Added because of /more navigation. If there is one segment left, we want to always return to
  // the back segment
  const finalSegment =
    resultSegments.length === 1 && backSegments.length === 1
      ? backSegments[0]
      : resultSegments.join('/');

  // Join segments back into a path
  let backUrl = '/' + finalSegment;

  // Append search and hash if options are enabled
  if (keepSearch && search) {
    backUrl += search;
  }
  if (keepHash && hash) {
    backUrl += hash;
  }

  return backUrl;
};

interface ReplacePathFragmentOptions extends OptionsCommon {
  pathFragment: PathFragment;
  replaceWith: string;
}

// eslint-disable-next-line complexity
export const replacePathFragmentUrl = (
  currentPath: null | string,
  location: Location | null,
  options: ReplacePathFragmentOptions,
) => {
  const {
    keepHash = false,
    keepSearch = false,
    pathFragment,
    replaceWith,
  } = options;

  const { hash, pathname, search } = location ?? window.location;

  if (currentPath === null) {
    console.error('getBackUrl: No current path');
    return '';
  }

  const pathFragmentPlain = pathFragment.replace('/', '');

  // Split current and back paths into segments
  const replaceSegments = currentPath.split('/').filter(Boolean);
  const currentSegments = pathname.split('/').filter(Boolean);
  const indexToReplace = replaceSegments.findIndex(
    item => item === pathFragmentPlain,
  );

  if (indexToReplace > -1) {
    currentSegments[indexToReplace] = replaceWith;
  }

  // Join segments back into a path
  let resultUrl = '/' + currentSegments.join('/');

  // Append search and hash if options are enabled
  if (keepSearch && search) {
    resultUrl += search;
  }
  if (keepHash && hash) {
    resultUrl += hash;
  }

  return resultUrl;
};
