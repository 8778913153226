import { Exercise } from '@strenco/api';

import { getUser } from '../../../user/api/cache/getters';
import { isSuperAdmin } from '../../../user/utils';

type ExercisePermissionAttributes = Pick<Exercise, 'createdBy' | 'isPublic'>;

export const canDeleteExercise = ({
  createdBy,
  isPublic,
}: ExercisePermissionAttributes) => {
  const user = getUser();

  if (user === null) {
    return false;
  }

  if (isPublic === true && isSuperAdmin() === true) {
    return true;
  }

  return createdBy === user.id;
};

export const canEditExercise = ({
  createdBy,
  isPublic,
}: ExercisePermissionAttributes) => {
  const user = getUser();

  if (user === null) {
    return false;
  }

  if (isPublic === true && isSuperAdmin() === true) {
    return true;
  }

  return createdBy === user.id;
};
