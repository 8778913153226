import { MantineColor } from '@mantine/core';
import {
  ExerciseVolumeUnit,
  TrainingProgramFull,
  WorkoutStatus,
} from '@strenco/api';

import { CLIENT_WORKOUT__STATUS_COLORS } from '../constants';

// eslint-disable-next-line complexity
export const getSetCounts = (
  workoutId: string,
  data: Pick<
    TrainingProgramFull<'user'>,
    'exerciseSetGroups' | 'exerciseSets' | 'workouts'
  >,
) => {
  const workout = data.workouts[workoutId];

  const completed = new Set<number>();
  const completedWithMissingDetails = new Set<number>();
  const skipped = new Set<number>();
  let total = 0;

  for (const groupId of workout.exerciseSetGroupsOrder ?? []) {
    const setIdsAll = data.exerciseSetGroups?.[groupId]?.setsOrder ?? [];
    total += setIdsAll.length;

    for (const setId of setIdsAll) {
      const set = data.exerciseSets[setId];
      const result = set?.result;
      if (!result) continue;

      if (result.isSkipped) {
        skipped.add(setId);
        continue;
      }
      completed.add(setId);
      const volumeUnit = set.set.volumeUnit;

      if (volumeUnit === ExerciseVolumeUnit.Reps) {
        if (result.actualReps === null || result.actualWeight === null) {
          completedWithMissingDetails.add(setId);
        }
      }

      if (
        volumeUnit === ExerciseVolumeUnit.Time &&
        result.actualTime === null
      ) {
        completedWithMissingDetails.add(setId);
      }
    }
  }

  return {
    completed: completed.size,
    completedWithMissingDetails: completedWithMissingDetails.size,
    skipped: skipped.size,
    total,
  };
};

export const isWorkoutCompleted = (
  status: undefined | WorkoutStatus,
  includeSkipped = false,
): status is
  | WorkoutStatus.Completed
  | WorkoutStatus.CompletedWithMissingData => {
  const isCompleted =
    status === WorkoutStatus.Completed ||
    status === WorkoutStatus.CompletedWithMissingData;

  if (includeSkipped === false) {
    return isCompleted;
  }

  return isCompleted || status === WorkoutStatus.Skipped;
};

/**
 * Get the appropriate color for each workout status
 * @param status Workout status
 * @returns      Mantine Color
 */
export const getWorkoutStatusColor = (status: WorkoutStatus): MantineColor => {
  return CLIENT_WORKOUT__STATUS_COLORS[status];
};
