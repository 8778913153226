/**
 * @file Contains translations for Confirmation Modals
 */
import {
  MutationDelete,
  MutationUpdate,
  MutationUpdateConfirm,
} from '../../constants/mutation-keys';

interface Props {
  confirm: string;
  text: string;
  title: string;
}

const translationsConfirmation: Record<
  MutationDelete | MutationUpdateConfirm,
  Props
> = {
  [MutationDelete.BusinessAdmin]: {
    confirm: 'Delete Admin',
    text: 'By continuing, the admin will be permanently deleted',
    title: 'Confirm Admin Deletion',
  },
  [MutationDelete.BusinessCoach]: {
    confirm: 'Delete Coach',
    text: 'By continuing, the coach will be permanently deleted.',
    title: 'Confirm Coach Deletion',
  },
  [MutationDelete.BusinessCoachRank]: {
    confirm: 'Delete',
    text: 'By continuing, this coach rank will be deleted.',
    title: 'Confirm Coach Level Deletion',
  },
  [MutationDelete.Client]: {
    confirm: 'Delete Client',
    text: 'By continuing, the client will be permanently deleted, and all associated data will be lost.',
    title: 'Confirm Client Deletion',
  },
  [MutationDelete.ClientRoutine]: {
    confirm: 'Delete Routine',
    text: 'By continuing, this routine will be permanently deleted, and all associated data will be lost.',
    title: 'Confirm Routine Deletion',
  },
  [MutationDelete.ClientWorkout]: {
    confirm: 'Delete Workout',
    text: 'By continuing, this workout will be permanently deleted, and all associated data will be lost.',
    title: 'Confirm Workout Deletion',
  },
  [MutationDelete.Exercise]: {
    confirm: 'Delete Exercise',
    text: 'By continuing, this exercise will be permanently deleted, and all associated data will be lost.',
    title: 'Confirm Exercise Deletion',
  },
  [MutationDelete.RoutineCycle]: {
    confirm: 'Delete Cycle',
    text: 'By continuing, all workouts within this cycle will be deleted as well.',
    title: 'Confirm Cycle Deletion',
  },
  [MutationDelete.Subscription]: {
    confirm: 'Skip Workout',
    text: 'Proceeding will mark this workout as skipped. Are you sure you want to skip this workout?',
    title: 'Cancel Subscription',
  },
  [MutationDelete.TemplateRoutine]: {
    confirm: 'Delete Program Template',
    text: 'By continuing, this program template will be permanently deleted, and all associated data will be lost.',
    title: 'Confirm Template Deletion',
  },
  [MutationDelete.TemplateWorkout]: {
    confirm: 'Delete Workout',
    text: 'By continuing, this template workout will be permanently deleted, and all associated data will be lost.',
    title: 'Confirm Template Deletion',
  },
  [MutationUpdate.ClientWorkoutReset]: {
    confirm: 'Reset Workout',
    text: 'By proceeding, the current workout will be reset to its initial state. This action cannot be undone.',
    title: 'Confirm Workout Reset',
  },
  [MutationUpdate.ClientWorkoutSkip]: {
    confirm: 'Skip Workout',
    text: 'Proceeding will mark this workout as skipped. Are you sure you want to skip this workout?',
    title: 'Confirm Workout Skip',
  },
  [MutationUpdate.SubscriptionActionCancel]: {
    confirm: 'Cancel Subscription',
    text: 'By proceeding, you are canceling your current subscription.',
    title: 'Confirm Subscription Cancellation',
  },
  [MutationUpdate.SubscriptionActionPause]: {
    confirm: 'Pause Subscription',
    text: 'By proceeding, you are pausing your current subscription. You will not be charged until you resume your subscription.',
    title: 'Confirm Subscription Pause',
  },
  [MutationUpdate.SubscriptionActionResume]: {
    confirm: 'Resume Subscription',
    text: 'By proceeding, you are reactivating this subscription.',
    title: 'Confirm Subscription Resume',
  },
};

export default translationsConfirmation;
