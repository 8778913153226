import { useTrainingProgramFull } from '@strenco/api';
import { FC } from 'react';

import { useParamRoutineId } from '../../../router/hooks/path-params';

export const PrefetchTrainingProgramFull: FC = () => {
  const trainingProgramId = useParamRoutineId();
  useTrainingProgramFull(trainingProgramId, { refetchOnMount: true });
  return null;
};
