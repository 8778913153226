/** @file Contains getters for search params */
import { ExerciseCategory, UserType } from '@strenco/api';
import { Location } from 'react-router-dom';

import { WorkoutTableColumn } from '../../../features/routine/helpers/workout-table';
import { ModalId } from '../../../utils/modal';
import { getSearchParam, getSearchParamAll } from '../../helpers/searchParams';
import { RailNavigationItemName, SearchParam } from '../../types';

/**
 * Get the coachId search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamCoachId = (location?: Location): null | string =>
  getSearchParam(SearchParam.CoachId, location);

/**
 * Get the code search param for user verification
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamCode = (location?: Location): null | string =>
  getSearchParam(SearchParam.Code, location);

/**
 * Get the email search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamEmail = (location?: Location): null | string =>
  getSearchParam(SearchParam.Email, location);

/**
 * Get the view search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamExerciseCategory = (
  location?: Location,
): ExerciseCategory | null =>
  getSearchParam(SearchParam.ExercisesCategory, location);

/**
 * Get the modalId search param which identifies which modal should be visible
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamModalId = (location?: Location): ModalId | null =>
  getSearchParam(SearchParam.ModalId, location);

/**
 * Get the clientId search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamClientId = (location?: Location): null | string =>
  getSearchParam(SearchParam.ClientId, location);

/**
 * Get the cycleId search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamCycleId = (location?: Location): null | number => {
  const value = getSearchParam(SearchParam.CycleId, location);

  if (value === null || Number.isNaN(Number(value))) {
    return null;
  }

  return Number(value);
};

/**
 * Get the trainingProgramId search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamTrainingProgramId = (
  location?: Location,
): null | string => {
  const value = getSearchParam(SearchParam.TrainingProgramId, location);

  return value;
};

/**
 * Get the exerciseId search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamExerciseId = (location?: Location): null | string =>
  getSearchParam(SearchParam.ExerciseId, location);

/**
 * Get the user type search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamUserType = (location?: Location): null | UserType =>
  getSearchParam(SearchParam.UserType, location);

/**
 * Get the entity id search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamEntityId = <T = string>(
  location?: Location,
): null | T => getSearchParam(SearchParam.EntityId, location) as null | T;

/**
 * Get the entity type search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamEntityType = <T = string>(
  location?: Location,
): null | T => getSearchParam(SearchParam.EntityType, location) as null | T;

/**
 * Get the entity variant search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamEntityVariant = <T = string>(
  location?: Location,
): null | T => getSearchParam(SearchParam.EntityVariant, location) as null | T;

/**
 * Get the search params of exercise instruction ids that are connected to the
 * set builder
 * @param location History Location object (optional)
 * @returns        Exercise instruction ids
 */
export const getSearchParamSelectedExerciseSetGroups = (
  location?: Location,
): null | number[] => {
  const param = getSearchParamAll(
    SearchParam.SelectedExerciseSetGroup,
    location,
  );

  if (param.length === 0) {
    return null;
  }

  return param.map(v => parseInt(v.toString()));
};

/**
 * Get whether the sets builder is open from url
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamSetsBuilderOpen = (
  location?: Location,
): boolean | null => {
  const value = getSearchParam(SearchParam.SetsBuilderOpen, location);

  if (value === null) {
    return null;
  }

  if (value === 'true') {
    return true;
  }

  return false;
};

/**
 * Get set id search param used within sets builder to prefill data
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamSetsBuilderSetId = (
  location?: Location,
): null | number => getSearchParam(SearchParam.SetsBuilderSetId, location);

/**
 * Get routine status from url, to navigate between different tabs
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamRoutineStatus = (location?: Location) =>
  getSearchParam(SearchParam.RoutineStatus, location);

/**
 * Get the search params of set parameters we want to use filtering to apply
 * changes to existing sets
 * @param location History Location object (optional)
 * @returns        SetFormField array
 */
export const getSearchParamSetParameters = (
  location?: Location,
): null | WorkoutTableColumn[] => {
  const param = getSearchParamAll(SearchParam.SetParameters, location);

  if (param.length === 0) {
    return null;
  }

  return param as WorkoutTableColumn[];
};

/**
 * This search param is used to track which sets are selected according to
 * sets builder sets field. We use it to highlight the rows in the set builder.
 * @param location History Location object (optional)
 * @returns        Set of numbers
 */
export const getSearchParamSetsBuilderSetsSelected = (
  location?: Location,
): null | Set<number> => {
  const param = getSearchParam(SearchParam.SetsBuilderSetsSelected, location);

  if (param === null) {
    return null;
  }

  return new Set(param.split(',').map(Number));
};

/**
 * Get the search params of workouts selected for comparison
 * @param location History Location object (optional)
 * @returns        Workout ids
 */
export const getSearchParamCompareWorkouts = (
  location?: Location,
): null | string[] => {
  const param = getSearchParam(SearchParam.CompareWorkouts, location);

  if (param === null) {
    return null;
  }

  return param.split(',');
};

/**
 * Get the search search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamSearch = (location?: Location): null | string =>
  getSearchParam(SearchParam.Search, location);

/**
 * Get the sortBy search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamSortBy = <T = string>(
  location?: Location,
): null | T => getSearchParam(SearchParam.SortBy, location) as T;

/**
 * Get the sortDirection search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamSortDirection = <T = string>(
  location?: Location,
): null | T => getSearchParam(SearchParam.SortDirection, location) as T;

/**
 * Get the facilities search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamFacilities = (
  location?: Location,
): null | number[] => {
  const param = getSearchParam(SearchParam.Facilities, location);

  if (param === null) {
    return null;
  }

  const splitted = param.split(',');

  return splitted.length === 0 ? null : splitted.map(n => +n);
};

/**
 * Get the rail search param
 * @param location History Location object (optional)
 * @returns        The requested search param value
 */
export const getSearchParamRail = (
  location?: Location,
): null | RailNavigationItemName => {
  const param = getSearchParam(SearchParam.Rail, location);

  if (param === null) {
    return null;
  }

  return param;
};
