import { useFacilityDelete } from '@strenco/api';

import { SearchParam } from '../../../router/types';
import { ModalId, useModal } from '../../../utils/modal';

export const useFacilityActions = (facilityId: number) => {
  const { openModal } = useModal();
  const { isPending: isDeleting, mutateAsync: deleteFacility } =
    useFacilityDelete(facilityId);

  const requestEdit = () => {
    openModal(ModalId.BusinessFacilityEdit, {
      [SearchParam.EntityId]: facilityId,
    });
  };

  return { isDeleting, requestDelete: deleteFacility, requestEdit };
};
