import {
  TrainingProgramFull,
  useExerciseSetContext,
  useExerciseSetGroupContext,
  useTrainingProgramContext,
  useTrainingProgramCycleContext,
  useTrainingProgramFull,
  useWorkoutContext,
} from '@strenco/api';

export const useTrainingProgramById = ():
  | TrainingProgramFull<'template'>
  | TrainingProgramFull<'user'>
  | undefined => {
  const { id } = useTrainingProgramContext();
  const { data } = useTrainingProgramFull(id);

  return data;
};

export const useTrainingProgramCycle = () => {
  const id = useTrainingProgramContext().id;
  const cycleId = useTrainingProgramCycleContext().id;
  const { data } = useTrainingProgramFull(id);

  return data?.cycles[cycleId];
};

export const useWorkout = () => {
  const id = useTrainingProgramContext().id;
  const workoutId = useWorkoutContext().id;

  const { data } = useTrainingProgramFull(id);

  return data?.workouts[workoutId];
};

export const useExerciseSetGroup = () => {
  const id = useTrainingProgramContext().id;
  const groupId = useExerciseSetGroupContext().id;
  const { data } = useTrainingProgramFull(id);

  return data?.exerciseSetGroups[groupId];
};

export const useExerciseSet = () => {
  const id = useTrainingProgramContext().id;
  const setId = useExerciseSetContext().id;
  const { data } = useTrainingProgramFull(id);

  return data?.exerciseSets[setId];
};
