/** @file Contains utils for environment */

import { version } from '../../package.json';
import envs from '../config/envs';

/**
 * Checks project environment
 * @returns Whether the environment is dev
 */
export const isDev = (): boolean => envs.stage === 'dev';

/**
 * Checks project environment
 * @returns Whether the environment is local
 */
export const isLocal = (): boolean => {
  return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
};

/**
 * Checks project environment
 * @returns Whether the environment is production
 */
export const isProduction = (): boolean =>
  isLocal() === false && envs.stage === 'prod';

export const getRelease = () => {
  return `strenco@${version}`;
};
