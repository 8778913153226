import { BusinessUser, UserStatus } from '@strenco/api';
import { FC } from 'react';

import { ActionIconHeaderActions } from '../../../../components/common/ActionIcons';
import { iconsMap } from '../../../../components/common/ActionIcons/config';
import CustomMenu, {
  MenuItem,
} from '../../../../components/ui/menus/CustomMenu';
import { translate } from '../../../../i18n';
import useCoachActions from '../../hooks/useCoachActions';

interface Props {
  coach: Pick<BusinessUser, 'id' | 'status'>;
}

const BusinessCoachHeaderActions: FC<Props> = ({ coach }) => {
  const {
    isDeleting,
    isUpdating,
    requestActivate,
    requestDeactivate,
    requestDelete,
    requestEdit,
  } = useCoachActions(coach);
  const items: MenuItem[] = [];

  if (coach !== null) {
    items.push({
      Icon: iconsMap.edit.Icon,
      label: translate('business.coaches_edit'),
      onClick: requestEdit,
    });

    if (coach.status === UserStatus.Active) {
      items.push({
        Icon: iconsMap.userDeactivate.Icon,
        label: translate('business.coaches_deactivate'),
        onClick: requestDeactivate,
      });
    }

    if (coach.status === UserStatus.Inactive) {
      items.push({
        Icon: iconsMap.userActivate.Icon,
        label: translate('business.coaches_activate'),
        onClick: requestActivate,
      });
    }

    items.push({
      disabled: coach.status === UserStatus.Active,
      Icon: iconsMap.delete.Icon,
      label: translate('business.coaches_delete'),
      onClick: requestDelete,
    });
  }

  return (
    <CustomMenu
      menuItems={items}
      position="bottom-end"
      tooltip={translate('business.coaches_actions')}
      trigger={<ActionIconHeaderActions loading={isDeleting || isUpdating} />}
    />
  );
};

export default BusinessCoachHeaderActions;
