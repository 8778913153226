import { modals } from '@mantine/modals';
import {
  Exercise,
  ExerciseDeleteReferenceRestrictErrorResponse,
  useExerciseDelete,
  useExerciseDuplicate,
} from '@strenco/api';

import { iconsMap } from '../../../../components/common/ActionIcons/config';
import { MenuItem } from '../../../../components/ui/menus/CustomMenu';
import { MutationDelete } from '../../../../constants/mutation-keys';
import { translate } from '../../../../i18n';
import { SearchParam } from '../../../../router/types';
import { getApiErrorCode, getApiErrorDetails } from '../../../../utils/errors';
import { ModalId, useModal } from '../../../../utils/modal';
import { confirmDanger } from '../../../../utils/modal/confirm';
import ExerciseInUseModalContent from '../components/ExerciseInUseModalContent';
import { getDuplicateTooltipAndIcon } from '../components/ExercisesTable/ExerciseRow/helpers';
import { canDeleteExercise, canEditExercise } from '../utils/permissions';

const useExerciseActions = (exerciseId: string) => {
  const { isPending: isDeleting, mutateAsync: deleteExercise } =
    useExerciseDelete(exerciseId);
  const { openModal } = useModal();
  const { isPending: isDuplicating, mutateAsync: duplicateExercise } =
    useExerciseDuplicate(exerciseId);

  const requestCreate = () => {
    openModal(ModalId.Exercise);
  };

  const requestDelete = () => {
    confirmDanger({
      key: MutationDelete.Exercise,
      onConfirm: () =>
        deleteExercise().catch(errorResponse => {
          if (
            getApiErrorCode(errorResponse) === 'ExerciseDeleteUsedInInWorkouts'
          ) {
            const details =
              getApiErrorDetails<ExerciseDeleteReferenceRestrictErrorResponse>(
                errorResponse,
              );

            if (details !== undefined) {
              modals.open({
                children: (
                  <ExerciseInUseModalContent
                    {...details}
                    exerciseId={exerciseId}
                    onChange={requestDelete}
                  />
                ),
                size: 'xl',
                title: translate('exercise.not_deleted__title'),
              });
            }
          }
        }),
    });
  };

  const requestDuplicate = async () => {
    const response = await duplicateExercise();
    openModal(ModalId.Exercise, {
      [SearchParam.ExerciseId]: response.exercise.id,
    });
  };

  const requestEdit = (exerciseId: string) => {
    openModal(ModalId.Exercise, { [SearchParam.ExerciseId]: exerciseId });
  };

  return {
    isDeleting,
    isDuplicating,
    requestCreate,
    requestDelete,
    requestDuplicate,
    requestEdit,
  };
};

export const useExerciseActionItems = (
  exercise: Pick<Exercise, 'createdBy' | 'id' | 'isPublic'>,
) => {
  const {
    isDeleting,
    isDuplicating,
    requestDelete,
    requestDuplicate,
    requestEdit,
  } = useExerciseActions(exercise.id);

  const items: MenuItem[] = [];
  const isLoading = isDeleting || isDuplicating;
  const { icon, tooltip } = getDuplicateTooltipAndIcon(exercise);

  if (canEditExercise(exercise)) {
    items.push({
      Icon: iconsMap.edit.Icon,
      isLoading,
      label: translate('exercise.edit'),
      onClick: () => requestEdit(exercise.id),
    });
  }

  items.push({
    Icon: icon,
    isLoading,
    label: tooltip,
    onClick: requestDuplicate,
  });

  if (canDeleteExercise(exercise)) {
    items.push({
      Icon: iconsMap.delete.Icon,
      isLoading,
      label: translate('exercise.delete'),
      onClick: requestDelete,
    });
  }

  return items;
};

export default useExerciseActions;
