/** @file Contains utils for user */
import { UserType } from '@strenco/api';

import { StorageKeys, StorageService } from '../../../utils/storage';
import { User } from '../types';

/**
 * Get the current user object from local storage.
 * @returns The user object from local storage.
 */
export const getCurrentStorageUser = (): null | User => {
  const storageUser = StorageService.read<User>(StorageKeys.User);

  if (storageUser === null) {
    return null;
  }

  return storageUser;
};
/**
 * Get the user type of logged in user
 * @returns The user type if logged in, otherwise null
 */
export const getUserType = (): null | UserType => {
  const user = getCurrentStorageUser();

  return user?.type ?? null;
};

/**
 * Get whether the logged in user is CoachPremium
 * @returns Whether the user is CoachPremium
 */
export const isCoachUser = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.Coach;
};

/**
 * Get whether the logged in user is BusinessOwner
 * @returns Whether the user is BusinessOwner
 */
export const isBusinessOwner = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.BusinessOwner;
};

/**
 * Get whether the logged in user is PersonalUser
 * @returns Whether the user is PersonalUser
 */
export const isPersonalUser = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.Personal;
};

/**
 * Get whether the logged in user is BusinessAdmin
 * @returns Whether the user is BusinessAdmin
 */
export const isBusinessAdmin = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.BusinessAdmin;
};

/**
 * Get whether the logged in user is BusinessCoach
 * @returns Whether the user is BusinessCoach
 */
export const isBusinessCoach = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.BusinessCoach;
};

/**
 * Get whether an id equals to the current user id
 * @param id Id we want to compare
 * @returns  Whether id is current user id
 */
export const isCurrentUserId = (id: null | string): boolean => {
  const user = getCurrentStorageUser();

  return user !== null && user.id === id;
};

/**
 * Get whether the logged in user is SuperAdmin
 * @returns Whether the user is SuperAdmin
 */
export const isSuperAdmin = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.SuperAdmin;
};

/**
 * Get whether the logged in user is StrencoAdmin
 * @returns Whether the user is StrencoAdmin
 */
export const isStrencoAdmin = (): boolean => {
  const user = getCurrentStorageUser();

  return user?.type === UserType.Admin;
};

export const isSubscriptionUser = () => {
  return isBusinessOwner() || isCoachUser() || isPersonalUser();
};
