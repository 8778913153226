import { Breadcrumbs, Grid, Space, Stack } from '@mantine/core';
import { ComponentProps, FC, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  BOTTOM_NAVIGATION__HEIGHT,
  CONTENT_COLOR,
  HEADER_HEIGHT,
} from '../../../constants/preferences';
import { CLASS_NAME__EMPTY_HIDDEN } from '../../../constants/selectors';
import useIsMobile from '../../../hooks/useIsMobile';
import useScrollContainerContext from '../../../providers/scroll-container/useScrollContainerContext';
import { vars } from '../../../theme';
import AlertSubscriptionStatus from '../../common/AlertSubscriptionStatus';
import PageHeader from '../HeaderDesktop';

const space = vars.spacing.xl;

type Props = ComponentProps<typeof Breadcrumbs> & PropsWithChildren &
  { withHeader?: boolean };

const PageLayout: FC<Props> = ({
  children,
  withHeader = true,
  ...pageHeaderProps
}) => {
  const { pathname } = useLocation();

  const ref = useScrollContainerContext();

  const isMobile = useIsMobile();
  const offset = isMobile
    ? HEADER_HEIGHT + BOTTOM_NAVIGATION__HEIGHT
    : HEADER_HEIGHT;

  const style = isMobile ? undefined : { borderTopLeftRadius: vars.radius.md };

  useEffect(() => {
    if (withHeader === false) {
      window.scrollTo({ top: 0 });
    } else {
      ref.current?.scroll({ top: 0 });
    }
  }, [withHeader, pathname, ref]);

  if (withHeader === false) {
    return (
      <Stack bg="white" mih="100dvh" p="sm" ref={ref} style={style}>
        {children}
        <Space h={space} />
      </Stack>
    );
  }

  return (
    <Grid
      bg={CONTENT_COLOR}
      mah={`calc(100dvh - ${offset}px)`}
      mih={`calc(100dvh - ${offset}px)`}
      p="sm"
      ref={ref}
      style={{ ...style, overflow: 'auto' }}
    >
      <PageHeader {...pageHeaderProps} />
      <Grid.Col className={CLASS_NAME__EMPTY_HIDDEN}>
        <AlertSubscriptionStatus />
      </Grid.Col>
      <Grid.Col>{children}</Grid.Col>
      <Grid.Col>
        <Space h={space} />
      </Grid.Col>
    </Grid>
  );
};

export default PageLayout;
