import {
  EntityUserProvider,
  TrainingProgramProvider,
  WorkoutProvider,
} from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../../constants/page-ids';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../../i18n/helpers/page';
import {
  useParamRoutineId,
  useParamWorkoutId,
} from '../../../../router/hooks/path-params';
import Layout from './layout';

const Content = lazy(() => import('./content'));

const TemplateRoutineWorkoutPage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.TemplateWorkouts));
  const trainingProgramId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  if (!trainingProgramId || !workoutId) return null;

  return (
    <EntityUserProvider id="template">
      <TrainingProgramProvider id={trainingProgramId} type="template">
        <WorkoutProvider id={workoutId} type="template">
          <Layout />
          <Suspense fallback={null}>
            <Content />
          </Suspense>
        </WorkoutProvider>
      </TrainingProgramProvider>
    </EntityUserProvider>
  );
};

export default TemplateRoutineWorkoutPage;
