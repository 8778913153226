import { CheckIcon, CloseButton, Select, Text } from '@mantine/core';
import { ExerciseVolumeUnit, useExercises } from '@strenco/api';
import { FC, useMemo, useRef } from 'react';

import { translate } from '../../../../../i18n';
import { vars } from '../../../../../theme';
import { getExerciseFullName } from './helpers';
import NothingFoundMessage from './NothingFoundMessage';
import { useGroupedExercises } from './useOptimizedExercises';

interface Props {
  autoFocus?: boolean;
  defaultSelected: null | string;
  isLoading?: boolean;
  onBlur?: () => void;
  onChange: (exerciseId: null | string) => void;
  onClear?: () => void;
  styleVariant?: 'dark' | 'light';
  volumeUnitFilter?: Set<ExerciseVolumeUnit>;
}

export const ExercisePickerAutocomplete: FC<Props> = ({
  autoFocus,
  defaultSelected,
  isLoading,
  onBlur,
  onChange,
  onClear,
  styleVariant,
  volumeUnitFilter,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { data, isLoading: isExercisesLoading } = useExercises();
  const loading = isExercisesLoading || isLoading;
  const { groupedByName, names } = useGroupedExercises(volumeUnitFilter);

  const items = useMemo(
    () =>
      names.map(name => ({
        group: name,
        items: groupedByName[name].map(item => ({
          disabled: item.id === defaultSelected,
          label: getExerciseFullName(item),
          value: item.id,
        })),
      })),
    [names, groupedByName, defaultSelected],
  );

  return (
    <Select
      autoFocus={autoFocus}
      data={items}
      defaultValue={defaultSelected}
      disabled={loading}
      limit={10}
      name="exercise-picker"
      nothingFoundMessage={<NothingFoundMessage />}
      onBlur={onBlur}
      onChange={onChange}
      onClear={() => alert('aaa')}
      onFocus={e => {
        e.currentTarget.select();
      }}
      pos="relative"
      radius="md"
      ref={ref}
      renderOption={({ checked, option }) => {
        const exercise = data?.entities[option.value];
        if (!exercise) return null;
        const { isUnilateral, name, variation } = exercise;

        return (
          <Text c="dark" fw={500} size="lg">
            {checked && (
              <CheckIcon
                color={vars.colors.branding[6]}
                size={14}
                style={{ display: 'inline', marginRight: vars.spacing.xs }}
              />
            )}
            {name}
            <Text
              c="dark.3"
              fw={500}
              size="0.9em"
              span
            >{`, ${variation || translate('common.standard')}`}</Text>
            {isUnilateral && (
              <Text c="violet" size="0.7em" span>
                &nbsp;{`(${translate('exercise.unilateral')})`}
              </Text>
            )}
          </Text>
        );
      }}
      rightSection={
        <CloseButton
          c="red"
          color="red"
          onClick={onClear}
          size="lg"
          style={{ pointerEvents: 'auto', zIndex: 3 }}
          type="button"
          variant="subtle"
        />
      }
      searchable={true}
      size="lg"
      style={{ width: '100%', zIndex: 100 }}
      styles={{
        input: {
          backgroundColor: 'transparent',
          color: styleVariant === 'dark' ? '#fff' : '#000',
        },
      }}
    ></Select>
  );
};
