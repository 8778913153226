import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosError } from 'axios';

import { ApiErrorType } from '../constants/errors/api-errors';
import { refreshSession, setUser } from '../features/user/utils/cognito';
import { getApiErrorCode } from '../utils/errors';
import toast from '../utils/toast';
import envs from './envs';

export type ApiResponse<T> = T;

export type ApiResponseError = AxiosError<
  unknown,
  { code: ApiErrorType; details?: any; message: string }
>;

const headers = {
  'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
  baseURL: envs.apiBaseUrl,
  headers,
});

axiosInstance.interceptors.request.use(async config => {
  const session = await fetchAuthSession();
  const token = session.tokens?.idToken;
  if (token !== undefined) {
    config.headers.Authorization = `Bearer ${token.toString()}`;
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  async (error: AxiosError) => {
    toast.apiError(error);
    const originalRequest = error.config;
    if (
      getApiErrorCode(error) === 'AuthTokenExpired' &&
      // @ts-ignore
      !originalRequest._retry
    ) {
      // @ts-ignore
      originalRequest._retry = true;

      try {
        await refreshSession();
      } catch (err) {
        // @ts-ignore
        delete originalRequest.headers.Authorization;
        setUser(null, true);
      }
      // @ts-ignore
      return axiosInstance(originalRequest);
    } else if (error?.response?.status === 401) {
      // @ts-ignore
      if (originalRequest?._retry === true) {
        setUser(null, true);
      }
    }

    return Promise.reject(error);
  },
);

// const apiClient = createHttpApiClient({
//   baseURL: envs.apiBaseUrl,
//   getAuthToken: () =>
//     fetchAuthSession().then(
//       session => session.tokens?.idToken?.toString() ?? '',
//     ),
//   getFreshToken: () =>
//     fetchAuthSession({ forceRefresh: true }).then(
//       session => session.tokens?.idToken?.toString() ?? '',
//     ),
//   logOutUser: async () => setUser(null, true),
//   onError: async error => {
//     toast.apiError(error);
//   },
//   onNotAuthorized: () => {},
// });

// export { apiClient };

const http = axiosInstance;

export default http;
