import {
  Anchor,
  Button,
  Group,
  Indicator,
  Skeleton,
  Text,
  Tooltip,
} from '@mantine/core';
import { IdentityList, UserStatus } from '@strenco/api';
import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getIsNavItemActive } from '../../../router/helpers/navigation';
import { vars } from '../../../theme';
import UserAvatar from '../UserAvatar';
import classes from './styles.css';

interface Props extends IdentityList {
  id?: string;
  indicator?: {
    color: string;
    label: ReactNode;
    processing?: boolean;
    tooltip: string;
    withBorder?: boolean;
  };
  isActive?: boolean;
  status: UserStatus;
  to: string;
}

const DrawerNavItemUser: FC<Props> = ({
  avatarUrl,
  firstName,
  indicator,
  isActive,
  lastName,
  to,
}) => {
  const { pathname } = useLocation();

  const isCurrent =
    isActive === undefined ? getIsNavItemActive(pathname, to, []) : isActive;

  const avatar = (
    <UserAvatar
      color="branding"
      firstName={firstName}
      lastName={lastName}
      radius="md"
      size="sm"
      src={avatarUrl}
      variant={isCurrent ? 'filled' : 'light'}
    />
  );

  return (
    <Anchor
      aria-current={isCurrent ? 'page' : false}
      className={classes.anchor}
      component={Link}
      to={to}
    >
      <Group gap="sm" justify="start" py={4}>
        {indicator !== undefined ? (
          <Tooltip label={indicator.tooltip}>
            <Indicator
              color={indicator.color}
              label={indicator.label}
              offset={2}
              processing={indicator.processing}
              size={10}
              styles={{
                indicator: { borderWidth: indicator.withBorder ? 1 : 0 },
              }}
              withBorder={indicator.withBorder}
            >
              {avatar}
            </Indicator>
          </Tooltip>
        ) : (
          avatar
        )}
        <Text fw="500" size="sm">
          {`${firstName} ${lastName}`}
        </Text>
      </Group>
    </Anchor>
  );
};

export const DrawerNavItemUserSkeleton = () => (
  <Button
    component="div"
    fullWidth={true}
    justify="start"
    leftSection={
      <UserAvatar color="white" radius="xl" size="sm" variant="light" />
    }
    px={0}
    size="md"
    variant="transparent"
  >
    <Skeleton h={vars.fontSizes.md} opacity={0.1} w={145} />
  </Button>
);

export default DrawerNavItemUser;
