import { Group, Portal, rem } from '@mantine/core';
import { FC } from 'react';

import { ActionIconButton } from '../../../../components/common/ActionIcons';
import HeaderMobile from '../../../../components/layout/HeaderMobile';
import SearchInputUrl from '../../../../components/ui/forms/SearchInputUrl';
import { FieldId } from '../../../../constants/forms/form-fields';
import { PORTAL__HEADER_ACTIONS } from '../../../../constants/portals';
import useIsMobile from '../../../../hooks/useIsMobile';
import { translate } from '../../../../i18n';
import { getFieldPlaceholder } from '../../../../i18n/helpers/forms';
import { SearchParam } from '../../../../router/types';
import { ModalId, useModal } from '../../../../utils/modal';

const RoutineTemplatesRootLayout: FC = () => {
  const isMobile = useIsMobile();

  const { openModal } = useModal();

  const requestTemplateRoutineCreate = () => {
    openModal(ModalId.TrainingProgramDetails, {
      [SearchParam.ClientId]: 'template',
      [SearchParam.EntityId]: null,
    });
  };

  if (isMobile) {
    return (
      <HeaderMobile
        Action={() => (
          <ActionIconButton
            icon="plus"
            onClick={requestTemplateRoutineCreate}
            size="lg"
            variant="transparent"
          />
        )}
        title={translate('routine.template.label')}
        withBackButton={true}
      />
    );
  }

  return (
    <Portal target={PORTAL__HEADER_ACTIONS}>
      <Group flex={1}>
        <SearchInputUrl
          flex={1}
          placeholder={getFieldPlaceholder(FieldId.TemplateSearch)}
          w={rem(300)}
        />
      </Group>
    </Portal>
  );
};

export default RoutineTemplatesRootLayout;
