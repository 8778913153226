const translationsAria = {
  add_bodyweight: 'Add bodyweight',
  add_check_in: 'Add new check in',
  breadcrumb: 'breadcrumb',
  change_exercise: 'Click to change exercise',
  edit_avatar: 'Change Avatar Image',
  sets_builder_form: 'Sets Builder',
  show_chart_view: 'Show chart view',
  show_list_view: 'Show list view',
  user_status: `{{name}}'s status: {{status}}`,
  workout_table_set_row_form: 'Series {{blockLabel}}, Set {{setOrder}}',
  workout_table_set_row_form_new_set:
    'Create new set for Series {{blockLabel}}, Set {{setOrder}}',
  workout_table_set_row_parameter:
    'Series {{blockLabel}}, Set {{setOrder}} {{parameter}}',
};

export default translationsAria;
