import { UserTrainingProgramStatus } from '@strenco/api';

const translationsRoutines = {
  actions: 'Routine Actions',
  add: 'Add Routine',
  cycle_add: 'Add Cycle',
  cycle_delete: 'Delete Cycle',
  cycle_duplicate: 'Duplicate Cycle',
  cycle_edit: 'Edit Cycle',
  cycle_move: 'Reorder this cycle',
  cycleEmpty: 'No workouts available for this cycle',
  cycles: 'Cycles',
  cycles_one: 'Cycle',
  cycles_other: '{{count}} cycles',
  delete: 'Delete Program',
  edit: 'Edit Program',
  generate: 'Generate Client Programs',
  next_up: 'Next Up!',
  routineCycles: 'Program Cycles',
  routines: 'Programs',
  routines_one: 'Program',
  routines_other: '{{count}} Programs',
  routines_status_zero: 'No programs in this category',
  routines_zero: 'No programs',
  status: {
    [UserTrainingProgramStatus.Completed]: {
      description:
        'The programs are fully completed. Visible for the clients (coming soon: option to hide them from clients).',
      label: 'Completed',
      title: 'Completed',
    },
    [UserTrainingProgramStatus.Draft]: {
      description:
        'The programs are only visible here until you move them to ready or in progress.',
      label: 'Draft',
      title: 'Draft',
    },
    [UserTrainingProgramStatus.InProgress]: {
      description:
        'The programs in progress are always visible in the mobile app.',
      label: 'In Progress',
      title: 'Programs',
    },
    [UserTrainingProgramStatus.Ready]: {
      description:
        'After completing a program, the next one will automatically move up to "In Progress" (coming soon: option to hide them from clients).',
      label: 'Ready',
      title: 'Ready to Go',
    },
  },
  template: {
    create: 'Create Program Template',
    delete: 'Delete Program Template',
    info: 'Program Template info',
    label: 'Program Templates',
    many: 'Template Workouts',
    search_zero: 'No results',
    search_zero_subtitle: 'No template workout found.',
    zero: 'No workout templates available.',
    zero_subtitle: 'Create a template to create programs quicker',
  },
  template_empty_data: {
    cta: 'Create New Program Template',
    description:
      'You can create new ones to help structure and speed up your program design.',
    title: 'No Program Templates Available',
  },
  template_empty_search: {
    cta: 'Create New Program Template',
    description:
      'No programs matching your search. Try searching with different keywords or',
    title: 'No Program Templates Found',
  },
  totalWorkouts: 'Total workouts: {{count}}',
  workouts_compare: 'Compare workouts',
};

export default translationsRoutines;
