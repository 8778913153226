import { ButtonId } from '../../constants/button-ids';

interface Props {
  label: string;
  title: string;
}

const translationsButtons: Record<ButtonId, Props> = {
  [ButtonId.Actions]: {
    label: 'Actions',
    title: 'Actions',
  },
  [ButtonId.Apply]: {
    label: 'Apply',
    title: 'Apply changes',
  },
  [ButtonId.Back]: {
    label: 'Back',
    title: 'Go back to the previous page',
  },
  [ButtonId.Cancel]: {
    label: 'Cancel',
    title: 'Cancel the current action',
  },
  [ButtonId.ClientCreate]: {
    label: 'Create Client',
    title: 'Create a new client',
  },
  [ButtonId.ClientDelete]: {
    label: 'Delete Client',
    title: 'Delete the selected client',
  },
  [ButtonId.ClientEdit]: {
    label: 'Edit Client',
    title: 'Edit Client',
  },
  [ButtonId.Close]: {
    label: 'Close',
    title: 'Close',
  },
  [ButtonId.CommentAdd]: {
    label: 'Add New Comment',
    title: 'Add Comment',
  },
  [ButtonId.CommentDelete]: {
    label: 'Delete Comment',
    title: 'Delete comments',
  },
  [ButtonId.CommentsAdd]: {
    label: 'Add Comments',
    title: 'Add Comment',
  },
  [ButtonId.Continue]: {
    label: 'Continue',
    title: 'Continue with the current action',
  },
  [ButtonId.Create]: {
    label: 'Create',
    title: 'Create',
  },
  [ButtonId.CycleCreate]: {
    label: 'Create Cycle',
    title: 'Create a new cycle',
  },
  [ButtonId.CycleDelete]: {
    label: 'Delete Cycle',
    title: 'Delete this cycle',
  },
  [ButtonId.CycleEdit]: {
    label: 'Edit Cycle',
    title: 'Edit this cycle',
  },
  [ButtonId.Delete]: {
    label: 'Delete',
    title: 'Delete',
  },
  [ButtonId.Duplicate]: {
    label: 'Duplicate',
    title: 'Duplicate',
  },
  [ButtonId.Edit]: {
    label: 'Edit',
    title: 'Edit',
  },
  [ButtonId.ExerciseChange]: {
    label: 'Change Exercise',
    title: 'Change exercise',
  },
  [ButtonId.ExerciseCreate]: {
    label: 'Create Exercise',
    title: 'Create a new exercise',
  },
  [ButtonId.ExerciseDelete]: {
    label: 'Delete Exercise',
    title: 'Delete this exercise',
  },
  [ButtonId.ExerciseEdit]: {
    label: 'Edit Exercise',
    title: 'Edit this exercise',
  },
  [ButtonId.GoToHome]: {
    label: 'Go to home',
    title: 'Navigate back to home',
  },
  [ButtonId.Logout]: {
    label: 'Logout',
    title: 'Logout from the system',
  },
  [ButtonId.Move]: {
    label: 'Move',
    title: 'Move',
  },
  [ButtonId.PasswordChange]: {
    label: 'Change Password',
    title: 'Change your password',
  },
  [ButtonId.PasswordForgot]: {
    label: 'Submit',
    title: 'This action will send a confirmation link.',
  },
  [ButtonId.PasswordReset]: {
    label: 'Reset Password',
    title: 'You will receive an email to reset your password',
  },
  [ButtonId.ResendVerificationCode]: {
    label: 'Resend Code',
    title: 'You will receive a new verification code',
  },
  [ButtonId.Reset]: {
    label: 'Reset',
    title: 'Reset',
  },
  [ButtonId.RoutineCreate]: {
    label: 'Create Program',
    title: 'Create a new program',
  },
  [ButtonId.RoutineDelete]: {
    label: 'Delete Program',
    title: 'Delete this program',
  },
  [ButtonId.RoutineEdit]: {
    label: 'Edit Program',
    title: 'Edit this program',
  },
  [ButtonId.RoutineInfo]: {
    label: 'Program Info',
    title: 'View information about the program',
  },
  [ButtonId.RoutineMove]: {
    label: 'Move Program',
    title: 'Move this program',
  },
  [ButtonId.Save]: {
    label: 'Save',
    title: 'Save changes',
  },
  [ButtonId.SeriesCreate]: {
    label: 'Create Series {{value}}',
    title: 'Will add a new series',
  },
  [ButtonId.SetAdd]: {
    label: 'Add Set',
    title: 'Add a new set',
  },
  [ButtonId.SetsBuilder]: {
    label: 'Sets Builder',
    title: 'Open Sets Builder',
  },
  [ButtonId.SignIn]: {
    label: 'Sign In',
    title: 'Sign in to your account',
  },
  [ButtonId.SignUp]: {
    label: 'Register',
    title: 'Create a new account',
  },
  [ButtonId.Submit]: {
    label: 'Submit',
    title: 'Submit the form',
  },
  [ButtonId.TemplateRoutineCreate]: {
    label: 'Create Program Template',
    title: 'Create a new program template',
  },
  [ButtonId.TemplateWorkoutCreate]: {
    label: 'Create Template Workout',
    title: 'Create a new template workout',
  },
  [ButtonId.UpdatePlan]: {
    label: 'Update Plan',
    title: '',
  },
  [ButtonId.WorkoutAdd]: {
    label: 'Add Workout',
    title: 'Add a workout to the program',
  },
  [ButtonId.WorkoutBuilder]: {
    label: 'Workout Builder',
    title: 'Open in Workout Builder',
  },
  [ButtonId.WorkoutCompare]: {
    label: 'Compare Workout',
    title: '',
  },
  [ButtonId.WorkoutCreate]: {
    label: 'Create Workout',
    title: 'Create new workout',
  },
  [ButtonId.WorkoutDelete]: {
    label: 'Delete Workout',
    title: 'Delete this workout',
  },
  [ButtonId.WorkoutDuplicate]: {
    label: 'Duplicate Workout',
    title: 'Duplicate this Workout',
  },
  [ButtonId.WorkoutEdit]: {
    label: 'Edit Workout',
    title: 'Edit this workout',
  },
  [ButtonId.WorkoutPrepare]: {
    label: 'Prepare Workout',
    title: 'Prepare Workout',
  },
} as const;

export default translationsButtons;
