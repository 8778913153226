import { UserType } from '@strenco/api';

const coachAndBusiness = new Set([
  UserType.BusinessAdmin,
  UserType.BusinessCoach,
  UserType.BusinessOwner,
  UserType.Coach,
]);

const subscriptions = new Set([
  UserType.BusinessOwner,
  UserType.Coach,
  UserType.Personal,
]);

const businessEditors = new Set([
  UserType.BusinessAdmin,
  UserType.BusinessOwner,
]);

const protectedRoutes = {
  business: businessEditors,
  businessClients: businessEditors,
  businessCoaches: businessEditors,
  businessFacilities: businessEditors,
  clients: coachAndBusiness,
  settingsBusiness: new Set([UserType.BusinessOwner]),
  settingsSubscriptions: subscriptions,
  templateRoutines: coachAndBusiness,
};

export default protectedRoutes;
