import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DrawerSidebarHeader,
  DrawerSidebarScrollArea,
} from '../../../../../components/common/DrawerComponents';
import DrawerNavGroup from '../../../../../components/common/DrawerNavGroup';
import DrawerSidebar from '../../../../../components/common/DrawerSidebar';
import useScrollActiveLinkIntoView from '../../../../../hooks/useScrollCurrentLinkIntoView';
import { getPageLabel } from '../../../../../i18n/helpers/page';
import { useHashWorkoutId } from '../../../../../router/hooks/hash';
import { useParamWorkoutId } from '../../../../../router/hooks/path-params';
import useCurrentPath from '../../../../../router/hooks/useCurrentPath';
import { useTrainingProgramById } from '../../../hooks/training-program';
import BackButton from './BackButton';
import { getRoutineWorkoutsSidebarItems } from './helpers';
import { RoutineSecondaryNavigationProps } from './types';

const RoutineWorkouts = () => {
  const currentPath = useCurrentPath();
  const location = useLocation();
  const hashWorkoutId = useHashWorkoutId();
  const paramWorkoutId = useParamWorkoutId();
  const data = useTrainingProgramById();

  useScrollActiveLinkIntoView();

  if (!data) return null;

  return data.trainingProgram.cyclesOrder.map(cycleId => {
    const cycle = data.cycles[cycleId];
    if (!cycle) {
      return null;
    }

    return (
      <DrawerNavGroup
        items={getRoutineWorkoutsSidebarItems(
          currentPath,
          cycle.workoutsOrder,
          data.workouts,
          hashWorkoutId,
          paramWorkoutId,
          location,
        )}
        key={cycleId}
        label={cycle.cycle.name}
      />
    );
  });
};

const RoutineFullSecondaryNavigation: FC<RoutineSecondaryNavigationProps> = ({
  pageId,
}) => {
  const data = useTrainingProgramById();
  const [scrolled, setScrolled] = useState(false);

  return (
    <DrawerSidebar title={getPageLabel(pageId)}>
      <DrawerSidebarHeader scrolled={scrolled}>
        <BackButton />
      </DrawerSidebarHeader>
      <DrawerSidebarScrollArea onScrolled={setScrolled}>
        <div>
          {data === undefined ? (
            <>
              <DrawerNavGroup isLoading={true} label="" />
              <DrawerNavGroup isLoading={true} label="" />
              <DrawerNavGroup isLoading={true} label="" />
            </>
          ) : (
            <RoutineWorkouts />
          )}
        </div>
      </DrawerSidebarScrollArea>
    </DrawerSidebar>
  );
};

export default RoutineFullSecondaryNavigation;
