import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../constants/page-ids';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../i18n/helpers/page';
import { useParamClientId } from '../../../router/hooks/path-params';
import Layout from './layout';
import { PrefetchTraineeFull } from './prefetch';

const Content = lazy(() => import('./content'));

interface Props {
  clientId?: string;
}

const ClientFullPage: FC<Props> = ({ clientId }) => {
  const traineeId = useParamClientId() ?? clientId;
  useDocumentTitle(getPageLabel(PageId.ClientsDetails));

  return (
    <>
      <PrefetchTraineeFull traineeId={traineeId} />
      <Layout clientId={traineeId} />
      <Suspense fallback={null}>
        <Content clientId={traineeId} />
      </Suspense>
    </>
  );
};

export default ClientFullPage;
