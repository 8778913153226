import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../constants/page-ids';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../i18n/helpers/page';
import Layout from './layout';
import Prefetch from './prefetch';

const Content = lazy(() => import('./content'));

const ClientsRootPage: FC = () => {
  useDocumentTitle(getPageLabel(PageId.Clients));

  return (
    <>
      <Prefetch />
      <Layout />
      <Suspense fallback={null}>
        <Content />
      </Suspense>
    </>
  );
};

export default ClientsRootPage;
