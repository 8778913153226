import { useTrainingProgramFull } from '@strenco/api';

import { BreadcrumbItem } from '../../../components/common/Breadcrumbs';
import { PageId } from '../../../constants/page-ids';
import { translate } from '../../../i18n';
import { getPageLabel } from '../../../i18n/helpers/page';
import {
  getBreadcrumbWorkoutLabel,
  getBreadcrumbWorkoutName,
} from '../../../router/breadcrumbs/helpers';
import {
  useParamRoutineId,
  useParamWorkoutId,
} from '../../../router/hooks/path-params';
import { routes } from '../../../router/routes';

const useBreadcrumbs = (): BreadcrumbItem[] => {
  const routineId = useParamRoutineId();
  const workoutId = useParamWorkoutId();

  const { data } = useTrainingProgramFull(routineId);

  const items: BreadcrumbItem[] = [
    {
      content: getPageLabel(PageId.TemplateRoutines),
      href: routes.library.routines.root(),
    },
  ];

  if (routineId !== null) {
    items.push({
      content: data?.trainingProgram.program.name,
      href: routes.library.routines.byId(routineId),
      isLoading: !data,
      label: translate('routine.template.label'),
    });

    if (workoutId !== null) {
      const workout = data?.workouts[workoutId];
      items.push({
        content: getBreadcrumbWorkoutName(workout?.workout),
        href: routes.library.routines.workouts(routineId, workoutId),
        isLoading: !workout,
        label: getBreadcrumbWorkoutLabel(data, workoutId),
      });
    }
  }

  return items;
};

export default useBreadcrumbs;
