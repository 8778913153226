import { FC } from 'react';

import { ActionIconButton } from '../../../../../components/common/ActionIcons';
import CustomMenu from '../../../../../components/ui/menus/CustomMenu';
import { translate } from '../../../../../i18n';
import { ModalId, useModal } from '../../../../../utils/modal';
import { isBusinessAdmin, isBusinessOwner } from '../../../../user/utils';
import { getUserCreateMenuItems } from '../helpers';

const BusinessHeaderActions: FC = () => {
  const { openModal } = useModal();

  if (isBusinessAdmin() === false && isBusinessOwner() === false) {
    return null;
  }

  const itemsUserCreate = getUserCreateMenuItems(openModal);

  const requestFacilityCreate = () => {
    openModal(ModalId.BusinessFacilityCreate);
  };

  itemsUserCreate.push({
    label: translate('business.facilities_create'),
    onClick: requestFacilityCreate,
  });

  return (
    <CustomMenu
      menuItems={itemsUserCreate}
      trigger={<ActionIconButton colorVariant="primary" icon="plus" />}
    />
  );
};

export default BusinessHeaderActions;
