import { EXERCISE_CATEGORY_ORDER } from '../../../constants/preferences';
import {
  isBusinessAdmin,
  isBusinessCoach,
  isBusinessOwner,
} from '../../user/utils';

export const getCategoriesToShow = () => {
  const canViewCompanyExercises =
    isBusinessAdmin() || isBusinessCoach() || isBusinessOwner();

  const categoriesToShow = EXERCISE_CATEGORY_ORDER.filter(category => {
    if (canViewCompanyExercises === false && category === 'business') {
      return false;
    }

    return true;
  });

  return categoriesToShow;
};
