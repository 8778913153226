import { EntityUserProvider, TrainingProgramProvider } from '@strenco/api';
import { FC, lazy, Suspense } from 'react';

import { PageId } from '../../../constants/page-ids';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getPageLabel } from '../../../i18n/helpers/page';
import {
  useParamClientId,
  useParamRoutineId,
} from '../../../router/hooks/path-params';
import Layout from './layout';
import { PrefetchTrainingProgramFull } from './prefetch';

const Content = lazy(() => import('./content'));

interface Props {
  clientId?: null | string;
}

const RoutineFullPage: FC<Props> = ({ clientId }) => {
  const userId = useParamClientId() ?? clientId;
  const trainingProgramId = useParamRoutineId();

  useDocumentTitle(getPageLabel(PageId.ClientsRoutineDetails));

  if (!userId || !trainingProgramId) {
    return null;
  }

  return (
    <TrainingProgramProvider id={trainingProgramId} type="user">
      <EntityUserProvider id={userId}>
        <PrefetchTrainingProgramFull />
        <Layout />
        <Suspense fallback={null}>
          <Content />
        </Suspense>
      </EntityUserProvider>
    </TrainingProgramProvider>
  );
};

export default RoutineFullPage;
