import { ComboboxData, ComboboxItem } from '@mantine/core';
import { BusinessCoachRank, BusinessFull } from '@strenco/api';

import { translate } from '../../../i18n';
import { getFullName } from '../../../utils/users';
import { getUser } from '../../user/api/cache/getters';
import { isBusinessAdmin, isBusinessOwner } from '../../user/utils';

export const getFacilitySelectData = (
  business: BusinessFull | undefined,
  filter: number[] = [],
): ComboboxData => {
  const user = getUser();

  if (business === undefined || user === null) {
    return [];
  }

  const facilitiesFilter = new Set(filter);

  const facilities =
    facilitiesFilter.size === 0
      ? business.facilities
      : business.facilities.filter(facility =>
          facilitiesFilter.has(facility.id),
        );

  return facilities.map(facility => ({
    label: facility.name,
    value: facility.id.toString(),
  }));
};

export const getCoachSelectData = (
  business: BusinessFull | undefined,
): ComboboxData => {
  const user = getUser();

  if (business === undefined || user === null) {
    return [];
  }

  const options: ComboboxItem[] = [];

  for (const coach of business.coaches ?? []) {
    options.push({ label: getFullName(coach), value: coach.id });
  }

  return options;
};

export const getAdminSelectData = (
  business: BusinessFull | undefined,
): ComboboxData => {
  const user = getUser();

  if (business === undefined || user === null) {
    return [];
  }

  return (
    business.admins?.map(admin => ({
      label: getFullName(admin),
      value: admin.id,
    })) ?? []
  );
};

export const getCoachRankSelectData = (
  ranks: BusinessCoachRank[] | undefined,
): ComboboxData => {
  const user = getUser();

  if (ranks === undefined || user === null) {
    return [];
  }

  return ranks.map(rank => ({
    label: `${rank.name} (${translate('business.coach_level')} ${rank.level})`,
    value: rank.id.toString(),
  }));
};

export const getBusinessQueryOptions = () => ({
  enabled: isBusinessAdmin() || isBusinessOwner(),
});

export const getUserMemberOfFacilities = (
  userId: null | string | undefined,
  business: BusinessFull | undefined,
) => {
  const facilityIds: number[] = [];

  for (const facility of business?.facilities ?? []) {
    const isMember = facility.members.some(m => m.userId === userId);
    if (isMember) facilityIds.push(facility.id);
  }

  return facilityIds;
};
