import { MantineColor } from '@mantine/core';
import {
  CoachingFormat,
  UserTrainingProgramStatus,
  WorkoutStatus,
} from '@strenco/api';

import { vars } from '../../../theme';

export const ROUTINE__STATUS_ORDERED = [
  UserTrainingProgramStatus.InProgress,
  UserTrainingProgramStatus.Ready,
  UserTrainingProgramStatus.Draft,
  UserTrainingProgramStatus.Completed,
];

export const ROUTINE__STATUS_ORDERED_READY_EXCLUDED = [
  UserTrainingProgramStatus.InProgress,
  UserTrainingProgramStatus.Draft,
  UserTrainingProgramStatus.Completed,
];

const COLOR__COMPLETED = vars.colors.green[4];
const COLOR__IN_PROGRESS = vars.colors.branding[8];
const COLOR__NO_STATUS = vars.colors.dark[4];

export const ROUTINE__STATUS_COLORS: Record<
  UserTrainingProgramStatus,
  MantineColor
> = {
  [UserTrainingProgramStatus.Completed]: COLOR__COMPLETED,
  [UserTrainingProgramStatus.Draft]: COLOR__NO_STATUS,
  [UserTrainingProgramStatus.InProgress]: COLOR__IN_PROGRESS,
  [UserTrainingProgramStatus.Ready]: vars.colors.orange[6],
};

export const CLIENT_WORKOUT__STATUS_COLORS: Record<
  WorkoutStatus,
  MantineColor
> = {
  Completed: COLOR__COMPLETED,
  CompletedWithMissingData: vars.colors.yellow[8],
  InProgress: COLOR__IN_PROGRESS,
  None: COLOR__NO_STATUS,
  Skipped: vars.colors.violet[6],
};

export const COACHING_FORMAT__ORDER = [
  CoachingFormat.Virtual,
  CoachingFormat.FaceToFace,
  CoachingFormat.Blended,
];
