import { ConversionType, Difficulty, Gender, Goal } from '@strenco/api';

const difficulty: Record<Difficulty, { label: string }> = {
  [Difficulty.AdvancedEasy]: { label: 'Advanced Easy' },
  [Difficulty.AdvancedHard]: { label: 'Advanced Hard' },
  [Difficulty.AdvancedMedium]: { label: 'Advanced Medium' },
  [Difficulty.BeginnerEasy]: { label: 'Beginner Easy' },
  [Difficulty.BeginnerHard]: { label: 'Beginner Hard' },
  [Difficulty.BeginnerMedium]: { label: 'Beginner Medium' },
  [Difficulty.IntermediateEasy]: { label: 'Intermediate Easy' },
  [Difficulty.IntermediateHard]: { label: 'Intermediate Hard' },
  [Difficulty.IntermediateMedium]: { label: 'Intermediate Medium' },
};

const goal: Record<Goal, { description: string; label: string }> = {
  [Goal.AbsoluteStrength]: {
    description:
      'The total maximum force a person can exert, regardless of body weight.',
    label: 'Absolute Strength',
  },
  [Goal.AnaerobicEndurance]: {
    description:
      'High-intensity, short-duration efforts relying on anaerobic energy systems.',
    label: 'Anaerobic Endurance',
  },
  [Goal.CardiovascularEndurance]: {
    description:
      'Focus on aerobic capacity and stamina for sustained activity.',
    label: 'Cardiovascular Endurance',
  },
  [Goal.DynamicStrength]: {
    description:
      'Improving strength during continuous movement, often used in sports and functional training.',
    label: 'Dynamic Strength',
  },
  [Goal.ElasticStrength]: {
    description:
      'Plyometric strength, essential for explosive movements and sports involving jumping.',
    label: 'Elastic Strength',
  },
  [Goal.ExplosiveStrength]: {
    description:
      'Maximum force exertion in the shortest time, crucial for sprinting and powerlifting.',
    label: 'Explosive Strength',
  },
  [Goal.FunctionalStrength]: {
    description: 'Strength that enhances daily tasks and movement efficiency.',
    label: 'Functional Strength',
  },
  [Goal.GeneralBodyComp]: {
    description: 'Improving muscle-to-fat ratio and overall body composition.',
    label: 'Body Composition',
  },
  [Goal.GeneralBodyFatReduction]: {
    description:
      'Reducing body fat percentage for health, aesthetics, and performance.',
    label: 'Body Fat Reduction',
  },
  [Goal.GeneralEndurance]: {
    description:
      'Enhancing both aerobic and anaerobic endurance for sustained performance.',
    label: 'Endurance',
  },
  [Goal.GeneralFitness]: {
    description: 'A holistic approach to improving overall fitness levels.',
    label: 'General Fitness',
  },
  [Goal.GeneralFlexibility]: {
    description:
      'Enhancing joint mobility and muscle elasticity to prevent injuries.',
    label: 'Flexibility',
  },
  [Goal.GeneralHypertrophy]: {
    description:
      'Building muscle mass through resistance training and progressive overload.',
    label: 'Hypertrophy',
  },
  [Goal.GeneralPower]: {
    description:
      'Developing explosive strength for fast and forceful movements.',
    label: 'Power',
  },
  [Goal.GeneralPrehab]: {
    description:
      'Exercises designed to prevent injuries and strengthen weak points.',
    label: 'Prehab',
  },
  [Goal.GeneralRecovery]: {
    description:
      'Optimizing rest, nutrition, and mobility to aid post-training recovery.',
    label: 'Recovery',
  },
  [Goal.GeneralRehab]: {
    description: 'Restoring movement and strength after an injury.',
    label: 'Rehab',
  },
  [Goal.GeneralStrength]: {
    description:
      'Increasing overall ability to exert force through resistance training.',
    label: 'Strength',
  },
  [Goal.MaximalStrength]: {
    description: 'The highest force output possible in a single effort.',
    label: 'Maximal Strength',
  },
  [Goal.MuscularEndurance]: {
    description:
      'Sustaining muscle contractions over extended periods or repetitions.',
    label: 'Muscular Endurance',
  },
  [Goal.RelativeStrength]: {
    description:
      'Strength in relation to body weight, crucial for weight-class athletes.',
    label: 'Relative Strength',
  },
  [Goal.SpeedEndurance]: {
    description: 'Maintaining high-speed performance over extended durations.',
    label: 'Speed Endurance',
  },
  [Goal.SpeedStrength]: {
    description:
      'Generating force quickly with moderate weights, important for agility.',
    label: 'Speed Strength',
  },
  [Goal.StartingStrength]: {
    description:
      'Generating force from a dead stop, crucial for initial lift power.',
    label: 'Starting Strength',
  },
  [Goal.StrengthEndurance]: {
    description:
      'Sustaining strength output over multiple repetitions or sets.',
    label: 'Strength Endurance',
  },
};

const translationsCommon = {
  accomplished: 'Accomplished',
  add: 'Add',
  all_time: 'All Time',
  back: 'Back',
  bodyweight: 'Bodyweight',
  changeStatus: 'Change Status',
  checkout: 'Checkout',
  clients_action_needed: 'Clients Action Needed',
  clients_action_no_routines_in_progress: 'No routine in progress',
  close: 'Close',
  comments_for: 'Comments for {{value}}',
  compare: 'Compare',
  completed_at: 'Completed at',
  completedAt: 'Completed at',
  continue: 'Continue',
  conversion: {
    action: 'Create using a template',
    [ConversionType.ProgramTemplateToUserProgram]: {
      info: 'Training programs will be created for selected clients',
      title: 'Generate Program For Users',
    },
    [ConversionType.ProgramWorkoutToStandaloneWorkoutTemplate]: {
      info: 'A standalone workout template will be created.',
      title: 'Generate Workout Template',
    },
    [ConversionType.StandaloneWorkoutTemplateToProgramWorkout]: {
      info: 'Selected workouts will be generated for this program cycle.',
      title: 'Generate Workout',
    },
    [ConversionType.UserProgramToProgramTemplate]: {
      info: 'A training program template will be created.',
      title: 'Generate template',
    },
  },
  created_at: 'Created At',
  dates: {
    annually: 'Annually',
    days: 'days',
    days_ago_one: 'a day ago',
    days_ago_other: '{{count}} days ago',
    days_ago_zero: 'today',
    days_one: '1 Day',
    days_other: '{{count}} days',
    hours: 'hours',
    hours_in_one: 'in 1h',
    hours_in_other: 'in {{count}}h',
    hours_in_zero: 'now',
    month: 'month',
    month_short: 'mo',
    monthly: 'Monthly',
    today: 'today',
  },
  delete: 'Delete',
  delete_value: 'Delete {{value}}',
  difficulty: { ...difficulty, label: 'Difficulty' },
  dismiss: 'Dismiss',
  duplicate: 'Duplicate',
  duplicate_value: 'Duplicate {{value}}',
  duration: 'Duration',
  edit: 'Edit',
  emptyComments: 'No comments added yet!',
  emptyDefault: 'Nothing found',
  ended_at: 'Ended at',
  ends_at: 'Ends at',
  errors: {
    general: {
      title: 'Something went wrong',
    },
  },
  export: 'Export',
  gender: {
    [Gender.Female]: { label: 'Female' },
    [Gender.Male]: { label: 'Male ' },
  },
  gif: 'Gif',
  goal: { ...goal, label_one: 'Goal', label_other: 'Goals' },
  import: 'Import',
  info: 'Info',
  learn_more: 'Learn More',
  max: 'Max',
  media: 'Media',
  message_offline: `Oops! It looks like there's no internet connection.`,
  move: 'Move',
  move_to: 'Move to',
  myself: 'Me',
  name: 'Name',
  none: 'None',
  of_double: '{{value1}} of {{value2}}',
  other: 'Other',
  position: 'Position {{value}}',
  prepare: 'Prepare',
  profile_edit: 'Edit Profile',
  provided: 'Provided',
  quick_actions: 'Quick Actions',
  records_zero: 'No records.',
  replace: 'Replace',
  results: 'Results',
  results_edit: 'Edit results',
  search_templates: 'Search templates',
  select: 'Select',
  settings: 'Settings',
  shift_to: 'Shift to {{value}}',
  show_less: 'Show Less',
  show_more: 'Show More',
  skipped: 'Skipped',
  standard: 'Standard',
  started_at: 'Started at',
  status: 'Status',
  status_change_to: 'Change status to',
  summary: 'Summary',
  template_create: 'Create Template',
  templates: 'Templates',
  thumbnail: 'Thumbnail',
  tou: {
    label: 'Terms of Use',
    link: 'https://strenco.app/en/terms-of-use',
  },
  unselect: 'Unselect',
  updated_at: 'Updated At',
  users: 'Users',
  users_activate: 'Activate User',
  users_deactivate: 'Deactivate User',
  users_deactivate_first: 'To delete this user, you need to deactivate first.',
  users_delete: 'Delete User',
  users_edit: 'Edit User',
  users_info: 'User Info',
  video_personal: 'Personal video',
  weight: 'Weight',
};

export default translationsCommon;
