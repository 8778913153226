import {
  TrainingProgramTemplateStatus,
  UserTrainingProgramStatus,
} from '@strenco/api';

import { translate } from '..';

const infoMappings = {
  [UserTrainingProgramStatus.Completed]: 'training_program.status__completed__',
  [UserTrainingProgramStatus.Draft]: 'training_program.status__draft__',
  [UserTrainingProgramStatus.InProgress]:
    'training_program.status__in_progress__',
  [UserTrainingProgramStatus.Ready]: 'training_program.status__ready__',
} as const satisfies Record<UserTrainingProgramStatus, string>;

const info = 'info' as const;
const label = 'label' as const;
const tab = 'tab' as const;

export const getTrainingProgramStatusInfo = (
  status: UserTrainingProgramStatus,
) => {
  return translate(`${infoMappings[status]}${info}`);
};

export const getTrainingProgramStatusLabel = (
  status: UserTrainingProgramStatus,
) => {
  return translate(`${infoMappings[status]}${label}`);
};

export const getTrainingProgramStatusTab = (
  status: UserTrainingProgramStatus,
) => {
  return translate(`${infoMappings[status]}${tab}`);
};

const templateMappings = {
  [TrainingProgramTemplateStatus.Draft]:
    'training_program_template.status__draft__',
  [TrainingProgramTemplateStatus.Published]:
    'training_program_template.status__published__',
} as const satisfies Record<TrainingProgramTemplateStatus, string>;

export const getTrainingProgramTemplateStatusLabel = (
  status: TrainingProgramTemplateStatus,
) => {
  return translate(`${templateMappings[status]}${label}`);
};
