const tempoInfo = `Tempo is a specific pattern for the timing of different phases in an exercise (4,0,X,2). ${[
  'The first number (4) represents the eccentric phase, which is the lowering or resisting phase of the exercise. You should take 4 seconds to complete this part',
  'The second number (0) signifies that there is no pause or rest at the midpoint between the eccentric and concentric phases.',
  'The third number (X) represents the concentric phase, which is the lifting or pushing phase of the exercise. X means that you should execute the movement as fast as possible',
  'The fourth number (1) indicates that there is a one second pause at the midpoint between the concentric and eccentric phases.',
].join('. ')}`;

const translationsNew = {
  exercise: {
    not_deleted__info:
      'This exercise is used in some workouts. Please change exercise or delete the series that are using this exercise',
    not_deleted__title: 'The exercise was not deleted',
    unilateral: 'Unilateral',
    unilateral__short: 'Uni',
    volume_unit__distance__info: '',
    volume_unit__distance__label: 'Distance',
    volume_unit__reps__info:
      'Valid values include a single positive number or a range of positive numbers (e.g., "10" or "10-12").\n Numbers less than 1 are not allowed. Do not include units.',
    volume_unit__reps__label: 'Reps',
    volume_unit__time__info:
      'Valid values include a single positive number followed by an optional time unit (e.g., "5 min" or "30 sec"), or a range of such values separated by hyphens (e.g., "1 min - 3 min"). Numbers less than 1 are not allowed. Accepted time units are "min", "s", and "sec".',
    volume_unit__time__label: 'Time',
  },
  exercise_set: {
    actions__info: '',
    actions__label: 'Set Actions',
    bulk_editor: 'Bulk Editor',
    comments__info: 'Informative notes related to this set.',
    comments__label: 'Notes',
    delete: 'Delete this set',
    duplicate: 'Duplicate this set',
    effort__error:
      'Only values between 1 and 10 are allowed (including 0 and 10).',
    effort__info:
      'The effort it took to complete the given set, using Reps in Reserve for Reps, and Rate of Perceived effort for others.',
    effort__label: 'Effort',
    effort__rir_info:
      'Reps in reserve (RIR) is an expression of how many more reps you could complete after your last rep.',
    effort__rir_label: 'RIR',
    effort__rpe_info:
      'Rate of Perceived Exertion (RPE) is a way to express how hard or easy your workout feels to you. On a scale from 1 to 10, with 1 being very light effort and 10 being the most intense.',
    effort__rpe_label: 'RPE',
    info: 'Exercise info',
    label: 'Sets',
    label_one: 'Set',
    label_other: '{{count}} sets',
    optional__info:
      'If checked, this set will not be required in order to mark a workout as completed',
    optional__label: 'Opt',
    reorder: 'Reorder this set up/down',
    reps: 'Reps',
    rest__info: 'How much rest you have after completing the set',
    rest__label: 'Rest',
    result__info: '',
    result__label: 'Result',
    rm_max__info: 'Percentage of 1RM used for this set.',
    rm_max__label: 'RM%',
    sets_builder__no_group_selected:
      'Select at least one series to use the editor',
    sets_builder__sets_error:
      'Only range values (1-n) or comma separated sets (1,2,3...n) are allowed. Examples: "1,2..", "1-5", "1-3, 4, 5, 6-9"',
    sets_builder__sets_info: 'Enter set, range (1-5) or multiple (1,2,4,5...)',
    sets_builder__sets_label: 'Sets',
    sets_builder__sets_out_of_bounds:
      'When parameter filtering is active, you can only update existing sets.<br/>Sets out of bounds: <strong>{{sets}}</strong>, for groups: <strong>{{groups}}</strong>',
    tempo__error:
      'Tempo must contain 4 comma separated values. Please use integers or X for as fast as possible. Examples: "4,1,X,0", "30,0,30,0"...',
    tempo__info: tempoInfo,
    tempo__label: 'Tempo',
    volume__error:
      'Please enter a value for volume. You can use single integers or a range. Example: "10", "10 - 12", ...',
    volume__info:
      'Represents the amount of work performed. The amount can be specified using Reps, Time or Distance',
    volume__label: 'Volume',
    volume_unit__info:
      'Pick between reps, time and distance depending on what is supported by the exercise.',
    volume_unit__label: 'Volume Unit',
    weight__info:
      'Enter the target weight. When added, it will be preselected in the mobile app.',
    weight__label: 'Weight',
  },
  measure_system: {
    imperial__weight_short: 'lbs',
    metric__weight_short: 'kg',
  },
  time_unit: {
    hours_long: 'Hours',
    hours_short: 'h',
    minutes_long: 'Minutes',
    minutes_short: 'min',
    seconds_long: 'Seconds',
    seconds_short: 's',
  },
  training_program: {
    status__completed__info:
      'The routines are fully completed. Visible for the clients (coming soon: option to hide them from clients).',
    status__completed__label: 'Completed',
    status__completed__tab: 'Completed',
    status__draft__info:
      'The routines are only visible here until you move them to ready or in progress.',
    status__draft__label: 'Draft',
    status__draft__tab: 'Draft',
    status__in_progress__info:
      'The routines in progress are always visible in the mobile app.',
    status__in_progress__label: 'In Progress',
    status__in_progress__tab: 'Programs',
    status__ready__info:
      'After completing a routine, your next routine will automatically move up to "In Progress" (coming soon: option to hide them from clients).',
    status__ready__label: 'Ready',
    status__ready__tab: 'Ready to Go',
  },
  training_program_template: {
    status__draft__label: 'Draft',
    status__published__label: 'Published',
  },
};

export default translationsNew;
