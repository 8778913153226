import { QueryKey } from '@strenco/api';
import { useQuery } from '@tanstack/react-query';

import { queryClient } from '../../../../config/react-query';
import { getCurrentCognitoUser } from '../../utils/cognito';

export const useCognitoUser = () => {
  const query = useQuery({
    queryFn: getCurrentCognitoUser,
    queryKey: ['CognitoUser'],
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return query;
};

export const invalidateCognitoUser = () =>
  queryClient.invalidateQueries({
    queryKey: ['CognitoUser'],
    refetchType: 'all',
    type: 'all',
  });

export const invalidateCurrentUser = () =>
  queryClient.invalidateQueries({
    queryKey: QueryKey.CurrentUser(),
    refetchType: 'all',
    type: 'all',
  });
